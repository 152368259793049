/* eslint-disable react/display-name */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Dropdown, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import MoreOutlined from '@ant-design/icons/lib/icons/MoreOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import OfferTag from 'components/ui/OfferTag/OfferTag';
import Table from 'components/ui/Table/Table';
import TableArrow from 'components/ui/TableArrow/TableArrow';

import { IClientRequestSearchResult } from 'store/client-request/client-request.types';
import { OfferState } from 'store/offer/offer.types';
import { formatPriceRange } from 'utils/currencyUtils';

import styles from './Widgets.module.sass';

interface ICRTableWidgetProps {
  dataSource: IClientRequestSearchResult[];
  pageSize: number;
  total: number;
  offset: number;
  isLoading: boolean;
  onPageChange(page: number, pageSize?: number): any;
}

const CRTableWidget: React.FC<ICRTableWidgetProps> = ({
  dataSource,
  pageSize = 10,
  offset,
  total,
  isLoading,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onEditOffer = (id: string) => {
    navigate(`/installer/tender/${id}/offer-type`);
  };

  const CRTableColumns: ColumnsType<IClientRequestSearchResult> = [
    {
      title: 'installerFlow:dashboard:id',
      dataIndex: 'displayId',
      key: 'displayId',
      align: 'right',
    },
    {
      title: 'installerFlow:dashboard:distance',
      dataIndex: 'distance',
      key: 'distance',
      align: 'right',
      render: (distance: string) => {
        return `${Math.round(Number.parseFloat(distance))} km`;
      },
    },
    {
      title: 'installerFlow:dashboard:zip',
      dataIndex: 'zip',
      render: (text: string, row: IClientRequestSearchResult, index: Number) => {
        return row.zip ? row.zip : 'n/a';
      },
    },
    {
      title: 'installerFlow:dashboard:period',
      dataIndex: 'period',
      key: 'period',
      render: (text: string, row: IClientRequestSearchResult, index: Number) =>
        row?.soonestPossible ? (
          t('installerFlow:dashboard:soonest')
        ) : (
          <span>
            {t('installerFlow:dashboard:from')}
            <DateFormat date={row.startDate} />
          </span>
        ),
    },
    {
      title: 'installerFlow:dashboard:priceRange',
      dataIndex: 'priceRange',
      key: 'priceRange',
      align: 'right',
      render: (text: string, row: IClientRequestSearchResult, index: Number) =>
        row.questionnaireSkipped
          ? t('installerFlow:dashboard:homeCheckPending')
          : formatPriceRange(row),
    },
    {
      title: 'installerFlow:dashboard:status',
      dataIndex: 'status',
      key: 'status',
      render: (status: OfferState, clientRequest) => {
        const showEditOfferButton =
          status === OfferState.TenderCreated ||
          status === OfferState.TenderSubmitted ||
          status === OfferState.TenderAccepted ||
          status === OfferState.TenderSubmittedHomeCheck ||
          status === OfferState.TenderSubmittedRemoteHomeCheck ||
          status === OfferState.TenderAcceptedHomeCheck ||
          status === OfferState.TenderAcceptedRemoteHomeCheck;

        const menu = (
          <Menu>
            <Menu.Item
              icon={<EditOutlined />}
              onClick={(e) => {
                e.domEvent.stopPropagation();
                e.domEvent.preventDefault();
                onEditOffer(clientRequest.id);
              }}
            >
              {t('Edit Offer')}
            </Menu.Item>
          </Menu>
        );

        return (
          <div className={styles.statusContainer}>
            <OfferTag state={status ? status : OfferState.None} />
            {showEditOfferButton && (
              <Dropdown overlay={menu} placement="bottomLeft">
                <button className={styles.moreButton}>
                  <MoreOutlined />
                </button>
              </Dropdown>
            )}
          </div>
        );
      },
    },
    {
      title: '',
      key: 'arrow',
      dataIndex: 'id',
      render: () => <TableArrow />,
    },
  ];

  // TODO: Later show this column only if installer is part of pool
  // if (installerIsPartOfPool) {
  CRTableColumns.splice(1, 0, {
    title: 'Pool',
    dataIndex: 'pool',
    align: 'right',
    render: (text: string, row: any) => {
      return row.poolName ? row.poolName : '';
    },
  });
  // }

  const handleRowClick = (tender: any) => {
    const { id } = tender;
    return `/installer/tender/${id}`;
  };

  return (
    <Table
      loading={isLoading}
      columns={CRTableColumns.map((tender: any) => ({ ...tender, title: t(`${tender.title}`) }))}
      dataSource={dataSource}
      emptyText={t('installerFlow:dashboard:noAvailableOrders')}
      pagination={{
        total,
        pageSize,
        position: ['bottomRight'],
        current: offset ? offset / pageSize + 1 : 1,
        onChange: onPageChange,
      }}
      onRowClick={handleRowClick}
    />
  );
};

export default CRTableWidget;
