import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  GET_OFFER_FILES_SUCCESS,
  GET_OFFERS_BY_CR,
  GET_OFFERS_BY_CR_ERROR,
  GET_OFFERS_BY_CR_SUCCESS,
  IOfferState,
  MY_ORDERS_FIND,
  MY_ORDERS_FIND_SUCCESS,
  OFFER_CREATE,
  OFFER_CREATE_ERROR,
  OFFER_CREATE_SUCCESS,
  OFFER_GET,
  OFFER_GET_BY_INSTALLER,
  OFFER_GET_ERROR,
  OFFER_GET_FOR_CLIENT_REQUEST,
  OFFER_GET_SUCCESS,
  OFFER_TRANSITION,
  OFFER_TRANSITION_ERROR,
  OFFER_TRANSITION_RESET,
  OFFER_TRANSITION_SUCCESS,
  OfferActionTypes,
  OFFERS_FIND_ERROR,
  OFFERS_GET_BY_ORGANIZATION,
  OFFERS_GET_BY_ORGANIZATION_SUCCESS,
  UPLOAD_OFFER_FILE,
  UPLOAD_OFFER_FILE_ERROR,
  UPLOAD_OFFER_FILE_SUCCESS,
  OFFERS_IN_PROGRESS_COUNT,
  OFFERS_IN_PROGRESS_COUNT_ERROR,
  OFFERS_IN_PROGRESS_COUNT_SUCCESS,
  OFFER_UPDATE,
  OFFER_UPDATE_ERROR,
  OFFER_UPDATE_SUCCESS,
  DOWNLOAD_CALENDAR_EVENT,
  DOWNLOAD_CALENDAR_EVENT_SUCCESS,
  DOWNLOAD_CALENDAR_EVENT_ERROR,
  OFFER_REASSIGN_INSTALLER,
  OFFER_REASSIGN_INSTALLER_SUCCESS,
  OFFER_REASSIGN_INSTALLER_ERROR,
  GET_OFFER_FILES,
  OFFER_GET_DOCS_FOR_INSTALLER,
  OFFER_GET_DOCS_FOR_INSTALLER_SUCCESS,
  OFFER_GET_DOCS_FOR_INSTALLER_ERROR,
} from './offer.types';

const initialState: IOfferState = {
  offerCreateState: null,
  createError: null,

  offerUpdateState: null,
  updateError: null,

  offerGetState: null,
  getError: null,

  offerFindState: null,
  foundError: null,

  offerTransitionState: null,
  transitionError: null,

  offersList: null,
  offersListError: null,
  offersListState: null,

  offer: null,

  foundOffers: { items: [], count: 0 },

  files: [],
  filesGetState: null,

  fileUploadState: null,

  offersInProgressCount: null,
  offersInProgressCountState: null,
  offersInProgressCountError: null,

  downloadCalendarEventError: null,
  downloadCalendarEventState: null,

  docsForInstaller: [],
  docsForInstallerState: RequestState.Loading,
};

const OfferReducer = (state = initialState, action: OfferActionTypes) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case DOWNLOAD_CALENDAR_EVENT:
        draft.downloadCalendarEventState = RequestState.Loading;
        break;

      case DOWNLOAD_CALENDAR_EVENT_ERROR:
        draft.downloadCalendarEventState = RequestState.Error;
        break;

      case DOWNLOAD_CALENDAR_EVENT_SUCCESS:
        draft.downloadCalendarEventState = RequestState.Success;
        draft.downloadCalendarEventError = null;
        break;

      case OFFERS_IN_PROGRESS_COUNT:
        draft.offersInProgressCountState = RequestState.Loading;
        break;

      case OFFERS_IN_PROGRESS_COUNT_ERROR:
        draft.offersInProgressCountState = RequestState.Error;
        draft.offersInProgressCountError = action.payload;
        break;

      case OFFERS_IN_PROGRESS_COUNT_SUCCESS:
        draft.offersInProgressCountState = RequestState.Success;
        draft.offersInProgressCountError = null;
        draft.offersInProgressCount = action.payload;
        break;

      case OFFER_GET:
      case OFFER_GET_FOR_CLIENT_REQUEST:
      case OFFER_GET_BY_INSTALLER:
        draft.offerGetState = RequestState.Loading;
        break;

      case OFFER_GET_ERROR:
        draft.offerGetState = RequestState.Error;
        draft.createError = action.payload;
        break;

      case OFFER_GET_SUCCESS:
        draft.offerGetState = RequestState.Success;
        draft.getError = null;
        draft.offer = action.payload;
        draft.files = [];
        break;

      case GET_OFFERS_BY_CR:
        draft.offersListError = null;
        draft.offersListState = RequestState.Loading;
        break;

      case GET_OFFERS_BY_CR_ERROR:
        draft.offersListError = action.payload;
        draft.offersListState = RequestState.Error;
        break;

      case GET_OFFERS_BY_CR_SUCCESS:
        draft.offersList = action.payload;
        draft.offersListError = null;
        draft.offersListState = RequestState.Success;
        break;

      case OFFER_CREATE:
        draft.offerCreateState = RequestState.Saving;
        break;

      case OFFER_CREATE_ERROR:
        draft.offerCreateState = RequestState.Error;
        draft.createError = action.payload;
        break;

      case OFFER_CREATE_SUCCESS:
        draft.offerCreateState = RequestState.Success;
        draft.createError = null;
        draft.offer = action.payload.offer;
        break;

      case OFFER_TRANSITION:
        draft.offerTransitionState = RequestState.Saving;
        break;

      case OFFER_TRANSITION_ERROR:
        draft.offerTransitionState = RequestState.Error;
        draft.createError = action.payload;
        break;

      case OFFER_TRANSITION_SUCCESS:
        draft.offerTransitionState = RequestState.Success;
        draft.createError = null;
        draft.offer = {
          ...state.offer,
          ...action.payload,
        };
        break;

      case OFFER_TRANSITION_RESET:
        draft.offerTransitionState = null;
        draft.createError = null;
        break;

      case MY_ORDERS_FIND:
        draft.offerFindState = RequestState.Loading;
        break;

      case OFFERS_GET_BY_ORGANIZATION:
        draft.offerFindState = RequestState.Loading;
        break;

      case OFFERS_FIND_ERROR:
        draft.offerFindState = RequestState.Error;
        draft.findError = action.payload;
        draft.foundOffers = [];
        break;

      case MY_ORDERS_FIND_SUCCESS:
        draft.offerFindState = RequestState.Success;
        draft.findError = null;
        draft.foundOffers = action.payload;
        break;

      case OFFERS_GET_BY_ORGANIZATION_SUCCESS:
        draft.offerFindState = RequestState.Success;
        draft.findError = null;
        draft.foundOffers = action.payload;
        break;
      case GET_OFFER_FILES:
        draft.filesGetState = RequestState.Loading;
        break;
      case GET_OFFER_FILES_SUCCESS:
        draft.files = [...action.payload.files];
        draft.filesGetState = RequestState.Success;
        break;

      case UPLOAD_OFFER_FILE:
        draft.fileUploadState = RequestState.Saving;
        break;

      case UPLOAD_OFFER_FILE_ERROR:
        draft.fileUploadState = RequestState.Error;
        break;

      case UPLOAD_OFFER_FILE_SUCCESS:
        draft.fileUploadState = RequestState.Success;
        draft.files = [...(state.files || []), action.payload];
        break;

      case OFFER_UPDATE:
        draft.offerUpdateState = RequestState.Loading;
        break;

      case OFFER_UPDATE_ERROR:
        draft.offerUpdateState = RequestState.Error;
        draft.updateError = action.payload;
        break;

      case OFFER_UPDATE_SUCCESS:
        draft.offerUpdateState = RequestState.Success;
        draft.updateError = null;
        draft.offer = { ...state.offer, ...action.payload };
        break;
      case OFFER_REASSIGN_INSTALLER:
        draft.offerUpdateState = RequestState.Loading;
        draft.updateError = null;
        break;
      case OFFER_REASSIGN_INSTALLER_SUCCESS:
        draft.offerUpdateState = RequestState.Success;
        draft.foundOffers = {
          ...state.foundOffers,
          items: state.foundOffers.items.map((item) =>
            item.id === action.payload.id ? { ...item, ...action.payload } : item,
          ),
        };
        break;
      case OFFER_REASSIGN_INSTALLER_ERROR:
        draft.offerUpdateState = RequestState.Error;
        draft.updateError = action.payload;
        break;
      // Docs for Installer
      case OFFER_GET_DOCS_FOR_INSTALLER:
        draft.docsForInstallerState = RequestState.Loading;
        draft.docsForInstaller = [];
        break;
      case OFFER_GET_DOCS_FOR_INSTALLER_SUCCESS:
        draft.docsForInstallerState = RequestState.Success;
        draft.docsForInstaller = action.payload;
        break;
      case OFFER_GET_DOCS_FOR_INSTALLER_ERROR:
        draft.docsForInstallerState = RequestState.Error;
        draft.docsForInstaller = [];
        break;
    }
  });

export default OfferReducer;
