import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

import NavLink from 'components/ui/NavLink/NavLink';
import { offerstInProgressCount, offerTransitionReset } from 'store/offer/offer.actions';
import { OfferCategory } from 'store/offer/offer.types';
import TendersTab from './TendersTab';
import OffersTab from './OffersTab';

import styles from './DashboardPage.module.sass';

enum DashboardTabs {
  HOMECHECK_OFFERS = 'homecheck-offers',
  INSTALLATION_OFFERS = 'installation-offers',
  TENDERS = 'tenders',
}

const DashboardPage = () => {
  const { t } = useTranslation();
  const { group } = useParams() as { group: string };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(offerstInProgressCount());
  }, []); // eslint-disable-line

  useEffect(() => {
    return function cleanup() {
      dispatch(offerTransitionReset());
    };
  }, [dispatch]);

  const titleText = `${t(`installerFlow:dashboard:${group}`)} - ${t(
    'header:installer',
  )} - Tendergy`;

  return (
    <div style={{ paddingTop: '20px' }}>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      <div className={styles.header}>
        {t('installerFlow:dashboard:tenders')} & {t('installerFlow:dashboard:orders')}
      </div>
      <div className={styles.navLinks} style={{ display: 'flex' }}>
        <NavLink to="/installer/dashboard/tenders" title="Tenders" />
        <NavLink to="/installer/dashboard/homecheck-offers" title="Home-Check Orders" />
        <NavLink to="/installer/dashboard/installation-offers" title="Installation Orders" />
      </div>
      <div className={styles.table}>
        {group === DashboardTabs.TENDERS && <TendersTab />}
        {group === DashboardTabs.HOMECHECK_OFFERS && <OffersTab type={OfferCategory.HomeCheck} />}
        {group === DashboardTabs.INSTALLATION_OFFERS && (
          <OffersTab type={OfferCategory.Installation} />
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
