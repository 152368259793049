import config from 'config';
import { IOrganizationDefaultSettings } from 'store/organization/organization.types';
import HttpService from './http.service';

class OrganizationAPI extends HttpService {
  DEFAULT_SETTINGS_API = 'organizations/defaults/mine';
  getOrganizationSettings = () => {
    return this.get(this.DEFAULT_SETTINGS_API);
  };
  saveOrganizationSettings = (settings: IOrganizationDefaultSettings) => {
    return this.post(this.DEFAULT_SETTINGS_API, settings);
  };
}

export default new OrganizationAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
