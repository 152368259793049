import { all, call, put, takeLatest } from 'redux-saga/effects';
import DocumentsAPI from 'services/documents.service';
import { IGetResponse } from 'store/common.types';
import { IDocument } from 'store/client-request/client-request.types';
import {
  OfferGetAction,
  OfferCreateAction,
  OFFER_CREATE,
  OFFER_GET,
  OfferTransitionAction,
  OFFER_TRANSITION,
  MY_ORDERS_FIND,
  OfferGetForClientRequestAction,
  OFFER_GET_FOR_CLIENT_REQUEST,
  OfferGetByInstallerAction,
  OFFER_GET_BY_INSTALLER,
  OFFERS_GET_BY_ORGANIZATION,
  GetOfferFilesAction,
  GET_OFFER_FILES,
  OffersGetByOrganizationAction,
  MyOrdersFindAction,
  GET_OFFERS_BY_CR,
  GetOffersByClientRequestAction,
  OfferFileUploadAction,
  UPLOAD_OFFER_FILE,
  OffersInProgressCountAction,
  OFFERS_IN_PROGRESS_COUNT,
  UpdateOffersAction,
  OFFER_UPDATE,
  DownloadCalendarEventAction,
  DOWNLOAD_CALENDAR_EVENT,
  IOffer,
  DOWNLOAD_ALL_OFFER_DOCUMENTS,
  DownloadAllDocumentsAction,
  OfferStatusType,
  OfferReassignInstallerAction,
  OFFER_REASSIGN_INSTALLER,
  OfferGetDocsForInstallerAction,
  OFFER_GET_DOCS_FOR_INSTALLER,
  IOfferDocsForInstaller,
} from './offer.types';
import {
  getOfferFiles,
  offerCreateError,
  offerCreateSuccess,
  offerGetDocsForInstallerError,
  offerGetDocsForInstallerSuccess,
  reassignOfferInstallerError,
  reassignOfferInstallerSuccess,
} from './offer.actions';

import {
  offerGetSuccess,
  offerGetError,
  offerTransitionSuccess,
  offerTransitionError,
  myOrdersFindSuccess,
  offerGetByOrganizationSuccess,
  offersFindError,
  getOfferFilesSuccess,
  getOfferFilesError,
  getOffersListError,
  getOffersListSuccess,
  offerFileUploadSuccess,
  offerFileUploadError,
  offerstInProgressCountSuccess,
  offerstInProgressCountError,
  updateOfferSuccess,
  updateOfferError,
  downloadCalendarEventSuccess,
  downloadCalendarEventError,
  downloadAllDocumentsError,
} from './offer.actions';
import OfferAPI from '../../services/offer.service';

export function* calendarEventDownload(action: DownloadCalendarEventAction) {
  try {
    yield call(OfferAPI.downloadCalendarEvent, action.payload);
    yield put(downloadCalendarEventSuccess());
  } catch (err: any) {
    const { response = {} } = err;
    const { data = {} } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(downloadCalendarEventError(message));
  }
}

export function* getInProgressOffersCount(action: OffersInProgressCountAction) {
  try {
    const res: IGetResponse<IOffer> = yield call(OfferAPI.countInprogressOffers);

    if (res?.count) {
      yield put(offerstInProgressCountSuccess(res.count));
    } else {
      yield put(offerstInProgressCountError('Empty Response'));
    }
  } catch (err: any) {
    const { response = {} } = err;
    const { data = {} } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(offerstInProgressCountError(message));
  }
}

export function* getOffer(action: OfferGetAction) {
  try {
    const offer: IOffer = yield call(OfferAPI.getById, action.payload);
    if (offer) {
      yield put(offerGetSuccess(offer));
    } else {
      yield put(offerGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(offerGetError(message));
  }
}

export function* getOffersForClientRequest(action: GetOffersByClientRequestAction) {
  try {
    const offers: IOffer[] = yield call(OfferAPI.getOffersListByClientRequestId, action.payload);
    yield put(getOffersListSuccess(offers));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(getOffersListError(message));
  }
}

export function* getOfferForClientRequest(action: OfferGetForClientRequestAction) {
  try {
    const offer: IOffer = yield call(OfferAPI.getByClientRequestId, action.payload);
    if (offer) {
      yield put(offerGetSuccess(offer));
    } else {
      yield put(offerGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(offerGetError(message));
  }
}

export function* getOfferForClientRequestByInstaller(action: OfferGetByInstallerAction) {
  try {
    const offer: IOffer = yield call(OfferAPI.getByClientRequestIdByInstaller, action.payload);
    yield put(offerGetSuccess(offer));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(offerGetError(message));
  }
}

export function* getAssignedOffers(action: MyOrdersFindAction) {
  const { type, ...rest } = action.payload;
  try {
    let offer: IOffer[] = [];
    switch (type) {
      case OfferStatusType.OFFERS_COMPLETED:
        offer = yield call(OfferAPI.findMyCompletedOrders, rest);
        break;
      case OfferStatusType.OFFERS_IN_PROGRESS:
        offer = yield call(OfferAPI.findMyInProgressOrders, rest);
        break;
      case OfferStatusType.WHOLE_COMPANY:
        offer = yield call(OfferAPI.findOrdersByOrganization, rest);
        break;
      default:
        offer = yield call(OfferAPI.findMyOrders, rest);
    }

    if (offer) {
      yield put(myOrdersFindSuccess(offer));
    } else {
      yield put(offersFindError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(offersFindError(message));
  }
}
export function* getOrdersByOrganization(action: OffersGetByOrganizationAction) {
  try {
    const offer: IOffer[] = yield call(OfferAPI.findOrdersByOrganization, action.payload);

    if (offer) {
      yield put(offerGetByOrganizationSuccess(offer));
    } else {
      yield put(offersFindError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(offersFindError(message));
  }
}

export function* createOffer(action: OfferCreateAction) {
  const { offer, eventType, onSuccess, onError } = action.payload;
  try {
    const savedOffer: IOffer = yield call(OfferAPI.transition, eventType, offer);
    yield put(offerCreateSuccess(savedOffer));

    if (typeof onSuccess === 'function') {
      onSuccess(savedOffer);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(offerCreateError(message));

    if (typeof onError === 'function') {
      onError(message);
    }
  }
}

export function* uploadFile(action: OfferFileUploadAction) {
  const { document, id, mediaCategory, onProgress, onSuccess, onError } = action.payload;
  try {
    const uploadedDocument: IDocument = yield call(
      DocumentsAPI.uploadFileOffer,
      document,
      id,
      mediaCategory,
      onProgress,
    );
    yield put(offerFileUploadSuccess(uploadedDocument));
    yield put(getOfferFiles(id));
    if (typeof onSuccess === 'function') {
      onSuccess(uploadedDocument);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(offerFileUploadError(message));
    if (typeof onError === 'function') {
      onError(message);
    }
  }
}

export function* getOfferDocuments(action: GetOfferFilesAction) {
  try {
    const { offerId } = action.payload;
    const files: IDocument[] = yield call(DocumentsAPI.getOfferFiles, offerId);

    if (Array.isArray(files)) {
      yield put(getOfferFilesSuccess(files));
    }
  } catch (err: any) {
    const { response = {} } = err;
    const { data = {} } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(getOfferFilesError(message));
  }
}

export function* transitionOffer(action: OfferTransitionAction) {
  const { event, offer, onSuccess, onError } = action.payload;
  try {
    const transitionedOffer: IOffer = yield call(OfferAPI.transition, event, offer);
    onSuccess && onSuccess(transitionedOffer);
    yield put(offerTransitionSuccess(transitionedOffer));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(offerTransitionError(message));

    if (typeof onError === 'function') {
      onError(message);
    }
  }
}

export function* offerUpdate({ payload }: UpdateOffersAction) {
  try {
    const { id, offer, onSuccess } = payload;
    const newOffer: IOffer = yield call(OfferAPI.update, id, offer);
    yield put(updateOfferSuccess(newOffer));

    if (typeof onSuccess === 'function') {
      onSuccess(newOffer);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(updateOfferError(message));
  }
}

export function* downloadAllDocuments(action: DownloadAllDocumentsAction) {
  try {
    const { id } = action.payload;
    yield call(OfferAPI.downloadAllDocuments, id);
  } catch (err: any) {
    yield put(downloadAllDocumentsError(err));
  }
}

export function* reassignOfferInstaller(action: OfferReassignInstallerAction) {
  try {
    const { offerId, installerId } = action.payload;
    const offer: IOffer = yield call(OfferAPI.reassignInstallerUser, offerId, installerId);
    yield put(reassignOfferInstallerSuccess(offer));
  } catch (err) {
    yield put(reassignOfferInstallerError(err));
  }
}

export function* getOfferDocsForInstaller(action: OfferGetDocsForInstallerAction) {
  try {
    const offerDocsForInstaller: IOfferDocsForInstaller[] = yield call(
      OfferAPI.getOfferDocsForInstaller,
      action.payload,
    );

    if (offerDocsForInstaller) {
      yield put(offerGetDocsForInstallerSuccess(offerDocsForInstaller.map((doc) => doc.document)));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(offerGetDocsForInstallerError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(OFFER_CREATE, createOffer),
    takeLatest(OFFER_GET, getOffer),
    takeLatest(OFFER_TRANSITION, transitionOffer),
    takeLatest(MY_ORDERS_FIND, getAssignedOffers),
    takeLatest(OFFERS_GET_BY_ORGANIZATION, getOrdersByOrganization),
    takeLatest(OFFER_GET_FOR_CLIENT_REQUEST, getOfferForClientRequest),
    takeLatest(OFFER_GET_BY_INSTALLER, getOfferForClientRequestByInstaller),
    takeLatest(GET_OFFER_FILES, getOfferDocuments),
    takeLatest(GET_OFFERS_BY_CR, getOffersForClientRequest),
    takeLatest(UPLOAD_OFFER_FILE, uploadFile),
    takeLatest(OFFERS_IN_PROGRESS_COUNT, getInProgressOffersCount),
    takeLatest(OFFER_UPDATE, offerUpdate),
    takeLatest(DOWNLOAD_CALENDAR_EVENT, calendarEventDownload),
    takeLatest(DOWNLOAD_ALL_OFFER_DOCUMENTS, downloadAllDocuments),
    takeLatest(OFFER_REASSIGN_INSTALLER, reassignOfferInstaller),
    takeLatest(OFFER_GET_DOCS_FOR_INSTALLER, getOfferDocsForInstaller),
  ]);
}
