import { isPhoneNumber, isEmail } from 'class-validator';
import { createAjv } from '@jsonforms/core';
import { FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';
import _lowerCase from 'lodash/lowerCase';
import { MathUtils } from 'utils/mathUtils';
import { offerMINdaysMessage } from 'utils/locize';
import i18n from './../i18n';
import VerificationAPI from '../services/verification.service';

const PASSWORD_REGEX = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
const REQUIRED_ADDRESS_FIELDS = ['street', 'houseNumber', 'city', 'postalCode', 'country'];

export interface IValidation {
  validator: (value: string) => boolean;
  title: string;
  checked?: boolean;
}

i18n.loadNamespaces(['common', 'customerFlow'], () => {
  for (let key in ValidationFactory) {
    //@ts-ignore
    ValidationFactory[`${key}`].message = i18n.t(`${ValidationFactory[`${key}`].message}`);
  }
});

export const ValidationFactory = {
  REQUIRED: {
    required: true,
    message: i18n.t('common:errors:fieldRequired'),
  },
  CHECKBOX_REQUIRED: {
    validator: async (_: any, value: boolean) => {
      if (!value) {
        throw new Error();
      }
    },
    message: i18n.t('common:errors:fieldRequired'),
  },
  REQUIRED_OPTION: { required: true, message: i18n.t('common:errors:selectOption') },
  REQUIRED_DISTANCE: { required: true, message: i18n.t('common:errors:enterDistance') },
  NUMBER_INPUT: {
    validator: (_rule: any, value: any) =>
      MathUtils.isValidNumber(value)
        ? Promise.resolve()
        : Promise.reject(i18n.t('common:errors:correctNumberFormat')),
  },
  IS_URL: {
    type: 'url',
    message: i18n.t('common:errors:validUrl'),
  } as Rule,
  INCLUDES_URL: {
    validator: async (_rule: any, value: string) => {
      const urlRegex = /(https?:\/\/)?(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?/gi;
      if (value && urlRegex.test(value)) {
        throw new Error();
      }
    },
    message: i18n.t('common:errors:includesUrl'),
  },
  EMAIL: {
    validator: async (_rule: any, value: any) => {
      if (value && !isEmail(value)) {
        throw new Error();
      }
    },
    message: i18n.t('common:errors:validEmail'),
  },
  EDITOR_REQUIRED: {
    validator: async (_rule: any, value: any) => {
      const validateData = typeof value === 'string' ? JSON.parse(value) : value;
      if (
        validateData.blocks.length === 0 ||
        validateData.blocks.every((block: any) => block.text === '')
      ) {
        throw new Error();
      }
    },
    message: i18n.t('common:errors:fieldRequired'),
  },
  POST_CODE: (({ getFieldValue }: FormInstance) => ({
    validator: async (_rule: any, value: any) => {
      if (!value || value.length < 3) {
        return Promise.reject(i18n.t('common:errors:validPostalCode'));
      }
      await VerificationAPI.verifyPostalCode(value, getFieldValue('country'))
        .then((response) => {
          return Promise.resolve();
        })
        .catch((error: any) => {
          return Promise.reject(i18n.t('common:errors:validPostalCode'));
        });
      return Promise.resolve();
    },
  })) as Rule,
  MAX_LENGTH: {
    max: 64,
    message: i18n.t('common:errors:validMaxLength'),
  },
  NOT_ONLY_WHITE_SPACE: {
    whitespace: true,
    message: i18n.t('common:errors:validOnlySpace'),
  },
  PASSWORD: {
    pattern: PASSWORD_REGEX,
    message: i18n.t('common:errors:validPassword'),
  },
  PASSWORD_MIN_LENGTH: {
    min: 12,
    message: i18n.t('common:errors:validPasswordTooShort'),
  },
  PHONE: {
    validator: async (_rule: any, value: any) => {
      if (!isPhoneNumber(value, 'DE') && value) {
        throw new Error();
      }
    },
    message: i18n.t('common:errors:validPhone'),
  },
  MIN_DAYS: (days: string) => ({
    validator: async (_rule: any, value: any) => {
      // eslint-disable-next-line radix
      if (parseInt(value) < parseInt(days)) {
        throw new Error();
      }
    },
    message: offerMINdaysMessage(days),
  }),
  ONE_FILLED: (field: string, label?: string) => {
    return (({ getFieldValue }: FormInstance) => ({
      validator: async (_rule: any, value: any) => {
        if (!value && !getFieldValue(field)) {
          throw new Error();
        }
      },
      message: `${i18n.t('common:errors:oneFilled')} ${_lowerCase(field)}, ${label}`,
    })) as Rule;
  },
  ONLY_ONE_FILLED: (field: string | string[]) => {
    return (({ getFieldValue }: FormInstance) => ({
      validator: async (_rule: any, value: any) => {
        if (value && getFieldValue(field)) {
          throw new Error();
        }
      },
      message: `${i18n.t('common:errors:oneFilled')} ${_lowerCase(field[1])}, \${name}`,
    })) as Rule;
  },
  REQUIRED_WITH_OTHER: (message: string) => {
    return {
      validator: async (_rule: any, value: any) => {
        const hasSelectedOption = Boolean(value.selectedOption);
        const hasOtherValueText =
          value.selectedOption === 'other' ? Boolean(value.text.trim()) : true;
        if (!hasSelectedOption || !hasOtherValueText) {
          throw new Error();
        }
      },
      message: i18n.t(message),
    };
  },
  REQUIRED_WITH_INPUTS: {
    validator: async (_rule: any, value: any) => {
      const keys = Object.keys(value);

      keys.forEach((key) => {
        const val = value[key];

        if (!val.trim()) {
          throw new Error();
        }
      });
    },
    message: i18n.t('common:errors:fieldRequired'),
  },
  CAR_MODEL: {
    validator: async (_rule: any, value: any) => {
      const hasManufacturerAndModel = Boolean(value.manufacturer?.trim() && value.model?.trim());
      const hasNoVehicle = Boolean(value.noVehicle);

      if (!value || !(hasManufacturerAndModel || hasNoVehicle)) {
        throw new Error();
      }
    },
    message: i18n.t('customerFlow:questions:carModel:error'),
  },
  EXCAVATION_SURFACE: {
    validator: async (_rule: any, value: any) => {
      if (Array.isArray(value))
        value.forEach((item: any) => {
          const keys = Object.keys(item);
          keys.forEach((key: string) => {
            const val = item[key];
            if (!val.trim()) {
              throw new Error();
            }
          });
        });
    },
    message: i18n.t('common:errors:fieldRequired'),
  },
  ADDRESS_INPUTS: (message: string) => {
    return {
      validator: async (rule: any, value: any) => {
        Object.keys(value).forEach((key) => {
          const isRequired = REQUIRED_ADDRESS_FIELDS.indexOf(key) !== -1;
          const inputValue = value[key];

          if (isRequired && !inputValue.trim()) {
            throw new Error();
          }
        });
      },
      message: i18n.t(message),
    };
  },
  CHARGING_STATION: {
    validator: async (rule: any, value: any) => {
      const isNeedSupport = value.answer === 'needInstallerSupport';
      const isCompanyOther = value.company === 'other';
      const isModelOther = value.model === 'other';

      const isArray = Array.isArray(value) && value.length;

      if (!value) {
        throw new Error();
      }

      if (value && !isNeedSupport && !isArray) {
        const otherCompanyValue = value.otherCompany.trim();
        const otherModelValue = value.otherModel.trim();

        if (!(value.company && value.model)) {
          throw new Error();
        }

        if (isCompanyOther && (!otherCompanyValue || !otherModelValue)) {
          throw new Error();
        }

        if (isModelOther && !otherModelValue) {
          throw new Error();
        }
      }
    },
    message: i18n.t('common:errors:fieldRequired'),
  },
  AJV_VALIDATION: (schema: any, message: string) => {
    return {
      validator: async (_rule: any, value: any) => {
        const ajv = createAjv();
        const valid = ajv.validate(schema, value);
        if (!valid) {
          ajv?.errors?.forEach((error: any) => {
            throw new Error();
          });
        }
      },
      message: i18n.t(message),
    };
  },
  CHECKBOX_VALIDATION: (checkboxValue: boolean, message: string) => {
    return {
      validator: async (_rule: any, value: any) => {
        if (!checkboxValue) {
          throw new Error();
        }
      },
      message: i18n.t(message),
    };
  },
};

export const ValidationMessagesFactory = {
  /* eslint-disable-next-line */
  required: 'Please input your ${name}!',
};

export const popoverValidations: IValidation[] = [
  { title: 'be at least 12 characters long', validator: (value) => value.length >= 12 },
  { title: 'contain lowercase letters', validator: (value) => !!value.match(/.*[a-z].*/) },
  { title: 'contain uppercase letters', validator: (value) => !!value.match(/.*[A-Z].*/) },
  { title: 'contain numbers', validator: (value) => !!value.match(/.*\d.*/) },
  { title: 'contain special character', validator: (value) => !!value.match(/[^\w\s]/) },
];
