import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Alert from 'antd/lib/alert';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Skeleton from 'antd/lib/skeleton';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import Button from 'components/ui/Button/Button';
import Notification from 'components/ui/Notification/Notification';
import useLanguageChangeCallback from 'hooks/useLaguageChangeCallback';
import {
  clientRequestDistanceGetAction,
  clientRequestGet,
  getClientRequestFiles,
  getComments,
} from 'store/client-request/client-request.actions';
import {
  currentClientRequestSelector,
  clientRequestStateSelector,
  isClientRequestDistance,
} from 'store/client-request/client-request.selectors';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { IOffer, OfferEventType, OfferState } from 'store/offer/offer.types';
import { offerByInstallerGetAction, offerTransition } from 'store/offer/offer.actions';
import { currentOfferSelector } from 'store/offer/offer.selectors';
import { RequestState } from 'store/common.types';
import PageHeader from './PageHeader';
import LocationWidget from './LocationWidget';
import InstallationPeriodWidget from './InstallationPeriodWidget';
import PriceWidget from './PriceWidget';
import SpecificationWidget from './SpecificationWidget/SpecificationWidget';
import DocumentsWidget from './DocumentsWidget';
import Chat from './Chat/Chat';

import styles from './TenderDetailsPage.module.sass';
import ProductComponentWidget from './ProductComponentWidget/ProductComponentWidget';

const TenderDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const didMountRef = useRef(false);
  const fetchClientRequest = useCallback(() => {
    if (!id) return;
    dispatch(clientRequestGet(id, false));
  }, [dispatch, id]);
  useEffect(() => {
    if (!id) return;
    dispatch(offerByInstallerGetAction(id));
    fetchClientRequest();
    dispatch(getClientRequestFiles(id));
    dispatch(clientRequestDistanceGetAction(id));
    dispatch(getComments(id));
  }, [id, dispatch, fetchClientRequest]);
  useLanguageChangeCallback(fetchClientRequest);
  const clientRequestState = useSelector(clientRequestStateSelector);
  const clientRequest = useSelector(currentClientRequestSelector);
  const clientRequestDistance = useSelector(isClientRequestDistance);
  const offer = useSelector(currentOfferSelector);

  useEffect(() => {
    if (didMountRef.current) {
      if (clientRequestState === RequestState.Error) {
        navigate('/installer/dashboard/tenders');
      }
    } else {
      didMountRef.current = false;
    }
  }, [clientRequestState]); // eslint-disable-line react-hooks/exhaustive-deps

  const createRejectedOffer = () => {
    if (!clientRequest) {
      return; //TODO: Handle error
    }
    const createDto = {
      clientRequestId: clientRequest.id,
    };
    dispatch(
      offerTransition(OfferEventType.INITIATED, createDto, (createdOffer: IOffer) =>
        rejectCreatedOffer(createdOffer),
      ),
    );
  };

  const rejectCreatedOffer = (offer: IOffer) => {
    dispatch(
      offerTransition(OfferEventType.INSTALLER_REJECTED, offer, () =>
        navigate('/installer/dashboard'),
      ),
    );
  };

  const renderButtons = () => {
    const submitText = isQuestionnaireSkipped
      ? t('installerFlow:tenderDetails:buttonHomeCheckOffer')
      : t('installerFlow:tenderDetails:buttonSendOffer');

    const BUTTONS = [
      {
        color: '',
        title: t('installerFlow:tenderDetails:buttonRejectRequest'),
        danger: true,
        description: t('installerFlow:tenderDetails:declineText'),
        onClick: () => createRejectedOffer(),
      },
      {
        color: 'green',
        title: submitText,
        description: t('installerFlow:tenderDetails:onTermsText'),
        onClick: () => navigate(`/installer/tender/${id}/offer-type`),
      },
    ];

    return (
      <Row style={{ paddingTop: '20px' }} gutter={[16, 16]}>
        {BUTTONS.map((button: any, index: number) => (
          <Col className={styles.buttonsCol} span={12} key={`action-button-${index}`}>
            <Button
              type="primary"
              block={true}
              color={button.color}
              danger={button.danger}
              onClick={button.onClick}
            >
              {button.title}
            </Button>

            <small className={styles.small}>{button.description}</small>
          </Col>
        ))}
      </Row>
    );
  };

  const renderNotificationContent = (offer: IOffer) => {
    const isTenderCreated = offer.state === OfferState.TenderCreated;

    const onShowTenderClick = () => {
      if (isTenderCreated) {
        const documents = clientRequest?.offers?.find((o: IOffer) => o.id === offer.id)?.documents;

        if (Array.isArray(documents) && documents.length) {
          const isHomeCheck = documents.some(
            (d) => d.document.category === MediaCategoryType.OfferHomeCheckInstaller,
          );
          const path = isHomeCheck ? 'home-check' : 'directly';
          navigate(`/installer/tender/${id}/participate/${path}`);
        } else {
          navigate(`/installer/tender/${id}/offer-type`);
        }
      } else {
        navigate(`/installer/order/${offer.id}/details/offer`);
      }
    };

    return (
      <div className={styles.children}>
        <div>
          {isTenderCreated
            ? t('installerFlow:tenderDetails:alreadyCreated')
            : t('installerFlow:tenderDetails:alreadyPaticipated')}
        </div>
        <span className={styles.continueButton} onClick={onShowTenderClick}>
          {isTenderCreated
            ? t('installerFlow:tenderDetails:continueSubmitting')
            : t('installerFlow:tenderDetails:openTender')}
        </span>
      </div>
    );
  };

  const renderLoader = () => (
    <Skeleton
      active={true}
      paragraph={{
        rows: 6,
      }}
      round={true}
    />
  );

  const isQuestionnaireSkipped = clientRequest?.questionnaireSkipped;
  const isShowRejectedHint = offer && offer.state === OfferState.TenderRejected;

  const renderContent = () => {
    if (!clientRequest) {
      return renderLoader();
    }

    const isShowAlert = offer && offer.state === OfferState.TenderRejected;
    const isShowNotification =
      offer &&
      offer.state !== OfferState.TenderCancelled &&
      offer.state !== OfferState.TenderRejected;

    return (
      <div className={styles.container}>
        <PageHeader clientRequest={clientRequest} offer={offer} />

        {offer && isShowNotification && (
          <div style={{ marginTop: 10, marginBottom: 16 }}>
            <Notification
              className={styles.notification}
              size="small"
              title="Info"
              type="info"
              content={renderNotificationContent(offer)}
            />
          </div>
        )}

        {isShowAlert && (
          <Alert
            className={styles.alert}
            message={`${t('installerFlow:tenderDetails:rejectAlertText')}: ${offer?.rejectReason}`}
            type="error"
          />
        )}

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <LocationWidget clientRequest={clientRequest} distance={clientRequestDistance} />
          </Col>
          <Col span={8}>
            <InstallationPeriodWidget clientRequest={clientRequest} />
          </Col>
          <Col span={8}>
            <PriceWidget clientRequest={clientRequest} />
          </Col>
          <Col span={16}>
            <div className={styles.heightSeparator}>
              {clientRequest.products && clientRequest?.products?.length > 0 && (
                <ProductComponentWidget products={clientRequest.products} />
              )}
              <SpecificationWidget clientRequest={clientRequest} />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.heightSeparator}>
              <DocumentsWidget clientRequest={clientRequest} />
              <Chat clientRequestId={id!} />
            </div>
          </Col>
        </Row>

        {isShowRejectedHint && (
          <Row>
            <div className={styles.rejectedHint}>
              <InfoCircleOutlined />
              <span>{t('installerFlow:tenderDetails:tenderRejectedHint')}</span>
            </div>
          </Row>
        )}

        {!clientRequest.archived &&
          (!offer ||
            offer.state === OfferState.TenderCancelled ||
            offer.state === OfferState.TenderRejected) &&
          renderButtons()}
      </div>
    );
  };

  return clientRequestState === RequestState.Success ? renderContent() : renderLoader();
};

export default TenderDetailsPage;
