import config from 'config';
import { fileDownload } from 'framework/fileDownload';
import { ISurvey } from 'store/surveys/surveys.types';
import HttpService from './http.service';

class SurveysAPI extends HttpService {
  SURVEYS_API = 'surveys';

  getSurveys = (poolId: string) => {
    return this.get(`intermediates/pools/${poolId}/surveys`);
  };

  getSurveyById = (id: string) => {
    return this.get(`${this.SURVEYS_API}/${id}`, {
      relations: ['answers', 'questionnaire'],
    });
  };

  createSurvey = (survey: ISurvey) => {
    return this.post(this.SURVEYS_API, survey);
  };

  exportPoolSurveys = async (id: string) => {
    const headers = this.getHeaders({}, true);
    const res = await this.client.request({
      url: `${config.CRAFT_SERVICE_URL}/${this.SURVEYS_API}/report/excel/${id}`,
      responseType: 'blob',
      headers,
    });
    const fileName = `Surveys Report.xlsx`;
    fileDownload(res.data, fileName);
  };
}

export default new SurveysAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
