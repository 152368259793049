import React, { useState } from 'react';
import clsx from 'clsx';

import moment from 'moment';
import MomentTimezone from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { DayPicker } from 'react-day-picker';

import { Typography } from 'antd';
import { SupportedDateFnsLocales, SupportedLanguages } from 'framework/constants';
import { formatDateFromIso } from 'utils/dateUtils';
import { modifiersStyles } from './config';

import 'react-day-picker/dist/style.css';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './DateTimePicker.module.sass';

export interface IDateTime {
  day: Date;
  time?: string;
}

interface IDateTimePickerProps {
  disabled?: any;
  maxValues?: number;
  timeAvailable?: string[];
  selectedValues: IDateTime[];
  getAllValues?: Function;
  setValues: Function;
  onTimeClick: Function;
  onDayClick: Function;
}

const DateTimePicker: React.FC<IDateTimePickerProps> = (props) => {
  const {
    timeAvailable,
    onTimeClick,
    onDayClick,
    getAllValues,
    selectedValues,
    setValues,
    disabled = null,
    maxValues = 3,
  } = props;
  const { t, i18n } = useTranslation();

  const [currentDay, setCurrent] = useState(disabled?.before ? disabled?.before : new Date());
  const [selectedTime, setTime] = useState([] as string[]);
  const [dateNow, setDateNow] = useState(new Date());

  if (getAllValues) {
    getAllValues(selectedValues);
  }

  const handleDayClick = (day: Date, { selected, disabled }: any) => {
    let arrAfterDelete = [];

    if (disabled) return;

    if (selected) {
      const selectedIndexes = selectedValues.reduce((currValue: number[], prevValue, index) => {
        if (moment(prevValue.day).isSame(day, 'day')) {
          currValue.push(index);
        }
        return currValue;
      }, []);

      arrAfterDelete = selectedValues.reduce((prev: IDateTime[], curr, index) => {
        if (!selectedIndexes.includes(index)) {
          prev.push(curr);
        }
        return prev;
      }, []);
      setValues(arrAfterDelete);
    }

    setCurrent(day);
    onDayClick(day);
  };

  const onTimeSelected = (item: string) => {
    let tempValues = [...selectedValues];
    const tempTime = [...selectedTime];

    const selectedIndex = selectedValues.findIndex(
      (selectedValue) =>
        moment(selectedValue.day).isSame(currentDay as Date, 'day') && selectedValue.time === item,
    );

    const isValueValid = selectedIndex === -1 && maxValues && tempValues.length < maxValues;

    if (isValueValid) {
      let [hours, minutes] = item.split(':');
      let tempDate = new Date(currentDay);

      tempDate.setHours(+hours, +minutes);

      tempValues.push({ day: tempDate, time: item });
      tempTime.push(item);
    }

    if (selectedIndex > -1) {
      tempValues.splice(selectedIndex, 1);
      tempTime.splice(selectedIndex, 1);
    }
    setValues(tempValues);

    const valuesWithTime = tempValues.reduce((previousValue: IDateTime[], current) => {
      if (current.day && current.time) {
        previousValue.push(current);
      }
      return previousValue;
    }, []);

    setValues(valuesWithTime);
    setTime(tempTime);
    onTimeClick(currentDay, item);
  };

  // DANGER
  // Start WTF code
  const updateTime = () => {
    setTimeout(() => {
      setDateNow(new Date());
    }, 60000);
  };

  updateTime();
  // End WTF code

  const renderTimeZone = () => {
    const zoneName = MomentTimezone.tz.guess();
    const currentTime = `(${formatDateFromIso(dateNow, 'HH:mm')})`;
    return <div className={styles.timeZone}>{`${zoneName} ${currentTime}`}</div>;
  };

  const renderDay = (day: Date) => {
    const date = day.getDate();

    return (
      <div className={styles.cellStyle}>
        <div className={styles.dateStyle}>{date}</div>
      </div>
    );
  };

  const renderCalendar = () => {
    const modifiers = {
      today: new Date(),
      select: selectedValues.map((value) => value.day),
      current: currentDay,
    };

    const dayPickerProps = {
      modifiers,
      modifiersStyles,
      disabled,
      showOutsideDays: true,
      enableOutsideDaysClick: true,
      locale: SupportedDateFnsLocales[i18n.language as SupportedLanguages],
      renderDay: renderDay,
      selected: selectedValues.map((value) => value.day),
      onDayClick: handleDayClick,
    };

    return (
      <div className={styles.calendar}>
        <DayPicker {...dayPickerProps} />
      </div>
    );
  };

  const getTimeItem = (time: string) => {
    const selectedIndex = selectedValues.findIndex(
      (selectedValue) =>
        moment(selectedValue.day).isSame(currentDay, 'day') && selectedValue.time === time,
    );

    const splittedTime = time.split(':');
    const isDisabled =
      moment(currentDay).isSame(moment(), 'date') &&
      moment().hours(+splittedTime[0]).minutes(+splittedTime[1]).isBefore(moment()) &&
      selectedIndex === -1;
    const liClass = clsx(styles.timeCard, {
      [styles.activeCard]: selectedIndex !== -1,
      [styles.disabledCard]: isDisabled,
    });
    return (
      <li
        className={liClass}
        key={`time-${time}`}
        onClick={!isDisabled ? () => onTimeSelected(time) : undefined}
      >
        <p>{time}</p>
      </li>
    );
  };

  const renderTimePicker = () => {
    return (
      <>
        <Typography.Title level={5}>
          {formatDateFromIso(currentDay, 'dddd, Do MMMM, YYYY')}
        </Typography.Title>
        {timeAvailable ? (
          <div className={styles.timesWrapper}>
            <ul className={styles.timesList}>{timeAvailable?.map((item) => getTimeItem(item))}</ul>
          </div>
        ) : (
          <p>{t('common:texts:noTimeAvailable')}</p>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.calendarWrapper}>{renderCalendar()}</div>
        <div>{renderTimeZone()}</div>
      </div>
      <div className={styles.timeWrapper}>{renderTimePicker()}</div>
    </div>
  );
};

export default DateTimePicker;
