import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, message, Popconfirm, Row, Tooltip } from 'antd';
import {
  CheckOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
  AlignLeftOutlined,
} from '@ant-design/icons/lib/icons';
import moment from 'moment';
import Button, { IconPosition, ButtonColor } from 'components/ui/Button/Button';
import NumericInput from 'components/ui/NumberInput/NumericInput';
import OfferTag, { TagType } from 'components/ui/OfferTag/OfferTag';
import ConfirmationModal from 'components/modals/ConfirmationModal/ConfirmationModal';
import ReminderModal from 'components/modals/ReminderModal/ReminderModal';
import UploadDocumentModal from 'components/modals/UploadDocumentModal/UploadDocumentModal';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import Tag from 'components/ui/Tag/Tag';
import AnnotationModal from 'components/modals/AnnotationModal/AnnotationModal';
import { PATH } from 'framework/path';
import { IOffer, OfferApproval, OfferEventType, OfferState } from 'store/offer/offer.types';
import { CustomDocumentsCategoryType, IPool } from 'store/intermediate/intermediate.types';
import {
  downloadAllDocuments,
  offerFileUpload,
  offerTransition,
  updateOffer,
} from 'store/offer/offer.actions';
import {
  customDocumentCreate,
  poolGetOfferAnnotationById,
  sendEmailReminder,
} from 'store/intermediate/intermediate.actions';
import { IClientRequest, MediaCategoryType } from 'store/client-request/client-request.types';
import { currentOfferFilesSelector } from 'store/offer/offer.selectors';
import { formatCurrency, formatPrice, percentFilter } from 'utils/currencyUtils';

import {
  checkIsHomeCheck,
  checkIsInvoicePublished,
  checkIsOfferPublished,
} from 'utils/offerHelpers';
import { InfoRow } from './InfoRow';
import { AnnotationsRow } from './AnnotationsRow';
import OfferActionsDropdown from './OfferActionsDropdown';
import styles from '../../ClientRequestPage.module.sass';
import { ShowMore } from '../../components/ShowMore/ShowMore';

export interface IOfferItemProps {
  clientRequest: IClientRequest;
  offer: any;
  pool: IPool | null;
}

const OfferItem: React.FC<IOfferItemProps> = ({ clientRequest, offer: initialOffer, pool }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fileInput = useRef<HTMLInputElement>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [offer, setOffer] = useState(initialOffer);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [showAnnotation, setShowAnnotation] = useState(false);
  const [showUploadDoc, setShowUploadDoc] = useState(false);
  const [OfferValidDate, setOfferValidityDate] = useState('');
  const [offerAnnotation, setOfferAnnotation] = useState('');

  const offerFiles = useSelector(currentOfferFilesSelector);
  const offerDocuments = offer.documents.length ? offer.documents : offerFiles;

  useEffect(() => {
    if (!pool) return;
    let offerValidity = pool.offerMinimumValidity;
    let offerCreatedDate = offer.createdAt;
    let validity = offer.offerValidityDate
      ? moment(new Date(offer.offerValidityDate))
      : moment(new Date(offerCreatedDate)).add(offerValidity, 'days');

    setOfferValidityDate(validity.format());
  }, [pool, offer]);

  useEffect(() => {
    if (!pool?.id) return;
    dispatch(poolGetOfferAnnotationById(pool.id));
  }, [dispatch, pool?.id]);

  const isOfferPublished = checkIsOfferPublished(offer.state);
  const isHomeCheck = checkIsHomeCheck(offer.state);
  const isInvoicePublished = checkIsInvoicePublished(offer.state);

  const isPayByIntermediateInstallation =
    clientRequest?.installationOfferApproval === OfferApproval.PayByIntermediate;

  const isPayByIntermediateInvoiceInstallation =
    clientRequest?.installationInvoiceApproval === OfferApproval.PayByIntermediate;

  const isPayByIntermediateHomeCheck =
    clientRequest?.homeCheckOfferApproval === OfferApproval.PayByIntermediate;

  const isHomeCheckReplaceEnabled =
    clientRequest?.homeCheckOfferApproval === OfferApproval.ReplaceByIntermediate;

  const isReplaceEnabled =
    clientRequest?.installationOfferApproval === OfferApproval.ReplaceByIntermediate;

  const isInvoiceReplaceEnabled =
    clientRequest?.installationInvoiceApproval === OfferApproval.ReplaceByIntermediate;

  const isPayByIntermediate = useCallback(() => {
    if (isHomeCheck) {
      return isPayByIntermediateHomeCheck;
    } else {
      return isInvoicePublished
        ? isPayByIntermediateInvoiceInstallation
        : isPayByIntermediateInstallation;
    }
  }, [
    isHomeCheck,
    isPayByIntermediateHomeCheck,
    isInvoicePublished,
    isPayByIntermediateInvoiceInstallation,
    isPayByIntermediateInstallation,
  ]);

  const isOfferReplaceable = useMemo(() => {
    if (isHomeCheck) return isHomeCheckReplaceEnabled;

    return isInvoicePublished ? isInvoiceReplaceEnabled : isReplaceEnabled;
  }, [
    isHomeCheck,
    isHomeCheckReplaceEnabled,
    isInvoicePublished,
    isInvoiceReplaceEnabled,
    isReplaceEnabled,
  ]);
  const isCustomerPriceEditable = useMemo(() => {
    return isOfferReplaceable || isPayByIntermediate();
  }, [isOfferReplaceable, isPayByIntermediate]);
  const isOfferHomeCheckInstaller = !!offer.documents?.find(
    (d: any) => d.document.category === MediaCategoryType.OfferHomeCheckInstaller,
  );
  const isOfferBidInstaller = !!offer.documents?.find(
    (d: any) => d.document.category === MediaCategoryType.OfferBidInstaller,
  );
  const isOfferHomeCheck = !!offer.documents?.find(
    (d: any) => d.document.category === MediaCategoryType.OfferHomeCheck,
  );
  const isInstallationOrderConfirmation = !!offer.documents?.find(
    (d: any) => d.document.category === MediaCategoryType.InstallationOrderConfirmation,
  );
  const isOfferInvoiceHomeCheck = !!offer.documents?.find(
    (d: any) => d.document.category === MediaCategoryType.OfferInvoiceHomeCheck,
  );
  const isOfferInvoiceHomeCheckInstaller = !!offer.documents?.find(
    (d: any) => d.document.category === MediaCategoryType.OfferInvoiceHomeCheckInstaller,
  );
  const isOfferInvoiceInstallation = !!offer.documents?.find(
    (d: any) => d.document.category === MediaCategoryType.OfferInvoiceInstallation,
  );
  const isOfferInvoiceInstallationInstaller = !!offer.documents?.find(
    (d: any) => d.document.category === MediaCategoryType.OfferInvoiceInstallationInstaller,
  );

  const isReplaceButton =
    isOfferReplaceable &&
    (!offer.documents?.length ||
      (isOfferHomeCheckInstaller &&
        !isOfferBidInstaller &&
        !isOfferHomeCheck &&
        !isInstallationOrderConfirmation) ||
      (!isOfferHomeCheckInstaller &&
        isOfferBidInstaller &&
        !isOfferHomeCheck &&
        !isInstallationOrderConfirmation) ||
      (isOfferHomeCheckInstaller &&
        isOfferBidInstaller &&
        isOfferHomeCheck &&
        !isInstallationOrderConfirmation) ||
      (isOfferInvoiceHomeCheckInstaller && !isOfferInvoiceHomeCheck) ||
      (isOfferInvoiceInstallationInstaller && !isOfferInvoiceInstallation));

  const initHomeCheckPrice =
    offer.state === OfferState.InvoiceSubmittedHomeCheck ||
    offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck
      ? offer.invoiceHomeCheck
      : offer.costEstimateHomeCheck;

  const initPrice =
    offer.state === OfferState.InvoiceSubmittedInstallation
      ? offer.invoiceAmount
      : offer.costEstimate;

  const [price, setPrice] = useState(isHomeCheck ? initHomeCheckPrice : initPrice);
  const vat = pool?.country?.vatRate;

  const onPublishClick = () => {
    const clearCostEstimateHomeCheck = parseFloat(offer.costEstimateHomeCheck);
    const clearCostEstimate = parseFloat(offer.costEstimate);
    const clearInvoiceHomeCheck = parseFloat(offer.invoiceHomeCheck);
    const clearInvoiceAmount = parseFloat(offer.invoiceAmount);
    const clearPrice = parseFloat(price);
    let costFields: any = isHomeCheck
      ? {
          initialCostEstimateHomeCheck: clearCostEstimateHomeCheck,
          costEstimateHomeCheck: isCustomerPriceEditable ? clearPrice : clearCostEstimateHomeCheck,
        }
      : {
          initialCostEstimate: clearCostEstimate,
          costEstimate: isCustomerPriceEditable ? clearPrice : clearCostEstimate,
        };

    if (
      offer.state === OfferState.InvoiceSubmittedHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedInstallation
    ) {
      costFields = isHomeCheck
        ? {
            initialInvoiceHomeCheck: clearInvoiceHomeCheck,
            invoiceHomeCheck: isCustomerPriceEditable ? clearPrice : clearInvoiceHomeCheck,
          }
        : {
            initialInvoiceAmount: clearInvoiceAmount,
            invoiceAmount: isCustomerPriceEditable ? clearPrice : clearInvoiceAmount,
          };
    }

    dispatch(
      offerTransition(
        offer.state === OfferState.InvoiceSubmittedHomeCheck ||
          offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck ||
          offer.state === OfferState.InvoiceSubmittedInstallation
          ? OfferEventType.INTERMEDIATE_ACCEPTED
          : OfferEventType.COORDINATOR_ACCEPTED,
        {
          id: offer.id,
          vatRate: vat,
          offerAnnotation: offerAnnotation,
          ...costFields,
        },
        (data: any) => {
          setOffer((prevOffer: any) => ({
            ...prevOffer,
            ...data,
            documents: prevOffer.documents,
            installerUser: prevOffer.installerUser,
          }));
          message.info(t('intermediate:myProjects:offerPublished'));
        },
      ),
    );
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files && event.target.files[0];
    let uploadCategory = isHomeCheck
      ? MediaCategoryType.OfferHomeCheck
      : MediaCategoryType.InstallationOrderConfirmation;
    if (offer.documents.length && offer.documents.length === 3) {
      uploadCategory = MediaCategoryType.InstallationOrderConfirmation;
    }
    if (
      offer.state === OfferState.InvoiceSubmittedHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck
    ) {
      uploadCategory = MediaCategoryType.OfferInvoiceHomeCheck;
    }
    if (offer.state === OfferState.InvoiceSubmittedInstallation) {
      uploadCategory = MediaCategoryType.OfferInvoiceInstallation;
    }
    if (file) {
      setFileUploading(true);
      dispatch(
        offerFileUpload(
          offer.id,
          uploadCategory,
          file,
          null,
          (document: any) => {
            setOffer({
              ...offer,
              documents: [
                ...offer.documents,
                {
                  id: document.id,
                  document,
                },
              ],
            });
            setFileUploading(false);
            if (fileInput.current) {
              fileInput.current.value = '';
            }
          },
          (error: any) => {
            setFileUploading(false);
            if (fileInput.current) {
              fileInput.current.value = '';
            }
          },
        ),
      );
    }
  };

  const handleRejectCR = (rejectReason: string) => {
    dispatch(
      offerTransition(
        OfferEventType.INTERMEDIATE_REJECTED,
        {
          rejectReason,
          id: offer.id,
        },
        () => {
          message.info(t('intermediate:myProjects:offerRejected'));
          navigate(PATH.HOME);
        },
      ),
    );
  };

  const setUpdatedData = (data: any) => {
    setOffer({
      ...data,
      documents: offer.documents,
      installerUser: offer.installerUser,
    });
  };

  const handlePaidOffer = (isCustomerInvoice = true) => {
    const isTransition =
      (offer.customerInvoicePaid && !isCustomerInvoice) ||
      (offer.installerInvoicePaid && isCustomerInvoice);
    const updateData: IOffer = isCustomerInvoice
      ? { customerInvoicePaid: !offer.customerInvoicePaid }
      : { installerInvoicePaid: !offer.installerInvoicePaid };
    if (isTransition) {
      dispatch(
        offerTransition(
          OfferEventType.INVOICE_PAID,
          { id: offer.id, ...updateData },
          setUpdatedData,
        ),
      );
    } else {
      dispatch(updateOffer(offer.id, updateData, setUpdatedData));
    }
  };

  const rejectModalProps = {
    onSubmit: handleRejectCR,
    onCancel: () => setShowRejectModal(false),
    visible: showRejectModal,
    title: t('intermediate:myProjects:rejectOffer'),
  };

  const reminderModalProps = {
    visible: showReminder,
    onSubmit: (value: any) => {
      if (clientRequest.ownerId) {
        dispatch(
          sendEmailReminder({
            userId: clientRequest.ownerId,
            text: value,
            onSuccess: () => {
              setShowReminder(false);
            },
          }),
        );
      }
    },
    onCancel: () => setShowReminder(false),
  };

  const handleUpdateAnnotation = (offerAnnotation: string, useAsDefault: boolean) => {
    if (!pool) return;
    setOfferAnnotation(offerAnnotation);
    if (useAsDefault) {
      dispatch(
        customDocumentCreate(
          pool.id,
          {
            category: CustomDocumentsCategoryType.OfferAnnotation,
            text: offerAnnotation,
            media: [],
          },
          customDocumentCreateSuccess(),
        ),
      );
    } else setShowAnnotation(false);
  };

  function customDocumentCreateSuccess() {
    setShowAnnotation(false);
  }

  const annotationModalProps = {
    offer,
    useAsDefault: false,
    visible: showAnnotation,
    onSubmit: handleUpdateAnnotation,
    onCancel: () => setShowAnnotation(false),
  };

  const uploadDocumentModalProps = {
    offerId: offer.id,
    visible: showUploadDoc,
    onSubmit: () => setShowUploadDoc(false),
    onCancel: () => setShowUploadDoc(false),
    onSuccess: () => setShowUploadDoc(false),
  };

  const renderDropdown = () => {
    const offerActionDropdownProps = {
      offer,
      isReplaceEnabled: isOfferReplaceable,
      isOfferPublished,
      onShowReminder: () => setShowReminder(true),
      isPublishState: !isReplaceButton,
      updateOffer: setOffer,
    };

    return <OfferActionsDropdown {...offerActionDropdownProps} />;
  };

  const showPaidButton =
    offer.state === OfferState.InvoiceAcceptedInstallation ||
    offer.state === OfferState.InvoicePaymentPendingHomeCheck ||
    offer.state === OfferState.InvoicePaymentPendingRemoteHomeCheck;

  const renderAcceptButtonText = () => {
    if (isReplaceButton) {
      return t('intermediate:myProjects:replaceOffer');
    }
    if (isPayByIntermediate()) {
      if (
        offer.state === OfferState.InvoiceSubmittedInstallation ||
        offer.state === OfferState.InvoiceSubmittedHomeCheck ||
        offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck
      ) {
        return t('intermediate:myProjects:acceptInvoice');
      }
      return t('intermediate:myProjects:confirmOffer');
    }
    return t('intermediate:myProjects:publishOffer');
  };

  const onAcceptButtonClick = () => {
    if (isPayByIntermediate() && !isReplaceButton) {
      return;
    }
    if (isReplaceButton) {
      fileInput?.current?.click();
    } else {
      onPublishClick();
    }
  };

  const renderRejectButtonText = () => {
    if (
      offer.state === OfferState.InvoiceSubmittedInstallation ||
      offer.state === OfferState.InvoiceSubmittedHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck
    ) {
      return t('intermediate:myProjects:rejectInvoice');
    }
    return t('intermediate:myProjects:rejectOffer');
  };

  const handleDownloadDocuments = () => {
    dispatch(downloadAllDocuments(offer.id));
  };

  const renderInvoiceTags = () => {
    const isShowTags =
      offer.state === OfferState.InvoiceSubmittedHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck ||
      offer.state === OfferState.InvoiceAcceptedHomeCheck ||
      offer.state === OfferState.InvoicePaidHomeCheck ||
      offer.state === OfferState.InvoicePaidRemoteHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedInstallation ||
      offer.state === OfferState.InvoiceAcceptedInstallation ||
      offer.state === OfferState.HomeCheckInvoiceRequested;

    return isShowTags ? (
      <>
        <Tag>
          {t(
            `intermediate:myProjects:installerInvoice${
              offer.installerInvoicePaid ? 'Paid' : 'Pending'
            }`,
          )}
        </Tag>
        <Tag>
          {t(
            `intermediate:myProjects:customerInvoice${
              offer.customerInvoicePaid ? 'Paid' : 'Pending'
            }`,
          )}
        </Tag>
      </>
    ) : null;
  };
  return (
    <div className={styles.offer} key={offer.id}>
      <Row align="middle" className={clsx([styles.offerHeader, styles.block])}>
        <Col span={16}>
          <div className={styles.idTitle}>
            {`${t('intermediate:myProjects:offer:displayId')}: `}
            <span className={styles.bold}>{offer.displayId}</span>
          </div>
          <OfferTag type={TagType.pool} state={offer.state} />
          {renderInvoiceTags()}
        </Col>
        <Col span={8} className={styles.updatedLastDate}>
          <div>
            {`${t('intermediate:myProjects:offer:offerValidityDate')}: `}
            <span className={styles.bold}>
              <DateFormat date={new Date(OfferValidDate)} />
            </span>
          </div>
          <div className={styles.lastDate}>
            {`${t('intermediate:myProjects:offer:lastUpdateDate')}: `}
            <span className={styles.bold}>
              <DateFormat date={offer.updatedAt} />
            </span>
          </div>
        </Col>
      </Row>
      <Row className={clsx([styles.block, styles.operatorHandles])}>
        <Col span={5}>
          <div className={clsx([styles.name, styles.bold])}>
            {`${offer.installerUser?.firstName} ${offer.installerUser?.lastName}`}
          </div>
          <div className={styles.priceTitle}>
            {t('intermediate:myProjects:offer:installerName')}
          </div>
        </Col>
        <Col span={14}>
          {isOfferPublished ? (
            <Row justify="end" align="middle" className={clsx([styles.done, styles.bold])}>
              <CheckOutlined />
              {` ${t('intermediate:myProjects:offer:requestDone')}`}
              {renderDropdown()}
            </Row>
          ) : (
            <Row className={styles.buttons}>
              {showPaidButton ? (
                <>
                  <Button
                    color={offer.installerInvoicePaid ? ButtonColor.GREEN : undefined}
                    className={styles.button}
                    type="primary"
                    size="small"
                    onClick={() => handlePaidOffer(false)}
                  >
                    {t('intermediate:myProjects:markInstallerInvoicePaid')}
                  </Button>
                  <Button
                    color={offer.customerInvoicePaid ? ButtonColor.GREEN : undefined}
                    className={styles.button}
                    type="primary"
                    size="small"
                    onClick={() => handlePaidOffer()}
                  >
                    {t('intermediate:myProjects:markCustomerInvoicePaid')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={styles.button}
                    type="primary"
                    danger={true}
                    size="small"
                    onClick={() => setShowRejectModal(true)}
                  >
                    {renderRejectButtonText()}
                  </Button>
                  <Popconfirm
                    title={t('intermediate:myProjects:publishConfirmation')}
                    okText={t('common:accept')}
                    cancelText={t('common:dontAccept')}
                    disabled={!isPayByIntermediate() || isReplaceButton}
                    onConfirm={onPublishClick}
                  >
                    {
                      <Button // @ts-ignore
                        type={isReplaceButton ? 'secondary' : 'primary'}
                        className={styles.button}
                        size="small"
                        icon={<ReloadOutlined />}
                        iconPosition={IconPosition.LEFT}
                        disabled={!isReplaceButton && !price}
                        onClick={onAcceptButtonClick}
                        loading={fileUploading}
                      >
                        {renderAcceptButtonText()}
                      </Button>
                    }
                  </Popconfirm>
                  <Button
                    className={styles.dropdownButton}
                    size="small"
                    onClick={() => setShowAnnotation(true)}
                  >
                    <AlignLeftOutlined />
                  </Button>
                  {renderDropdown()}
                </>
              )}
              <input
                type="file"
                accept="application/pdf"
                style={{ display: 'none' }}
                ref={fileInput}
                onChange={onFileChange}
              />
            </Row>
          )}
        </Col>
      </Row>

      <Row className={styles.block} gutter={[8, 8]}>
        {offer.initialCostEstimateHomeCheck && (
          <>
            <Col span={4}>
              <div className={clsx([styles.price, styles.bold])}>
                {formatCurrency(offer.initialCostEstimateHomeCheck, offer.currencySymbol)}
              </div>
              <div className={styles.priceTitle}>
                {t('intermediate:myProjects:offer:installerHomeCheckPrice')}
              </div>
            </Col>
            <Col span={4}>
              {offer.initialCostEstimate || offer.initialInvoiceHomeCheck ? (
                <div className={clsx([styles.price, styles.bold])}>
                  {formatCurrency(offer.costEstimateHomeCheck, offer.currencySymbol)}
                </div>
              ) : (
                <NumericInput
                  disabled={isOfferPublished || !isOfferReplaceable}
                  prefix={offer.currencySymbol || '€'}
                  value={formatPrice(price)}
                  onValueChange={(values) => setPrice(values.value)}
                />
              )}
              <div className={styles.priceTitle}>
                {!offer.initialCostEstimate && !offer.initialInvoiceHomeCheck && (
                  <Tooltip title={t('intermediate:myProjects:offer:enterCustomerPrice')}>
                    <InfoCircleOutlined />
                    &nbsp;
                  </Tooltip>
                )}
                {t('intermediate:myProjects:offer:customerHomeCheckPrice')}
              </div>
            </Col>
          </>
        )}
        {offer.initialInvoiceHomeCheck && (
          <Col span={4}>
            <div className={clsx([styles.price, styles.bold])}>
              {formatCurrency(offer.initialInvoiceHomeCheck, offer.currencySymbol)}
            </div>
            <div className={styles.priceTitle}>
              {t('intermediate:myProjects:offer:homeCheckInstallerInvoiceAmount')}
            </div>
          </Col>
        )}

        {offer.invoiceHomeCheck && (
          <Col span={4}>
            {offer.state !== OfferState.InvoiceSubmittedHomeCheck &&
            offer.state !== OfferState.InvoiceSubmittedRemoteHomeCheck ? (
              <div className={clsx([styles.price, styles.bold])}>
                {formatCurrency(offer.invoiceHomeCheck, offer.currencySymbol)}
              </div>
            ) : (
              <NumericInput
                disabled={isOfferPublished || !isCustomerPriceEditable}
                prefix={offer.currencySymbol || '€'}
                value={formatPrice(price)}
                onValueChange={(values) => setPrice(values.value)}
              />
            )}
            <div className={styles.priceTitle}>
              <Tooltip title={t('intermediate:myProjects:offer:enterCustomerPrice')}>
                <InfoCircleOutlined />
                &nbsp;
              </Tooltip>
              {t('intermediate:myProjects:offer:homeCheckCustomerInvoiceAmount')}
            </div>
          </Col>
        )}
        {offer.initialCostEstimate && (
          <>
            <Col span={4}>
              <div className={clsx([styles.price, styles.bold])}>
                {formatCurrency(offer.initialCostEstimate, offer.currencySymbol)}
              </div>
              <div className={styles.priceTitle}>
                {t('intermediate:myProjects:offer:installerInstallationPrice')}
              </div>
            </Col>
            <Col span={4}>
              {offer.initialInvoiceAmount ? (
                <div className={clsx([styles.price, styles.bold])}>
                  {formatCurrency(offer.costEstimate, offer.currencySymbol)}
                </div>
              ) : (
                <NumericInput
                  suffix={offer.currencySymbol || '€'}
                  disabled={isOfferPublished || !isCustomerPriceEditable}
                  value={formatPrice(price)}
                  onValueChange={(values) => setPrice(values.value)}
                />
              )}
              <div className={styles.priceTitle}>
                {!offer.costEstimate && (
                  <Tooltip title={t('intermediate:myProjects:offer:enterCustomerPrice')}>
                    <InfoCircleOutlined />
                    &nbsp;
                  </Tooltip>
                )}
                {t('intermediate:myProjects:offer:customerInstallationPrice')}
              </div>
            </Col>
          </>
        )}
        {offer.initialInvoiceAmount && (
          <Col span={4}>
            <div className={clsx([styles.price, styles.bold])}>
              {formatCurrency(offer.initialInvoiceAmount, offer.currencySymbol)}
            </div>
            <div className={styles.priceTitle}>
              {t('intermediate:myProjects:offer:installerInvoiceAmount')}
            </div>
          </Col>
        )}
        {offer.invoiceAmount && (
          <Col span={4}>
            {offer.state !== OfferState.InvoiceSubmittedInstallation ? (
              <div className={clsx([styles.price, styles.bold])}>
                {formatCurrency(offer.invoiceAmount, offer.currencySymbol)}
              </div>
            ) : (
              <NumericInput
                disabled={isOfferPublished || !isCustomerPriceEditable}
                prefix={offer.currencySymbol || '€'}
                value={formatPrice(price)}
                onValueChange={(values) => setPrice(values.value)}
              />
            )}
            <div className={styles.priceTitle}>
              <Tooltip title={t('intermediate:myProjects:offer:enterCustomerPrice')}>
                <InfoCircleOutlined />
                &nbsp;
              </Tooltip>
              {t('intermediate:myProjects:offer:customerInvoiceAmount')}
            </div>
          </Col>
        )}
        {offer.initialVatRate && (
          <Col span={4}>
            <div className={clsx([styles.price, styles.bold])}>{offer.initialVatRate}%</div>
            <div className={styles.priceTitle}>Installers&apos;s VAT</div>
          </Col>
        )}
        {offer.initialVatRate && (
          <Col span={4}>
            <NumericInput disabled prefix="%" value={vat?.toString()} isAllowed={percentFilter} />
            <div className={styles.priceTitle}>Customer&apos;s VAT</div>
          </Col>
        )}
      </Row>

      <Col span={24}>
        <ShowMore
          details={showDetails}
          setDetails={setShowDetails}
          headerChild={<InfoRow offer={offer} />}
          footerChild={<AnnotationsRow offer={offer} />}
          handleDownloadDocuments={handleDownloadDocuments}
          setShowUploadDoc={setShowUploadDoc}
          documents={offerDocuments}
        />
      </Col>

      <ConfirmationModal {...rejectModalProps} />
      <ReminderModal {...reminderModalProps} />
      <AnnotationModal {...annotationModalProps} />
      <UploadDocumentModal {...uploadDocumentModalProps} />
    </div>
  );
};

export default OfferItem;
