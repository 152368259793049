import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Flex, Form, Row, Select, Typography } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import NumericInput from 'components/ui/NumberInput/NumericInput';
import Loader from 'components/ui/Loader/Loader';
import {
  defaultSettingsSelector,
  getDefaultSettingsState,
} from 'store/organization/organization.selectors';
import { IOrganizationDefaultSettings } from 'store/organization/organization.types';
import { getDefaultSettings, saveDefaultSettings } from 'store/organization/organization.actions';
import { getCurrenciesByOrganization } from 'store/intermediate/intermediate.actions';
import { currenciesSelector } from 'store/intermediate/intermediate.selectors';
import { RequestState } from 'store/common.types';
import { IOrganization } from 'store/intermediate/intermediate.types';
import { percentFilter } from 'utils/currencyUtils';
import styles from './DefaultSetting.module.sass';

interface IProps {
  organization: IOrganization;
}

export const DefaultSettings: React.FC<IProps> = ({ organization }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currencies = useSelector(currenciesSelector);

  const [form] = Form.useForm();

  const defaultSettings = useSelector(defaultSettingsSelector);
  const defaultSettingsState = useSelector(getDefaultSettingsState);

  useEffect(() => {
    if (organization?.id) {
      dispatch(getCurrenciesByOrganization(organization.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    !defaultSettings ? dispatch(getDefaultSettings()) : form.setFieldsValue(defaultSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSettings]);

  const onSave = (data: IOrganizationDefaultSettings) => {
    const currencySymbol = currencies.currencySymbol;
    dispatch(saveDefaultSettings({ ...data, currencySymbol }));
  };

  const countriesOptions = [
    {
      label: `${currencies.currencySymbol} ${currencies.currency}`,
      value: currencies.currency,
    },
  ];

  return (
    <div className={styles.container}>
      {defaultSettingsState === RequestState.Loading ? (
        <Loader />
      ) : (
        <Form onFinish={onSave} form={form}>
          <Typography.Title level={5}>
            {t('organization:defaultSettings:category:appointmentOffTendergy:header')}
          </Typography.Title>
          <Form.Item name="appointmentOffTendergy" valuePropName="checked">
            <Checkbox>
              {t('organization:defaultSettings:category:appointmentOffTendergy:label')}
            </Checkbox>
          </Form.Item>
          <Typography.Title level={5}>
            {t('organization:defaultSettings:category:homecheckPrice:header')}
          </Typography.Title>
          <Row gutter={[16, 16]} align="middle" className={styles.row}>
            <Col span={8}>
              <Typography>
                {t('organization:defaultSettings:category:homecheckPrice:label')}
              </Typography>
            </Col>
            <Col span={10}>
              <Form.Item name="homeCheckPrice" noStyle>
                <NumericInput label="Cost estimate for a home check" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="vat" noStyle>
                <NumericInput
                  isAllowed={percentFilter}
                  label={`% ${t('intermediate:myProjects:bundle:vat')}`}
                />
              </Form.Item>
            </Col>
            <Col span={10} offset={8}>
              <Form.Item name="currency">
                <Select
                  placeholder={t('intermediate:myProjects:bundle:bundleCurrency')}
                  options={countriesOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>{t('organization:defaultSettings:category:validityDuration:label')}</Col>
            <Col span={10}>
              <Form.Item name="offerValidityDays" colon={false} noStyle>
                <NumericInput label={t('installerFlow:tenderParticipate:withReservation:days')} />
              </Form.Item>
            </Col>
          </Row>
          <Flex justify="flex-end">
            <Form.Item className={styles.submit} noStyle>
              <Button type="primary" htmlType="submit">
                {t('common:buttons:saveChanges')}
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      )}
    </div>
  );
};
