/* eslint-disable react/display-name */
import React from 'react';
import { TableProps } from 'antd/lib/table';
import clsx from 'clsx';
import styles from './BiLingualTable.module.sass';
import Table from '../Table/Table';

export interface IBiLingualData {
  german: string;
  english: string;
  bold?: boolean;
  subSection?: boolean;
  indented?: true;
}

export const rendrerFn = (text: any, _: IBiLingualData) => (
  <div dangerouslySetInnerHTML={{ __html: text }} />
);

const BiLingualTable: React.FC<TableProps<IBiLingualData>> = (props) => {
  return (
    <Table
      className={styles.biLingualTable}
      rowClassName={(record, _) =>
        clsx({
          [styles.outlined]: record.bold,
          [styles.subSection]: record.subSection,
          [styles.indented]: record.indented,
        })
      }
      columns={props.columns}
      dataSource={props.dataSource}
    />
  );
};

export default BiLingualTable;
