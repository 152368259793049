import frFR from 'antd/locale/fr_FR';
import itIT from 'antd/locale/it_IT';
import deDE from 'antd/locale/de_DE';
import enGB from 'antd/locale/en_GB';
import { fr, it, de, enGB as enGBDateFns } from 'date-fns/locale';
import { Locale } from 'antd/lib/locale';
import { IUserObject, TUserRoles } from '../store/auth/auth.types';
import type { Locale as DateFnsLocale } from 'date-fns';

export const AUTH_TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const FINGERPRINT_KEY = 'fingerprint';
export const LANGUAGE_KEY = 'i18nextLng';
export const USER_ROLE_ADMIN: TUserRoles = 'admin';
export const USER_ROLE_CUSTOMER: TUserRoles = 'user';
export const USER_ROLE_INSTALLER: TUserRoles = 'installer';
export const USER_ROLE_INTERMEDIATE_MANAGER: TUserRoles = 'intermediate manager'; // Pool operator
export const USER_ROLE_INSTALLER_MANAGER: TUserRoles = 'installer manager';
export const APP_NAME = 'tendergy';

export const GENDER_OPTIONS = [
  { value: 0, label: 'installerFlow:registration:female' },
  { value: 1, label: 'installerFlow:registration:male' },
  { value: 2, label: 'installerFlow:registration:other' },
];

export const DEFAULT_USER_OBJECT: IUserObject = {
  email: '',
  id: '',
  name: '',
  firstName: '',
  lastName: '',
  roles: [],
  exp: 0,
  iat: 0,
};

export const SCROLL_CONFIG: any = {
  behavior: 'smooth',
  block: 'center',
  inline: 'center',
};

export const DEFAULT_SELECTED_WEEKDAYS = ['1', '2', '3', '4', '5'];

export const MODAL_TERMS_KEY = 'MODAL_TERMS_KEY';
export const MODAL_DATA_PROTECTION_KEY = 'MODAL_DATA_PROTECTION_KEY';
export const MODAL_SELECT_DATE_KEY = 'MODAL_SELECT_DATE_KEY';
export const MODAL_CUSTOM_DATA_PROTECTION_KEY = 'MODAL_CUSTOM_DATA_PROTECTION_KEY';

export const STEPS_CONFIG = {
  requirements: {
    step: 1,
  },
  wallbox: {
    step: 2,
  },
  cost: {
    step: 3,
    numberOfSubSteps: 3,
    defaultActiveSubStep: 0,
  },
  request: {
    step: 4,
    numberOfSubSteps: 5,
    defaultActiveSubStep: 0,
  },
  offer: {
    step: 5,
    numberOfSubSteps: 2,
    defaultActiveSubStep: 0,
  },
  installation: {
    step: 6,
  },
};

export const DEFAULT_STEPPER_CONFIG = {
  wallboxStep: STEPS_CONFIG.wallbox.step,
  costCalculationStep: STEPS_CONFIG.cost.step,
  requestStep: STEPS_CONFIG.request.step,
  offerStep: STEPS_CONFIG.offer.step,
  installationStep: STEPS_CONFIG.installation.step,
};

export const DEFAULT_PAGINATION_PAGE_SIZE = 10;

export const DEFAULT_DATE_FORMAT = 'L';
export const DEFAULT_TIME_FORMAT = 'LT';

export const CUSTOM_POOL_AVATAR_KEY = 'custom-pool-avatar';
export const CUSTOM_POOL_ACCENT_COLOR_KEY = 'custom-accent-color';
export const CUSTOM_POOL_FONT_KEY = 'custom-font';
export const CUSTOM_POOL_IMPRINT_KEY = 'custom-imprint';
export const CUSTOM_POOL_DATA_PRIVACY_KEY = 'custom-data-privacy';
export const CREATE_SURVEY_POOL_KEY = 'creat-survey-pool';
export const DEFAULT_COLOR = '#172638';

export const DEFAULT_META_VALUE = {
  limit: DEFAULT_PAGINATION_PAGE_SIZE,
  offset: 0,
};

export const DEFAULT_FILTERS_VALUE: { state: []; query: string } = {
  state: [],
  query: '',
};

// [Sync]: Same as Moment locales in App.tsx
export enum SupportedLanguages {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  IT = 'it',
}
// [Sync]: Same as Moment locales in App.tsx
export const SupportedLocales: Record<SupportedLanguages, Locale> = {
  en: enGB,
  de: deDE,
  fr: frFR,
  it: itIT,
};
// [Sync]: Same as Moment locales in App.tsx
/*
 * [WTF]: Why do you use two different libraries for date picking?
 * This should be removed after fixing this ****
 */
export const SupportedDateFnsLocales: Record<SupportedLanguages, DateFnsLocale> = {
  en: enGBDateFns,
  de,
  fr,
  it,
};
export const GOOGLE_CAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
