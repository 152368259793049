import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Skeleton } from 'antd';
import ArrowDown from 'assets/icons/chevron-down.svg';
import BackButton from 'components/ui/BackButton/BackButton';
import NextButton from 'components/ui/NextButton/NextButton';
import styles from './BoxContent.module.sass';
import Button, { ButtonColor } from '../../ui/Button/Button';
import Icon from '../../ui/Icon/Icon';

interface IBoxContentProps extends WithTranslation {
  boxClassName?: string;
  boxContentClassName?: string;

  title?: any;
  children: any;

  nextButtonText?: string;
  additionalButtonText?: string;

  showBackButton?: boolean;
  showNextButton?: boolean;
  showAdditionalButton?: boolean;

  nextButtonLoading?: boolean;
  nextOnEnter?: boolean;
  isContentLoading?: boolean;
  onNextClick?: () => void;
  onBackClick?: () => void;
}

interface IBoxContentState {
  showArrow: boolean;
}

class BoxContent extends Component<IBoxContentProps, IBoxContentState> {
  constructor(props: IBoxContentProps) {
    super(props);

    this.state = {
      showArrow: false,
    };
  }

  private containerRef: any = React.createRef();

  static defaultProps = {
    title: '',

    showBackButton: true,
    showNextButton: false,
    showAdditionalButton: false,
    nextButtonLoading: false,
    nextOnEnter: true,

    boxClassName: '',
    boxContentClassName: '',
  };

  handleContainerScroll = () => {
    // We need to calculate this each time because have cases when some answer can disable next questions
    const containerRect = this.containerRef.current.getBoundingClientRect();
    const containerScrollHeight = this.containerRef.current.scrollHeight - containerRect.height;

    // 100 - is some padding for hiding arrow a little bit early before container end
    const scrollTopHeight = this.containerRef.current.scrollTop + 100;
    const showArrowRule = scrollTopHeight < containerScrollHeight && !this.state.showArrow;
    const hideArrowRule = scrollTopHeight > containerScrollHeight && this.state.showArrow;

    if (showArrowRule) {
      this.setState(() => ({
        showArrow: true,
      }));
    }

    if (hideArrowRule) {
      this.setState(() => ({
        showArrow: false,
      }));
    }
  };

  renderTitle = () => {
    const { title } = this.props;
    return typeof this.props.title === 'string' ? <h4 className={styles.text}>{title}</h4> : title;
  };

  renderNavigation = () => {
    const {
      nextButtonText,
      additionalButtonText,
      showAdditionalButton,
      showBackButton,
      showNextButton,
      nextButtonLoading,
      onBackClick,
      onNextClick,
      t,
    } = this.props;

    const nextText: string = nextButtonText || t('common:buttons:further');
    const additionalText: string = additionalButtonText || t('common:buttons:button');

    return (
      <>
        {showBackButton && (
          <BackButton key="back-button" onClick={onBackClick}>
            {t('common:buttons:back')}
          </BackButton>
        )}

        {this.state.showArrow && (
          <span className={styles.arrowIcon}>
            <Icon icon={ArrowDown} />
          </span>
        )}

        <div className={styles.nextButtons}>
          {showAdditionalButton && (
            <Button type="primary" color={ButtonColor.WHITE}>
              {additionalText}
            </Button>
          )}
          {showNextButton && (
            <NextButton key="forward-button" loading={nextButtonLoading} onClick={onNextClick}>
              {nextText}
            </NextButton>
          )}
        </div>
      </>
    );
  };

  handleKeyDown = (e: any) => {
    const { showNextButton, onNextClick, nextOnEnter } = this.props;
    if (e.key === 'Enter' && showNextButton && onNextClick && nextOnEnter) {
      onNextClick();
    }
  };

  render() {
    const {
      title,
      boxClassName,
      boxContentClassName,
      children,
      showBackButton,
      showNextButton,
      showAdditionalButton,
    } = this.props;

    const navClassName = clsx(styles.navigation, {
      [styles.withoutNav]: !showNextButton && !showBackButton && !showAdditionalButton,
    });

    const containerClassName = clsx(styles.container, boxClassName);
    const contentClassName = clsx(styles.content, boxContentClassName);
    if (this.props.isContentLoading) {
      return (
        <div className={containerClassName}>
          <div className={contentClassName}>
            <Skeleton />
          </div>
        </div>
      );
    }

    return (
      <div className={containerClassName} onKeyDown={this.handleKeyDown} tabIndex={-1}>
        {title ? <div className={styles.title}>{this.renderTitle()}</div> : null}
        <div
          className={contentClassName}
          ref={this.containerRef}
          onScroll={this.handleContainerScroll}
        >
          {children}
        </div>
        <div className={navClassName}>{this.renderNavigation()}</div>
      </div>
    );
  }
}

export default withTranslation()(BoxContent);
