import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import WebFont from 'webfontloader';

import Cookies from 'universal-cookie';
import moment from 'moment';
// [Sync]: Same as SupportedLanguages & SupportedLocales
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';

import { useTranslation } from 'react-i18next';
import CookieConsentModal from 'components/modals/CookieConsentModal/CookieConsentModal';
import DeliveryDateModal from 'components/modals/DeliveryDateModal/DeliveryDateModal';
import PoolThemeContext from 'context/poolTheme.context';
import { createFingerprint } from 'framework/fingerprint';
import { fingerprintSelector, isCustomerSelector } from 'store/auth/auth.selectors';

import './App.sass';
import { theme } from 'theme/theme';
import {
  CUSTOM_POOL_ACCENT_COLOR_KEY,
  CUSTOM_POOL_FONT_KEY,
  DEFAULT_COLOR,
  FINGERPRINT_KEY,
  SupportedLanguages,
  SupportedLocales,
} from './framework/constants';
import { isRegistrationInviteTokenSelector } from './store/register/register.selectors';
import {
  allowPoolFontSelector,
  allowPoolAccentColorSelector,
  currentClientRequestSelector,
} from './store/client-request/client-request.selectors';
import { authInit, getFingerprint } from './store/auth/auth.actions';
import ContactModal from './components/modals/ContactModal/ContactModal';

const cookies = new Cookies();

interface IAppProps {
  children: React.ReactElement;
}

const App: React.FC<IAppProps> = (props) => {
  const poolTheme = useState({ color: '#1C6EF7', fontFamily: '' });
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(SupportedLocales[i18n.language as SupportedLanguages]);
  const location = useLocation();

  const fingerprint = useSelector(fingerprintSelector);
  const clientRequest = useSelector(currentClientRequestSelector);
  const inviteTokenData = useSelector(isRegistrationInviteTokenSelector);
  const allowPoolAccentColor = useSelector(allowPoolAccentColorSelector);
  const allowPoolFont = useSelector(allowPoolFontSelector);
  const isCustomer = useSelector(isCustomerSelector);
  // Checking permission and setup custom accent color
  useEffect(() => {
    const inviteCustomColor = inviteTokenData?.clientRequestPool?.customAccentColor;
    const inviteCustomFont = inviteTokenData?.clientRequestPool?.customFont;

    // checking for invited user
    if (inviteTokenData?.clientRequestPool && (inviteCustomColor || inviteCustomFont)) {
      const allowInviteAccentColor =
        inviteTokenData.clientRequestPool.membership?.optionsIncluded?.some(
          (option: any) => option.name === CUSTOM_POOL_ACCENT_COLOR_KEY,
        );

      const allowInviteFont = inviteTokenData.clientRequestPool.membership?.optionsIncluded?.some(
        (option: any) => option.name === CUSTOM_POOL_FONT_KEY,
      );

      if (allowInviteAccentColor || allowInviteFont) {
        poolTheme[1]({
          fontFamily: inviteCustomFont || '',
          color: inviteCustomColor || DEFAULT_COLOR,
        });
      }
    }

    // checking for registered user
    if (
      (allowPoolAccentColor || allowPoolFont) &&
      (clientRequest?.pool?.customAccentColor || clientRequest?.pool?.customFont)
    ) {
      poolTheme[1]({
        fontFamily: clientRequest?.pool?.customFont || '',
        color: clientRequest?.pool?.customAccentColor || DEFAULT_COLOR,
      });
    }
  }, [clientRequest, inviteTokenData]); // eslint-disable-line

  useEffect(() => {
    const cookieConsent = cookies.get('CookieConsent');
    const isCookiesEnabled = cookieConsent && cookieConsent !== 'rejected';
    if (
      // eslint-disable-next-line
      process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' &&
      location &&
      isCookiesEnabled &&
      cookieConsent.includes('ga')
    ) {
      // @ts-ignore
      window.gtag('set', 'page', location.pathname + location.search);
      // @ts-ignore
      window.gtag('send', 'pageview');
    }
  }, [location]);

  useEffect(() => {
    if (fingerprint) {
      dispatch(authInit());
    } else {
      createFingerprint().then((fg) => {
        localStorage.setItem(FINGERPRINT_KEY, fg);
        dispatch(getFingerprint(fg));
      });
    }
  }, [fingerprint]); // eslint-disable-line

  useEffect(() => {
    const poolFont =
      inviteTokenData?.clientRequestPool?.customFont || clientRequest?.pool?.customFont;

    if (poolFont) {
      WebFont.load({
        google: {
          families: [poolFont],
        },
      });
    }
  }, [clientRequest, inviteTokenData]);

  useEffect(() => {
    if (Object.keys(SupportedLocales).includes(i18n.language)) {
      moment.locale(i18n.language);
      setLocale(SupportedLocales[i18n.language as SupportedLanguages]);
    }
  }, [i18n.language]);
  const getHelmet = () => (
    <Helmet>
      <title>Tendergy</title>
    </Helmet>
  );

  return (
    <PoolThemeContext.Provider value={poolTheme}>
      <ConfigProvider theme={theme} locale={locale}>
        <div className="App">
          {getHelmet()}
          {props.children}
          {clientRequest && <DeliveryDateModal />}
          {isCustomer && <ContactModal pool={clientRequest?.pool} />}
          <CookieConsentModal />
        </div>
      </ConfigProvider>
    </PoolThemeContext.Provider>
  );
};

export default App;
