import React, { useCallback } from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IProduct } from 'store/product/product.types';
import { priceFieldFormatter } from 'utils/product';

import styles from './ProductWizardOptions.module.sass';
import { currentClientRequestSelector } from '../../../../../../../store/client-request/client-request.selectors';

interface IProductWizardOptionsProps {
  products: IProduct[];
  onChange?: any;
  value?: any;
  id?: string;
}

const ProductWizardOptions: React.FC<IProductWizardOptionsProps> = (props) => {
  const { t } = useTranslation();
  const { onChange, value, products, id } = props;
  const clientRequest = useSelector(currentClientRequestSelector);
  const getDescription = (value: string) => {
    return draftToHtml(JSON.parse(value));
  };
  const pageLocizeKey = 'customerFlow:productWizard:productSelection:product';
  const productClickHandler = useCallback(
    (product: IProduct) => {
      if (!onChange) return;
      if (value && value.id === product.id) {
        onChange(null);
        return;
      }
      onChange(product);
    },
    [onChange, value],
  );
  return (
    <>
      {products.map((product) => (
        <div
          id={id}
          key={product.id}
          className={clsx(styles.product, {
            [styles.checked]: value?.id === product.id,
          })}
          onClick={() => productClickHandler(product)}
        >
          <Typography.Title level={4} className={styles.title}>
            {product.title}
          </Typography.Title>
          <div className={styles.body}>
            <div>
              <img alt="Product" src={product.mediaUrl} className={styles.image} />
            </div>
            <div className={styles.description}>
              <Typography.Text strong>{t(`${pageLocizeKey}:description`)}</Typography.Text>
              <div dangerouslySetInnerHTML={{ __html: getDescription(product.description!) }} />
            </div>
          </div>
          <div className={styles.footer}>
            <Typography.Title level={5} className={styles.title}>
              {product.onRequestPrice
                ? product.onRequestPriceTitle
                : priceFieldFormatter(
                    product.grossPrice,
                    clientRequest?.pool?.country.currencySymbol || '',
                  )}
            </Typography.Title>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProductWizardOptions;
