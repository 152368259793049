import { NumberFormatValues } from 'react-number-format';
import {
  IClientRequestSearchResult,
  IClientRequest,
  IClientRequestCost,
} from 'store/client-request/client-request.types';
import { IOption } from 'store/common.types';
import { ICountry, IPool } from 'store/intermediate/intermediate.types';
import { CostCalculationModeOptionsType } from '../types/pool-settings.types';

export const formatPrice = (value: any) => {
  const isNumber = parseFloat(value);
  if (Number.isNaN(isNumber)) return undefined;
  return isNumber
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const formatCurrency = (value: any, currency?: string) => {
  const isNumber = parseFloat(value);
  if (Number.isNaN(isNumber)) return 'n/a';
  return `${isNumber
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}${currency || '€'}`;
};

export const removeThousandSeparator = (value: any): number => {
  if (typeof value === 'number') {
    return value;
  }
  const isReplaced = value.replaceAll('.', '').replace(',', '.');
  return parseFloat(isReplaced);
};

export const getPriceRange = (clientRequest: IClientRequest | IClientRequestSearchResult) => {
  const pool = clientRequest?.pool;
  const minPrice =
    clientRequest.poolCostEstimateMin || pool?.costEstimateMin || clientRequest.costEstimateMin;

  const maxPrice =
    clientRequest.poolCostEstimateMax || pool?.costEstimateMax || clientRequest.costEstimateMax;

  return {
    min: minPrice,
    max: maxPrice,
  };
};

export const formatPriceRange = (clientRequest: IClientRequest | IClientRequestSearchResult) => {
  const priceRange = getPriceRange(clientRequest);
  return `${formatCurrency(priceRange.min)} - ${formatCurrency(priceRange.max)}`;
};

export const getPrice = (calculatedPrice: IClientRequestCost, pool?: IPool | null) => {
  const min =
    pool?.costCalculationMode === CostCalculationModeOptionsType.Range
      ? pool.costEstimateMin
      : calculatedPrice.min;

  const max =
    pool?.costCalculationMode === CostCalculationModeOptionsType.Range
      ? pool.costEstimateMax
      : calculatedPrice.max;

  return { min, max };
};

export const percentFilter = ({ floatValue }: NumberFormatValues) =>
  !floatValue || (!!floatValue && floatValue >= 0 && floatValue <= 100);

export const getDropDownOptions = (value?: ICountry[], symbolValue?: boolean) =>
  (value || []).reduce((prev: IOption[], curr: ICountry) => {
    const exist = prev.find(
      (item: IOption) => item.value === (symbolValue ? curr.currencySymbol : curr.currency),
    );
    if (exist) {
      return prev;
    }
    return [
      ...prev,
      {
        label: `${curr.currencySymbol} ${curr.currency}`,
        value: symbolValue ? curr.currencySymbol : curr.currency,
      },
    ];
  }, []);
