/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, Flex, Form, Input, Select, Tag, Tooltip, Typography } from 'antd';
import { Helmet } from 'react-helmet';
import { CheckCircleTwoTone, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import Table from 'components/ui/Table/Table';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import { IPool } from 'store/intermediate/intermediate.types';

import {
  parentPoolsFilterDataSelector,
  parentPoolsFilterRequestStateSelector,
} from 'store/parentPoolsFilter/parentPoolsFilter.selectors';
import { RequestState } from 'store/common.types';
import { parentPoolsFilterAction } from 'store/parentPoolsFilter/parentPoolsFilter.actions';
import { poolsGet } from 'store/intermediate/intermediate.actions';
import {
  poolsRequestStateSelector,
  poolsSelector,
  totalPoolsSelector,
} from 'store/intermediate/intermediate.selectors';
import styles from './ProjectPools.module.sass';

interface IPoolsFilterForm {
  page: number;
  poolName: string;
  parentId?: string;
}
const normalizeSearchParam = (param?: string | null): string => (param ? param : '');

const ProjectPools: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const parentPoolsRequestState = useSelector(parentPoolsFilterRequestStateSelector);
  const parentPools = useSelector(parentPoolsFilterDataSelector);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const pools = useSelector(poolsSelector) as IPool[];
  const totalPools = useSelector(totalPoolsSelector);
  const poolsRequestState = useSelector(poolsRequestStateSelector);
  const poolName = searchParams.get('poolName');
  const parentId = searchParams.get('parentId');
  const page = +(searchParams.get('page') || '1');
  const filterPools = useCallback(
    (_page = 1, _poolName?: string, _parentId?: string) => {
      const limit = DEFAULT_PAGINATION_PAGE_SIZE;
      const offset = (_page - 1) * limit;
      dispatch(
        poolsGet({
          query: _poolName,
          parentId: _parentId,
          isParent: !_parentId,
          limit,
          offset,
        }),
      );
    },
    [dispatch],
  );
  useEffect(() => {
    filterPools(page, poolName ? poolName : undefined, parentId ? parentId : undefined);
  }, [page, filterPools, poolName, parentId]);

  const dataSource = useMemo(
    () =>
      pools?.map((item) => ({
        ...item,
        children: item.childClientRequestPool?.length ? item.childClientRequestPool : null,
      })),
    [pools],
  );

  const OfferTableColumns: ColumnsType<IPool> = [
    {
      title: t('intermediate:myProjects:projectPoolsTab:nameColumn'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (_: string, row: IPool) => (
        <div className={styles.nameCol}>
          {row.name}
          {row.isParent && (
            <Tag style={{ marginLeft: '10px' }} color="#FF9E56">
              {t('intermediate:myProjects:projectPoolsTab:parentTag')}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: t('intermediate:myProjects:projectPoolsTab:publicColumn'),
      dataIndex: 'isPublicPool',
      key: 'isPublicPool',
      align: 'center',
      render: (_: string, row: IPool) =>
        row.isPublicPool ? <CheckCircleTwoTone twoToneColor="#1C6EF7" /> : <CloseCircleOutlined />,
    },
    {
      title: t('intermediate:myProjects:projectPoolsTab:offerReplacementColumn'),
      dataIndex: 'intermediateOffer',
      key: 'intermediateOffer',
      align: 'center',
      render: (_: string, row: IPool) =>
        row.installationOfferApproval === 'replaceByIntermediate' ? (
          <CheckCircleTwoTone twoToneColor="#1C6EF7" />
        ) : (
          <CloseCircleOutlined />
        ),
    },
    {
      title: t('intermediate:myProjects:projectPoolsTab:invoiceReplacementColumn'),
      dataIndex: 'intermediateOffer',
      key: 'intermediateOffer',
      align: 'center',
      render: (_: string, row: IPool) =>
        row.installationInvoiceApproval === 'replaceByIntermediate' ? (
          <CheckCircleTwoTone twoToneColor="#1C6EF7" />
        ) : (
          <CloseCircleOutlined />
        ),
    },
    {
      title: t('intermediate:myProjects:projectPoolsTab:homeReplacementColumn'),
      dataIndex: 'intermediateHomeCheckOffer',
      key: 'intermediateHomeCheckOffer',
      align: 'center',
      render: (_: string, row: IPool) =>
        row.homeCheckOfferApproval === 'replaceByIntermediate' ? (
          <CheckCircleTwoTone twoToneColor="#1C6EF7" />
        ) : (
          <CloseCircleOutlined />
        ),
    },
    {
      title: t('intermediate:myProjects:projectPoolsTab:createdColumn'),
      dataIndex: 'date',
      key: 'date',
      render: (_: string, row: IPool) => <DateFormat date={new Date(row.createdAt)} />,
    },
    {
      title: t('intermediate:myProjects:projectPoolsTab:updatedColumn'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_: string, row: IPool) => <DateFormat date={new Date(row.updatedAt)} />,
    },
  ];

  const parentsFilterHandler = (poolName: string) => {
    debounce(() => {
      dispatch(parentPoolsFilterAction(poolName));
    }, 100)();
  };
  useEffect(() => {
    dispatch(parentPoolsFilterAction(''));
  }, [dispatch]);
  const parentOptions = parentPools?.items.map((pool) => ({
    value: pool.id,
    label: pool.name,
  }));
  const poolsFilterHandler = (filterFormsValues: IPoolsFilterForm) => {
    setSearchParams({
      poolName: normalizeSearchParam(filterFormsValues.poolName),
      parentId: normalizeSearchParam(filterFormsValues.parentId),
      page: '1',
    });
  };
  const changePageHandler = (page: number) => {
    setSearchParams((_searchParams) => ({
      poolName: normalizeSearchParam(poolName),
      parentId: normalizeSearchParam(parentId),
      page: page.toString(),
    }));
  };
  return (
    <Flex gap={16} vertical={true}>
      <Helmet>
        <title>{`${t('intermediate:myProjects:projectPoolsTab:title')} - ${t(
          'header:myProjects',
        )} - Tendergy`}</title>
      </Helmet>
      <Flex align="center" justify="space-between">
        <Typography.Title level={3}>
          {t('intermediate:myProjects:projectPoolsTab:title')}
          <Tooltip
            title={t('intermediate:myProjects:projectPoolsTab:notificationContent')}
            placement="bottom"
            className={styles.tooltip}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </Typography.Title>
        <Button
          type="primary"
          onClick={() => {
            navigate('/my-projects/create');
          }}
        >
          {t('intermediate:myProjects:projectPoolsTab:createPool')}
        </Button>
      </Flex>
      <Card title={t('intermediate:myProjects:filters:title')}>
        <Form
          layout={'inline'}
          onFinish={poolsFilterHandler}
          initialValues={{
            parentId,
            poolName,
          }}
          className={styles.form}
        >
          <Form.Item
            label={t('intermediate:myProjects:projectPoolsTab:nameColumn')}
            name={'poolName'}
            className={styles.formItem}
          >
            <Input size={'large'} />
          </Form.Item>
          <Form.Item
            label={t('intermediate:myProjects:projectPoolsTab:parent')}
            name={'parentId'}
            className={styles.formItem}
          >
            <Select
              size={'large'}
              showSearch
              onSearch={parentsFilterHandler}
              loading={parentPoolsRequestState === RequestState.Loading}
              options={parentOptions}
              filterOption={false}
              allowClear={true}
              onClear={() => parentsFilterHandler('')}
              onChange={() => parentsFilterHandler('')}
            />
          </Form.Item>
          <Form.Item className={styles.formItem}>
            <Button type="primary" htmlType={'submit'} size={'small'}>
              {t('intermediate:myProjects:filters:title')}
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <div className={styles.table}>
        <Table
          columns={OfferTableColumns}
          rowKey="id"
          loading={poolsRequestState === RequestState.Loading}
          dataSource={dataSource}
          emptyText={t('installerFlow:dashboard:noOpenOrders')}
          onRowClick={(pool: IPool) => `/my-projects/${pool.id}/requests`}
          pagination={{
            size: 'default',
            current: page,
            pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
            total: totalPools,
            onChange: changePageHandler,
            showSizeChanger: false,
          }}
        />
      </div>
    </Flex>
  );
};

export default ProjectPools;
