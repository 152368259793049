import config from 'config';
import { fileDownload } from 'framework/fileDownload';
import { IOffer, OfferEventType } from 'store/offer/offer.types';
import { IFindWithRelations, ISearchDto } from 'store/common.types';
import HttpService from './http.service';

class OfferAPI extends HttpService {
  OFFER_API = 'offers';

  create = (params: IOffer) => {
    return this.post(this.OFFER_API, { ...params });
  };

  transition = (type: OfferEventType, offer: IOffer): Promise<IOffer> => {
    return this.put(`${this.OFFER_API}/transition`, {
      type,
      offer,
    });
  };

  update = (id: string, offer: IOffer) => {
    return this.put(`${this.OFFER_API}/${id}`, { ...offer });
  };

  getById = ({ id, relations }: IFindWithRelations) => {
    return this.get(`${this.OFFER_API}/${id}`, { relations });
  };

  getOffersListByClientRequestId = (id: string) => {
    return this.get(`${this.OFFER_API}/client-request/${id}/all-offers-for-customer`);
  };

  getByClientRequestId = (id: string) => {
    return this.get(`${this.OFFER_API}/client-request/${id}/offer-for-customer`);
  };

  getByClientRequestIdByInstaller = (id: string) => {
    return this.get(`${this.OFFER_API}/client-request/${id}/offer-by-installer`);
  };

  findOrdersByOrganization = (searchOptions: ISearchDto) => {
    return this.get(`${this.OFFER_API}/organization`, {
      ...searchOptions,
      sortFields: 'createdAt',
      sortDirections: -1,
    });
  };

  findMyOrders = (searchOptions: ISearchDto) => {
    return this.get(`${this.OFFER_API}/mine`, {
      ...searchOptions,
      sortFields: 'createdAt',
      sortDirections: -1,
    });
  };

  findMyInProgressOrders = (searchOptions: ISearchDto) => {
    return this.get(`${this.OFFER_API}/in-progress`, {
      ...searchOptions,
      sortFields: 'createdAt',
      sortDirections: -1,
    });
  };

  findMyCompletedOrders = (searchOptions: ISearchDto) => {
    return this.get(`${this.OFFER_API}/completed`, {
      ...searchOptions,
      sortFields: 'createdAt',
      sortDirections: -1,
    });
  };

  deleteById = (id: string) => {
    return this.delete(`${this.OFFER_API}/${id}`);
  };

  countInprogressOffers = () => {
    return this.get(`${this.OFFER_API}/in-progress/count`);
  };

  getInvoices = () => {
    return this.get(`${this.OFFER_API}/documents/invoices`);
  };

  downloadCalendarEvent = async (offerId: string, fileName?: string) => {
    const headers = this.getHeaders({}, true);
    const res = await this.client.request({
      url: `${config.CRAFT_SERVICE_URL}/${this.OFFER_API}/${offerId}/calendar-event`,
      responseType: 'blob',
      headers,
    });
    fileDownload(res.data, fileName || 'event.ics');
  };

  // download all documents
  downloadAllDocuments = async (id: string) => {
    const headers = this.getHeaders({}, true);
    const res = await this.client.request({
      url: `${config.CRAFT_SERVICE_URL}/${this.OFFER_API}/${id}/documents/archive`,
      responseType: 'blob',
      headers,
    });
    const fileName = `offer-${id}-archive.zip`;
    fileDownload(res.data, fileName);
  };

  reassignInstallerUser = (offerId: string, installerId: string) => {
    return this.patch(`${this.OFFER_API}/${offerId}/installer/${installerId}`);
  };

  getOfferDocsForInstaller = (offerId: string) => {
    return this.get(`${this.OFFER_API}/${offerId}/my-documents`);
  };
}

export default new OfferAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
