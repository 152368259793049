import React from 'react';

import ProjectPools from './ProjectPools/ProjectPools';

const MyProjects: React.FC = () => {
  return (
    <div style={{ paddingTop: '20px' }}>
      <ProjectPools />
    </div>
  );
};

export default MyProjects;
