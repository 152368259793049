import config from 'config';
import { fileDownload } from 'framework/fileDownload';
import {
  ClientRequestState,
  IClientRequest,
  MediaCategoryType,
} from 'store/client-request/client-request.types';
import { ISearchDto } from 'store/common.types';
import {
  ICustomDocument,
  IOrganizationInvite,
  IPool,
  IPoolCreate,
  IPoolOffersParams,
  ISearchPoolDto,
  SlugCategoryType,
} from 'store/intermediate/intermediate.types';
import { IInviteTokenData } from 'store/register/register.types';
import HttpService from './http.service';

class IntermediateAPI extends HttpService {
  INTERMEDIATE_API = 'intermediates';
  ORGANIZATION_API = 'organizations';
  DOCUMENTS_API = 'files';
  CLIENT_REQUEST_POOL_API = 'client-request-pools';
  INVITE_TOKEN_API = 'invite-tokens';
  CUSTOM_DOCUMENTS_API = 'custom-documents';

  findOrganization = (searchValue: string, intermediateOrganizationId?: string, limit?: number) => {
    const isIntermediate = intermediateOrganizationId
      ? `/intermediate/${intermediateOrganizationId}`
      : '';
    return this.get(
      `${this.ORGANIZATION_API}${isIntermediate}?query=${searchValue}&limit=${limit}`,
    );
  };

  getPoolClientRequests = (
    searchValue: string = '',
    state?: ClientRequestState[],
    offset: number = 0,
    archived: any = '',
  ) => {
    return this.get(`${this.INTERMEDIATE_API}/client-requests`, {
      sortFields: 'createdAt',
      sortDirections: -1,
      query: searchValue,
      state,
      offset,
      archived,
    });
  };

  getPoolOffers = (params: IPoolOffersParams) => {
    return this.get(`${this.INTERMEDIATE_API}/offers`, {
      sortFields: 'createdAt',
      sortDirections: -1,
      ...params,
    });
  };

  updatePool = (poolId: string, pool: IPool) => {
    return this.put(`${this.INTERMEDIATE_API}/pools/${poolId}`, { ...pool });
  };

  getPoolById = (poolId: string) => {
    // [TODO]: Remove countries
    return this.get(`${this.INTERMEDIATE_API}/pools/${poolId}`, {
      relations: ['intermediateOrganization', 'countries', 'country'],
      requestsCount: true,
    });
  };

  getPoolRequestsByPoolId = (
    searchDto: ISearchDto,
    poolId: string,
    filters?: { state: string[]; query: string },
  ): Promise<IClientRequest[]> => {
    const params = {
      sortFields: 'createdAt',
      sortDirections: -1,
      ...searchDto,
      ...filters,
    };
    return this.get(`${this.INTERMEDIATE_API}/pools/${poolId}/requests`, params);
  };

  getPools = (params?: ISearchPoolDto) => {
    return this.get(`${this.INTERMEDIATE_API}/pools/`, {
      ...params,
    });
  };

  getStats = () => {
    return this.get(`${this.INTERMEDIATE_API}/stats/`);
  };

  createPool = (params: IPoolCreate) => {
    return this.post(`${this.INTERMEDIATE_API}/pools/`, { ...params });
  };

  uploadPoolFile = (
    document: File,
    poolId: string,
    category: MediaCategoryType,
    onUploadProgress?: any,
  ) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const data = new FormData();

    data.append('document', document);
    data.append('category', category);

    return this.post(
      `${this.CLIENT_REQUEST_POOL_API}/${poolId}/media/upload`,
      data,
      true,
      headers,
      onUploadProgress,
    );
  };

  getPoolFiles = (poolId: string, category: MediaCategoryType) => {
    const params = {
      category,
    };
    const url = `${this.CLIENT_REQUEST_POOL_API}/${poolId}/media`;

    return this.get(url, params);
  };

  getPoolDocuments = (poolId: string) => {
    return this.get(`${this.CLIENT_REQUEST_POOL_API}/${poolId}/custom-documents`);
  };

  createPoolDocuments = (poolId: string, document: any) => {
    return this.post(`${this.CLIENT_REQUEST_POOL_API}/${poolId}/custom-documents`, {
      ...document,
    });
  };

  updatePoolDocument = (poolId: string, customDocumentsId: string, document: any) => {
    return this.put(
      `${this.CLIENT_REQUEST_POOL_API}/${poolId}/custom-documents/${customDocumentsId}`,
      { ...document },
    );
  };
  deletePoolDocument = (customDocumentsId: String) => {
    return this.delete(`custom-documents/${customDocumentsId}`);
  };
  getParentPools = () => {
    return this.get(`${this.CLIENT_REQUEST_POOL_API}/parent-pools`);
  };

  getOfferAnnotation = (poolId: string) => {
    return this.get(`${this.CLIENT_REQUEST_POOL_API}/${poolId}/offer-annotation`);
  };

  inviteOrganization = (params: IOrganizationInvite) => {
    return this.post(`${this.INTERMEDIATE_API}/organizations/invite`, { ...params });
  };

  getQuestionnaireConfigForCustomer = () => {
    return this.get(`${this.CLIENT_REQUEST_POOL_API}/questionnaire-config`);
  };

  getQuestionnaireConfig = (poolId: string) => {
    return this.get(`${this.CLIENT_REQUEST_POOL_API}/${poolId}/questionnaire-configs`);
  };

  getQuestionnaire = (poolId: string) => {
    return this.get(`public/${this.CLIENT_REQUEST_POOL_API}/${poolId}/questionnaire`);
  };

  createQuestionnaireConfig = (poolId: string, config: any) => {
    return this.post(`${this.CLIENT_REQUEST_POOL_API}/${poolId}/questionnaire-configs`, {
      ...config,
    });
  };

  updateQuestionnaireConfig = (poolId: string, configId: string, config: any) => {
    return this.put(`${this.CLIENT_REQUEST_POOL_API}/${poolId}/questionnaire-configs/${configId}`, {
      ...config,
    });
  };

  deleteQuestionnaireConfig = (poolId: string, configId: string) => {
    return this.delete(
      `${this.CLIENT_REQUEST_POOL_API}/${poolId}/questionnaire-configs/${configId}`,
    );
  };

  deletePerson = (poolId: string, personId: string) =>
    this.delete(`${this.CLIENT_REQUEST_POOL_API}/${poolId}/contact-person/${personId}`);

  getLandingPage = (poolId: string, category: string) => {
    return this.get(`public/${this.CLIENT_REQUEST_POOL_API}/${poolId}/${category}`);
  };
  getServiceFeeDescription = (poolId: string) => {
    return this.get(
      `public/${this.CLIENT_REQUEST_POOL_API}/${poolId}/${SlugCategoryType.PoolServiceFreeDescription}`,
    );
  };
  updateInviteToken = (tokenId: string, token: IInviteTokenData) => {
    return this.put(`${this.INVITE_TOKEN_API}/${tokenId}`, {
      ...token,
    });
  };

  uploadCustomDocumentImage = (
    document: File,
    category: MediaCategoryType,
    onUploadProgress?: any,
  ) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const data = new FormData();

    data.append('document', document);
    data.append('category', category);

    return this.post(`${this.DOCUMENTS_API}/upload`, data, true, headers, onUploadProgress);
  };

  getCustomDocumentImages = (customDocumentId: string) => {
    return this.get(`${this.CUSTOM_DOCUMENTS_API}/${customDocumentId}/media`);
  };

  getCustomDocumentMediaLink = (customDocumentId: string, mediaName: string) => {
    const encodedName = encodeURIComponent(mediaName);
    return this.get(`public/${this.CUSTOM_DOCUMENTS_API}/${customDocumentId}/media/${encodedName}`);
  };

  inviteExistingOrganization = (poolId: string, organizationId: string) => {
    return this.post(`${this.INTERMEDIATE_API}/organizations/invite/existing`, {
      poolId,
      organizationId,
    });
  };

  createOrderInstallerDirectly = (id: string, organization: string) => {
    return this.post(`orders/client-request/${id}`, {
      installerOrganization: organization,
    });
  };

  orderDownLoadPdf = async (requestId: string, orgId: string) => {
    const headers = this.getHeaders({}, true);
    try {
      const res = await this.client.request({
        method: 'post',
        url: `${config.CRAFT_SERVICE_URL}/orders/client-request/${requestId}/document`,
        data: {
          installerOrganization: orgId,
        },
        responseType: 'blob',
        headers,
      });
      const fileName = 'tendergy-order-preview.pdf';
      fileDownload(res.data, fileName);
    } catch (e) {
      console.log(e);
    }
  };

  getOrganizationsInvites = (poolId: string) => {
    return this.get(`${this.INTERMEDIATE_API}/organizations/invite/existing/${poolId}`);
  };
  deleteOrganizationInvite = (inviteId: string) => {
    return this.delete(`${this.INTERMEDIATE_API}/organizations/invite/${inviteId}`);
  };

  getCurrenciesByOrganization = (organizationId: string) => {
    return this.get(`organizations/${organizationId}/currencies`);
  };

  getPoolInviteTokens = (poolId: string) => {
    return this.get(`${this.INTERMEDIATE_API}/pools/${poolId}/invite-tokens`);
  };

  sendEmailReminder = (userId: string, text: string) => {
    return this.post(`${this.INTERMEDIATE_API}/reminder-email`, {
      userId,
      text,
    });
  };

  getIsCurrentPoolEditable = (poolId: string) => {
    return this.get(`${this.INTERMEDIATE_API}/pools/${poolId}/editing-allowed`);
  };

  uploadOrganizationLogo = (orgId: string, logo: File, onUploadProgress?: any) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const data = new FormData();
    data.append('logo', logo);
    return this.post(
      `${this.ORGANIZATION_API}/${orgId}/logo`,
      data,
      true,
      headers,
      onUploadProgress,
    );
  };

  deleteOrganizationLogo = (orgId: string) => {
    return this.delete(`${this.ORGANIZATION_API}/${orgId}/logo`);
  };

  updateLegalDocumets = (poolId: string, documents: ICustomDocument[]) => {
    return this.post(`${this.CLIENT_REQUEST_POOL_API}/${poolId}/legal-documents`, documents);
  };

  getLegalDocumets = (poolId: string, isPublic: boolean) => {
    return this.get(
      `${isPublic ? 'public/' : ''}${this.CLIENT_REQUEST_POOL_API}/${poolId}/legal-documents`,
    );
  };

  saveLegalDocuments = (poolId: string, legalDocuments: ICustomDocument[]) => {
    return this.post(`${this.CLIENT_REQUEST_POOL_API}/${poolId}/legal-documents`, legalDocuments);
  };
}

export default new IntermediateAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
