import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  currentClientRequestSelector,
  lastVisitedStepSelector,
} from 'store/client-request/client-request.selectors';
import { clientRequestUpdate } from 'store/client-request/client-request.actions';
import { productWizardSelector } from '../store/product-component/product-component.selector';
const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

const useClientRequestStepsHandler = () => {
  const clientRequest = useSelector(currentClientRequestSelector);
  const lastVisitedStep = useSelector(lastVisitedStepSelector);
  const wizard = useSelector(productWizardSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUpdateSuccess = useCallback(
    (step: number) => {
      if (!clientRequest?.id) return;
      scrollToTop();
      const clientRequestRoute = `/customer/client-request/${clientRequest.id}`;
      switch (step) {
        case 1:
          navigate(`${clientRequestRoute}/wallbox`);
          break;
        case 2:
          navigate(`${clientRequestRoute}/questionnaire`);
          break;
        case 3:
          navigate(`${clientRequestRoute}/request`);
          break;
      }
    },
    [navigate, clientRequest],
  );

  const canGoToStep = useCallback(
    (step: number, subStep: number = 1) => {
      if (!clientRequest) return false;
      if (!clientRequest.step || !clientRequest.subStep) return false;
      if (clientRequest.step > 3) return false;
      if (step === 1) {
        if (
          !(
            clientRequest?.pool?.wizardInstallationJourney &&
            clientRequest?.pool?.wizardWallboxJourney
          )
        )
          return false;
        if (wizard.length === 0) return false;
        if (subStep === 2) {
          if (clientRequest?.products?.length === 0) return false;
        }
      }
      if (step > lastVisitedStep.step) return false;
      return !(lastVisitedStep.step === step && lastVisitedStep.subStep < subStep);
    },
    [clientRequest, lastVisitedStep, wizard],
  );
  const changeStep = useCallback(
    (step: number, subStep: number = 1, checkForAvailability = true) => {
      if (!canGoToStep(step, subStep) && checkForAvailability) return;
      if (clientRequest && clientRequest.id) {
        dispatch(
          clientRequestUpdate(clientRequest.id, { step: step, subStep: subStep }, () =>
            handleUpdateSuccess(step),
          ),
        );
      }
    },
    [dispatch, clientRequest, handleUpdateSuccess, canGoToStep],
  );
  return { canGoToStep, changeStep };
};

export default useClientRequestStepsHandler;
