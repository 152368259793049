import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useFetchCustomerLegalDocuments from 'hooks/useFetchCustomerLegalDocuments';
import { isRegistrationInviteTokenSelector } from 'store/register/register.selectors';
import { legalDocumentsSelector } from 'store/intermediate/intermediate.selectors';
import { CustomDocumentsCategoryType } from 'store/intermediate/intermediate.types';
import { isCustomerSelector } from 'store/auth/auth.selectors';
import { getLegalDocumentData } from 'utils/commonUtils';
import styles from './PrivacyPolicyPage.module.sass';
import BoxContent from '../../components/layout/BoxContent/BoxContent';
import Box from '../../components/layout/Box/Box';
import DataPrivacyComponent from '../../components/static/DataPrivacyComponent/DataPrivacyComponent';

const PrivacyPolicyPage: React.FC = () => {
  const { t } = useTranslation();

  const token = useSelector(isRegistrationInviteTokenSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const legalDocuments = useSelector(legalDocumentsSelector);
  useFetchCustomerLegalDocuments();
  const getCustomerPrivacy = () => {
    const value = getLegalDocumentData(
      legalDocuments,
      CustomDocumentsCategoryType.PoolPrivacyPolicy,
    );
    if (isCustomer || token) {
      return value ? <div dangerouslySetInnerHTML={{ __html: value }} /> : <DataPrivacyComponent />;
    }
    return <DataPrivacyComponent />;
  };

  return (
    <Box>
      <BoxContent title={t('common:texts:privacyPolicy')} showBackButton={false}>
        <div className={styles.container}>{getCustomerPrivacy()}</div>
      </BoxContent>
    </Box>
  );
};

export default PrivacyPolicyPage;
