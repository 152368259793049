import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  IInstallerState,
  INSTALLER_DATA_GET,
  INSTALLER_DATA_GET_ERROR,
  INSTALLER_DATA_GET_SUCCESS,
  INSTALLER_DATA_UPDATE,
  INSTALLER_DATA_UPDATE_ERROR,
  INSTALLER_DATA_UPDATE_SUCCESS,
  INSTALLER_INVITATIONS_GET_SUCCESS,
  INSTALLER_ORGANIZATION_GET_SUCCESS,
  InstallerActionTypes,
  JOINING_POOL_CONFIRM,
  JOINING_POOL_CONFIRM_ERROR,
  JOINING_POOL_CONFIRM_SUCCESS,
} from './installer.types';

const initialState: IInstallerState = {
  installerData: null,
  installerDataState: null,
  installerDataError: null,

  installerDataUpdateState: null,
  installerDataUpdateError: null,

  joiningPoolConfirmState: null,
  joiningPoolConfirmError: null,

  installerInvitations: [],
  installerOrganization: null,
};

const InstallerReducer = (state = initialState, action: InstallerActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case JOINING_POOL_CONFIRM:
        draft.joiningPoolConfirmState = RequestState.Loading;
        break;

      case JOINING_POOL_CONFIRM_ERROR:
        draft.joiningPoolConfirmState = RequestState.Error;
        draft.joiningPoolConfirmError = action.payload;
        break;

      case JOINING_POOL_CONFIRM_SUCCESS:
        draft.joiningPoolConfirmState = RequestState.Success;
        draft.joiningPoolConfirmError = null;
        break;

      case INSTALLER_DATA_GET:
        draft.installerDataState = RequestState.Loading;
        break;

      case INSTALLER_DATA_GET_ERROR:
        draft.installerDataState = RequestState.Error;
        draft.installerDataError = action.payload;
        break;

      case INSTALLER_DATA_GET_SUCCESS:
        draft.installerDataState = RequestState.Success;
        draft.installerDataError = null;
        draft.installerData = action.payload;
        break;

      case INSTALLER_DATA_UPDATE:
        draft.installerDataUpdateState = RequestState.Loading;
        break;

      case INSTALLER_DATA_UPDATE_ERROR:
        draft.installerDataUpdateState = RequestState.Error;
        draft.installerDataUpdateError = action.payload;
        break;

      case INSTALLER_DATA_UPDATE_SUCCESS:
        draft.installerDataUpdateState = RequestState.Success;
        draft.installerDataUpdateError = null;
        draft.installerData = action.payload;
        break;

      case INSTALLER_INVITATIONS_GET_SUCCESS:
        draft.installerInvitations = action.payload;
        break;

      case INSTALLER_ORGANIZATION_GET_SUCCESS:
        draft.installerOrganization = action.payload;
    }
  });

export default InstallerReducer;
