import React, { ReactElement } from 'react';
import { default as Modal, ModalProps } from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import { legalDocumentsSelector } from 'store/intermediate/intermediate.selectors';
import { CustomDocumentsCategoryType } from 'store/intermediate/intermediate.types';
import { getLegalDocumentData } from 'utils/commonUtils';
import DataPrivacyComponent from '../DataPrivacyComponent/DataPrivacyComponent';

export interface ITermsOfUseProps extends ModalProps {
  custom?: boolean;
  dataPrivacy?: string;
  customer?: boolean;
  type?: string;
  termsComponent?: ReactElement;
  onOk: () => void;
}

const DataProtectionModal: React.FC<ITermsOfUseProps> = ({
  onOk,
  customer,
  termsComponent,
  type,
  ...rest
}) => {
  const { t } = useTranslation();
  const legalDocuments = useSelector(legalDocumentsSelector);

  const customDocCategory =
    type === 'cancelationPolicy'
      ? CustomDocumentsCategoryType.PoolCancelationPolicy
      : CustomDocumentsCategoryType.PoolPrivacyPolicy;

  const getBody = () => {
    const value = getLegalDocumentData(legalDocuments, customDocCategory);
    if (value) {
      return <div dangerouslySetInnerHTML={{ __html: value }} />;
    } else if (customDocCategory === CustomDocumentsCategoryType.PoolPrivacyPolicy) {
      return <DataPrivacyComponent />;
    } else {
      return termsComponent;
    }
  };
  return (
    <Modal
      {...rest}
      footer={[
        <Button color={ButtonColor.GREEN} type="primary" onClick={onOk} key="ok">
          Ok
        </Button>,
      ]}
    >
      <Typography.Title>{t('common:texts:dataDeclaration')}</Typography.Title>
      {customer ? getBody() : <DataPrivacyComponent />}
    </Modal>
  );
};

export default DataProtectionModal;
