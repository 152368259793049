/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ColumnsType } from 'antd/lib/table';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import OfferTag from 'components/ui/OfferTag/OfferTag';
import Table from 'components/ui/Table/Table';
import Tag from 'components/ui/Tag/Tag';

import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import { IClientRequest } from 'store/client-request/client-request.types';
import { IPool } from 'store/intermediate/intermediate.types';
import { OfferState } from 'store/offer/offer.types';
import { unapprovedClientRequestsGet } from 'store/intermediate/intermediate.actions';

import { formatPriceRange, formatCurrency } from 'utils/currencyUtils';

const UnapprovedClientRequestsTable: React.FC<{ dataSource: any }> = ({ dataSource }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    dispatch(unapprovedClientRequestsGet(offset, ''));
  }, [offset]); // eslint-disable-line

  const UCRTableColumns: ColumnsType<IClientRequest> = [
    {
      title: 'installerFlow:dashboard:id',
      dataIndex: 'displayId',
      key: 'displayId',
    },
    {
      title: 'Pool',
      dataIndex: 'pool',
      render: (pool: IPool) => {
        return pool.name ? pool.name : '';
      },
    },
    {
      title: 'installerFlow:dashboard:period',
      dataIndex: 'period',
      key: 'period',
      render: (text: string, row: IClientRequest, index: Number) =>
        row?.soonestPossible || !row?.startDate ? (
          t('installerFlow:dashboard:soonest')
        ) : (
          <span>
            {t('installerFlow:dashboard:from')}
            <DateFormat date={row.startDate} />
          </span>
        ),
    },
    {
      title: 'installerFlow:dashboard:priceRange',
      dataIndex: 'priceRange',
      key: 'priceRange',
      render: (text: string, row: IClientRequest, index: Number) => {
        const isSkipped = row.questionnaireSkipped;
        return isSkipped ? formatCurrency(row.pool?.homeCheckEstimate) : formatPriceRange(row);
      },
    },
    {
      title: 'installerFlow:dashboard:status',
      dataIndex: 'state',
      key: 'state',
      render: (state: OfferState) =>
        state && state !== OfferState.None ? <OfferTag state={state} /> : <Tag>Unprocessed</Tag>,
    },
  ];

  return (
    <Table
      columns={UCRTableColumns.map((tender: any) => ({ ...tender, title: t(`${tender.title}`) }))}
      dataSource={dataSource.items}
      rowKey="id"
      emptyText={t('intermediate:myProjects:dashboardTab:noEntries')}
      onRowClick={(record) => `/my-projects/client-request/${record.id}`}
      pagination={{
        total: dataSource?.total || 0,
        position: ['bottomCenter'],
        pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
        current: offset ? offset / DEFAULT_PAGINATION_PAGE_SIZE + 1 : 1,
        onChange: (page) => setOffset((page - 1) * DEFAULT_PAGINATION_PAGE_SIZE),
      }}
    />
  );
};

export default UnapprovedClientRequestsTable;
