import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Modal as ANTModal } from 'antd';
import Button from 'components/ui/Button/Button';
import Loader from 'components/ui/Loader/Loader';
import DropDown from 'components/ui/DropDown/DropDown';
import IntermediateAPI from 'services/intermediate.service';
import {
  poolInviteTokensSelector,
  poolInviteTokensStateSelector,
} from 'store/intermediate/intermediate.selectors';
import { RequestState } from 'store/common.types';

import { poolOrganizationsGet } from 'store/pool-tender-organization/pool-tender-organization.actions';
import { poolOrganizationsSelector } from 'store/pool-tender-organization/pool-tender-organization.selectors';
import styles from './InstallerOrganizationsModal.module.sass';

export interface IInstallerOrganizationsModalProps {
  onCancel: () => void;
  requestId: string;
  visible: boolean;
  poolId?: string;
  onSubmit: (organizationId: string) => void;
}

const modalProps = {
  centered: true,
  closable: true,
  footer: null,
  width: 600,
};

const InstallerOrganizationsModal: React.FC<IInstallerOrganizationsModalProps> = ({
  onCancel,
  requestId,
  visible,
  poolId,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedOrganization, setSelectedOrganization] = useState<string | null>(null);
  const poolOrganizations = useSelector(poolOrganizationsSelector);
  const inviteTokens = useSelector(poolInviteTokensSelector);
  const inviteTokensState = useSelector(poolInviteTokensStateSelector);

  useEffect(() => {
    if (poolId) {
      dispatch(poolOrganizationsGet(poolId));
    }
  }, [dispatch]); // eslint-disable-line

  if (inviteTokensState === RequestState.Loading || !inviteTokens) {
    return <Loader />;
  }

  const options = poolOrganizations.map((item) => ({
    value: item.organization?.id,
    label: item.organization?.name,
  }));

  const handleSubmit = () => {
    if (selectedOrganization) {
      onSubmit(selectedOrganization);
      setSelectedOrganization(null);
    }
  };
  const onClose = () => {
    setSelectedOrganization(null);
    onCancel();
  };
  const downloadPdf = () => {
    if (selectedOrganization) {
      IntermediateAPI.orderDownLoadPdf(requestId, selectedOrganization);
    }
  };

  const ddProps = {
    options: options,
    style: { width: '100%' },
    showSearch: true,
    placeholder: t('intermediate:myProjects:companyModal:selectCompany'),
    optionFilterProp: 'children',
    onSelect: (value: string) => setSelectedOrganization(value),
  };

  return (
    <ANTModal
      className={styles.organizationModal}
      {...modalProps}
      onCancel={onClose}
      open={visible}
      title={t('intermediate:myProjects:selectInstaller')}
    >
      <div className={styles.organizationModalContainer}>
        <DropDown {...ddProps} className={styles.organizationModalDropdown} />
        {selectedOrganization && (
          <div className={styles.organizationModalDetails}>
            {t('intermediate:myProjects:orderInstallerOrg:pdfDescription')}
          </div>
        )}
        <div className={styles.modalFooter}>
          {selectedOrganization && (
            <Row justify="end">
              <Button
                type="default"
                onClick={downloadPdf}
                style={{ marginBottom: '10px', background: '#d5dfde' }}
                disabled={selectedOrganization ? false : true}
              >
                {t('common:buttons:downloadPDF')}
              </Button>
            </Row>
          )}
          <Row justify="end">
            <Button onClick={onClose} style={{ marginRight: '10px', background: '#ff4d4f' }}>
              {t('common:buttons:cancel')}
            </Button>
            <Button type="primary" onClick={handleSubmit} disabled={!selectedOrganization}>
              {selectedOrganization ? t('common:buttons:confirmOrder') : t('common:buttons:next')}
            </Button>
          </Row>
        </div>
      </div>
    </ANTModal>
  );
};

export default InstallerOrganizationsModal;
