import React from 'react';
import clsx from 'clsx';
import styles from './StatisticsCard.module.sass';

export interface ListItem {
  title: string;
  value: number;
}

export interface IProjectsSidebarProps {
  className?: string;
  list: ListItem[];
}

const StatisticsCard: React.FC<IProjectsSidebarProps> = (props) => {
  const { className, list } = props;
  return (
    <div className={clsx([className, styles.container])}>
      {list.map((item) => (
        <div key={item.title} className={styles.item}>
          <div className={styles.number}>{item.value}</div>
          <div className={styles.title}>{item.title}</div>
        </div>
      ))}
    </div>
  );
};

StatisticsCard.defaultProps = {
  list: [],
};

export default StatisticsCard;
