/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import OfferTag from 'components/ui/OfferTag/OfferTag';
import Table from 'components/ui/Table/Table';
import Tag from 'components/ui/Tag/Tag';
import Filters from 'components/ui/Filters/Filters';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import {
  organizationsFind,
  poolClientRequestsGet,
  poolOffersGet,
} from 'store/intermediate/intermediate.actions';
import {
  findOrganizations,
  poolOffers,
  poolClientRequests,
  poolOffersState,
} from 'store/intermediate/intermediate.selectors';
import { IUserObject } from 'store/auth/auth.types';
import { IClientRequest } from 'store/client-request/client-request.types';
import { Organization } from 'store/intermediate/intermediate.types';
import { IOffer, OfferState } from 'store/offer/offer.types';
import { RequestState } from 'store/common.types';

import styles from './Dashboard.module.sass';

const defaultFiltersValue = {
  search: '',
  state: [],
  organization: [],
  clientRequests: [],
};

const PoolOffersTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [offset, setOffset] = useState<number>(0);
  const [filters, setFilters] = useState(defaultFiltersValue);

  const organizations = useSelector(findOrganizations);
  const offers = useSelector(poolOffers);
  const poolLoadingOffersState = useSelector(poolOffersState);
  const findClientRequests = useSelector(poolClientRequests);

  const params = {
    state: filters.state.length ? filters.state : undefined,
    clientRequestId: filters.clientRequests.length ? filters.clientRequests : undefined,
    organizationId: filters.organization.length ? filters.organization : undefined,
    limit: DEFAULT_PAGINATION_PAGE_SIZE,
    offset: offset,
  };

  useEffect(() => {
    dispatch(organizationsFind(''));
    dispatch(poolClientRequestsGet(''));
  }, [dispatch]);

  useEffect(() => {
    setOffset(0);
    dispatch(poolOffersGet(params));
  }, [filters]); // eslint-disable-line

  useEffect(() => {
    dispatch(poolOffersGet(params));
  }, [offset]); // eslint-disable-line

  const handleFiltersChange = (name: string, value: any) => {
    const newValue = {
      ...filters,
      [name]: value,
    };

    setOffset(0);
    setFilters(newValue);
  };

  const handleClearFilters = () => {
    setFilters(defaultFiltersValue);
    setOffset(0);
  };

  const toDisplayName = (user: IUserObject | undefined) =>
    user ? `${user.firstName} ${user.lastName}`.trim() : '';

  const OfferTableColumns: ColumnsType<IOffer> = [
    {
      title: 'installerFlow:dashboard:orderNr',
      dataIndex: 'id',
      key: 'id',
      render: (_: string, row: IOffer) => row.clientRequest?.displayId,
    },
    {
      title: 'installerFlow:dashboard:name',
      dataIndex: 'name',
      key: 'name',
      render: (_: string, row: IOffer) => toDisplayName(row.installerUser),
    },
    {
      title: 'installerFlow:dashboard:progress',
      dataIndex: 'state',
      key: 'state',
      render: function renderTag(state: OfferState) {
        return state ? <OfferTag state={state}>{state}</OfferTag> : <Tag>N/A</Tag>;
      },
    },
    {
      title: 'installerFlow:dashboard:zip',
      dataIndex: 'zip',
      render: (_: string, row: IOffer) => {
        return row.clientRequest?.address ? row.clientRequest?.address.postalCode : 'n/a';
      },
    },
    {
      title: 'installerFlow:dashboard:dueDate',
      dataIndex: 'date',
      key: 'date',
      render: (_: string, row: IOffer) => {
        const date = row.finalInstallationTimestamp || row.finalHomeCheckTimestamp || '';
        return date ? <DateFormat date={date} /> : 'n/a';
      },
    },
  ];

  const stateOptions = Object.values(OfferState).map((state) => ({
    value: state,
    label: t(`common:allStates:${state}`),
  }));

  const organizationsOptions = organizations.map((i: Organization) => ({
    value: i.id,
    label: i.organization.name,
  }));

  const clientRequestOptions = findClientRequests.map((i: IClientRequest) => ({
    value: i.id,
    label: i.displayId,
  }));

  const isFiltersActive = Boolean(
    filters.search ||
      filters.state.length ||
      filters.organization.length ||
      filters.clientRequests.length,
  );

  return (
    <div className={styles.offers}>
      <div className={styles.filtersContainer}>
        <Filters
          filters={filters}
          setFilters={setFilters}
          inputName="search"
          inputValue={filters.search}
          inputPlaceholder={t('intermediate:myProjects:filters:searchField')}
          onFiltersChange={handleFiltersChange}
          onClearFilters={handleClearFilters}
          isActive={isFiltersActive}
        >
          <Filters.MultiSelect
            name="state"
            label={'Select state'}
            options={stateOptions}
            onFilterChange={() => {
              setOffset(0);
            }}
          />

          <Filters.MultiSelect
            name="organization"
            label={t('intermediate:myProjects:companyModal:selectCompany')}
            options={organizationsOptions}
            onFilterChange={() => {
              setOffset(0);
            }}
          />

          <Filters.MultiSelect
            name="clientRequests"
            label={'Select Client Requests'}
            options={clientRequestOptions}
            onFilterChange={() => {
              setOffset(0);
            }}
          />
        </Filters>
      </div>

      <Table
        loading={poolLoadingOffersState === RequestState.Loading}
        columns={OfferTableColumns.map((order: any) => ({ ...order, title: t(`${order.title}`) }))}
        rowKey="id"
        dataSource={offers?.items || []}
        onRowClick={(record) => `/my-projects/client-request/${record.clientRequest.id}`}
        pagination={{
          total: offers?.total || 0,
          position: ['bottomCenter'],
          pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
          current: offset ? offset / DEFAULT_PAGINATION_PAGE_SIZE + 1 : 1,
          onChange: (page) => setOffset((page - 1) * DEFAULT_PAGINATION_PAGE_SIZE),
        }}
      />
    </div>
  );
};

export default PoolOffersTable;
