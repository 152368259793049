import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StatisticsCard from 'components/ui/StatisticsCard/StatisticsCard';
import Tabs from 'components/ui/Tabs/Tabs';
import Loader from 'components/ui/Loader/Loader';
import {
  statsSelector,
  statsStateSelector,
  unapprovedClientRequests,
  unapprovedOffers,
} from 'store/intermediate/intermediate.selectors';
import {
  statsGet,
  unapprovedClientRequestsGet,
  unapprovedOffersGet,
} from 'store/intermediate/intermediate.actions';
import { RequestState } from 'store/common.types';
import styles from './Dashboard.module.sass';
import UnapprovedClientRequestsTable from './UnapprovedClientRequestsTable';
import PoolOffers from './PoolOffers';
import UnapprovedOffersTable from './UnapprovedOffersTable';

const ManagementDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('requests');
  const clientRequests = useSelector(unapprovedClientRequests);
  const offers = useSelector(unapprovedOffers);
  const stats = useSelector(statsSelector);
  const statsState = useSelector(statsStateSelector);

  useEffect(() => {
    dispatch(unapprovedClientRequestsGet(0, ''));
    dispatch(unapprovedOffersGet({}));
    dispatch(statsGet());
  }, [dispatch]);

  if (statsState === RequestState.Loading || !statsState) {
    return <Loader />;
  }

  const statisticsCardProps = {
    list: [
      {
        title: t('intermediate:myProjects:dashboardTab:pendingCR'),
        value: stats.pendingClientRequests,
      },
      {
        title: t('intermediate:myProjects:dashboardTab:installationsUpcoming'),
        value: stats.upcomingInstallations,
      },
      {
        title: t('intermediate:myProjects:dashboardTab:finalizedJobs'),
        value: stats.finalizedJobs,
      },
    ],
  };

  const tabsProps = {
    selected: selectedTab,
    className: styles.tabs,
    list: [
      {
        title: t('intermediate:myProjects:dashboardTab:unapprovedCR'),
        value: 'requests',
        number: clientRequests.total,
        action: () => setSelectedTab('requests'),
      },
      {
        title: t('intermediate:myProjects:dashboardTab:unapprovedOffers'),
        value: 'offers',
        action: () => setSelectedTab('offers'),
        number: offers.total,
      },
      {
        title: t('intermediate:myProjects:dashboardTab:offers'),
        value: 'allOffers',
        action: () => setSelectedTab('allOffers'),
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>{`${t('intermediate:myProjects:dashboardTab:title')} - ${t(
          'header:myProjects',
        )} - Tendergy`}</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <div className={styles.titleWrap}>
            <h2 className={styles.title}>{t('intermediate:myProjects:dashboardTab:title')}</h2>
            <StatisticsCard {...statisticsCardProps} />
          </div>
          <Tabs {...tabsProps} />
          {selectedTab === 'requests' && (
            <UnapprovedClientRequestsTable dataSource={clientRequests} />
          )}
          {selectedTab === 'offers' && <UnapprovedOffersTable dataSource={offers} />}
          {selectedTab === 'allOffers' && <PoolOffers />}
        </Col>
      </Row>
    </>
  );
};

export default ManagementDashboard;
