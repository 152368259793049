import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Typography from 'antd/lib/typography';
import Row from 'antd/lib/grid/row';
import Col from 'antd/lib/grid/col';
import { useForm } from 'antd/lib/form/Form';
import { Button, Form } from 'antd';
import moment from 'moment';
import BackButton from 'components/ui/BackButton/BackButton';

import OfferReservations from 'components/form/OfferReservations/OfferReservations';
import {
  currentOfferFilesSelector,
  currentOfferSelector,
  offerFilesStateSelector,
} from 'store/offer/offer.selectors';
import { IOffer, OfferApproval, OfferEventType, OfferState } from 'store/offer/offer.types';
import {
  getOfferFiles,
  offerByInstallerGetAction,
  offerCreate,
  offerGet,
  offerTransition,
  updateOffer,
} from 'store/offer/offer.actions';
import { clientRequestGet } from 'store/client-request/client-request.actions';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';

import { IFile, MediaCategoryType } from 'store/client-request/client-request.types';
import { RequestState } from 'store/common.types';
import { removeThousandSeparator } from 'utils/currencyUtils';
import { checkIsRejected } from 'utils/offerHelpers';
import styles from '../TenderParticipatePage/TenderParticipate.module.sass';
import IntermediateDetailsWidget from '../TenderParticipatePage/IntermediateDetailsWidget';

interface IProps {
  isHomeCheckDone?: boolean;
}

interface IFormState {
  annotation?: string;
  costEstimate?: string;
  initialCostEstimate?: number;
  vatRate?: number;
  currencySymbol?: string;
  offerValidityDays?: string;
  offerValidityDate?: string;
  documents?: IFile[];
}

const ParticipatePage: React.FC<IProps> = ({ isHomeCheckDone }) => {
  const [loading, setLoading] = useState(false);

  const currentOffer = useSelector(currentOfferSelector);
  const clientRequest = useSelector(currentClientRequestSelector);
  const offerFiles = useSelector(currentOfferFilesSelector);
  const getFilesState = useSelector(offerFilesStateSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: clientRequestId, offerId } = useParams();
  const { t } = useTranslation();

  const [form] = useForm();

  const offer = useMemo(
    () => (!checkIsRejected(currentOffer?.state!) ? currentOffer : null),
    [currentOffer],
  );

  const isShowPoolData =
    clientRequest?.pool?.installationOfferApproval === OfferApproval.ReplaceByIntermediate;

  useEffect(() => {
    if (!clientRequest?.id && offer?.clientRequestId) {
      dispatch(clientRequestGet(offer.clientRequestId, false));
    }
    if (offer?.id && !offerFiles?.length && getFilesState !== RequestState.Success) {
      dispatch(getOfferFiles(offer.id));
    }
    if (offerFiles?.length) {
      const document = offerFiles.find(
        (file) => file.document.category === MediaCategoryType.OfferBidInstaller,
      );
      if (document) {
        form.setFieldValue('files', [document?.document]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, offer, clientRequest, offerFiles]);

  useEffect(() => {
    offerId
      ? dispatch(offerGet({ id: offerId }))
      : dispatch(offerByInstallerGetAction(clientRequestId!));
  }, [clientRequestId, dispatch, offerId]);
  useEffect(() => {
    const country = clientRequest?.pool?.country;
    if (!country) return;
    form.setFieldsValue({
      vatRate: country.vatRate,
      currencySymbol: country.currencySymbol,
    });
  }, [form, clientRequest]);
  const onBackButtonClick = () =>
    isHomeCheckDone
      ? navigate(`/installer/order/${offerId}/details`)
      : navigate(`/installer/tender/${clientRequestId}`);

  const onContinueClick = useCallback(async () => {
    try {
      const values: IFormState = await form.validateFields();

      const vatRate = removeThousandSeparator(values.vatRate);
      const initialCostEstimate = removeThousandSeparator(values.costEstimate);

      const payload = {
        id: offerId,
        ...values,
        documents: undefined,
        vatRate,
        initialCostEstimate,
        offerValidityDate: form.getFieldValue('offerValidityDate'),
        costEstimate: initialCostEstimate,
        initialVatRate: vatRate,
        initialCurrencySymbol: values.currencySymbol,
      };

      setLoading(true);

      if (offer?.id) {
        if (
          !(
            offer.state === OfferState.OfferAcceptedHomeCheck ||
            offer.state === OfferState.OfferAcceptedRemoteHomeCheck
          ) &&
          (offer?.initialCostEstimate || offer?.initialCostEstimateHomeCheck)
        ) {
          dispatch(
            updateOffer(offer?.id, { ...payload, costEstimateHomeCheck: null }, () =>
              navigate('/installer/dashboard'),
            ),
          );
          return;
        }
        handleOfferTransition(payload, (offer) =>
          navigate(`/installer/order/${offer?.id}/details`),
        );
      } else {
        dispatch(
          offerCreate(
            { clientRequestId, documents: [values?.documents?.[0].id as any] },
            (offer: IOffer) => handleOfferTransition({ ...payload, id: offer.id }),
          ),
        );
      }
    } catch (e) {
      console.log(e);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, offer]);

  const handleOfferTransition = (payload: IOffer, onSuccess?: (offer: IOffer) => void) => {
    const transitionEvent =
      isHomeCheckDone &&
      ![
        OfferState.InvoicePaidHomeCheck,
        OfferState.InvoicePaidRemoteHomeCheck,
        OfferState.TenderCreated,
      ].includes(offer?.state!)
        ? OfferEventType.HOME_CHECK_COMPLETED
        : OfferEventType.INSTALLER_ACCEPTED;

    dispatch(
      offerTransition(transitionEvent, payload, (offer: IOffer) => {
        setLoading(false);
        onSuccess?.(offer) ?? navigate('/installer/dashboard');
      }),
    );
  };

  const handleFormValuesChange = (changedValues: IFormState) => {
    if (changedValues.offerValidityDays) {
      form.setFieldValue(
        'offerValidityDate',
        moment().add(changedValues.offerValidityDays, 'days').format(),
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton className={styles.back} onClick={onBackButtonClick}>
          {t('common:buttons.back')}
        </BackButton>
        <Typography.Title level={3} className={styles.title}>
          {t('installerFlow:tenderParticipate:pageTitle')}
        </Typography.Title>
      </div>
      <Form form={form} onValuesChange={handleFormValuesChange}>
        <OfferReservations offerId={offerId} />
      </Form>
      <Row gutter={[16, 16]}>
        {isShowPoolData && (
          <Col span={24}>
            <IntermediateDetailsWidget clientRequest={clientRequest} />
          </Col>
        )}
      </Row>

      <Button type="primary" onClick={onContinueClick} loading={loading} className={styles.margin}>
        {t('installerFlow:tenderDetails:buttonSubmitBinding')}
      </Button>

      <p className={styles.buttonText}>
        <small className={styles.small}>
          {t('installerFlow:tenderDetails:participateDirectlyPage:textOne')}
        </small>
        <small className={styles.small}>
          {t('installerFlow:tenderDetails:participateDirectlyPage:textTwo')}
        </small>
      </p>
    </div>
  );
};

export default ParticipatePage;
