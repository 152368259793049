import draftToHtml from 'draftjs-to-html';
import { QuestionKey } from 'store/client-request/client-request.types';
import {
  CustomDocumentsCategoryType,
  CustomDocumentTextType,
  ICustomDocument,
} from 'store/intermediate/intermediate.types';

export function mergeArraysWithoutDuplicates<T>(oldData: T[], newData: T[] | T, key: keyof T): T[] {
  const mergedMap = new Map<T[keyof T], T>();

  oldData.forEach((obj) => {
    mergedMap.set(obj[key], obj);
  });
  const newValues = Array.isArray(newData) ? newData : [newData];
  newValues.forEach((obj) => {
    mergedMap.set(obj[key], obj);
  });

  return Array.from(mergedMap.values());
}

export const getQuestionStep = (key: QuestionKey) => {
  const position = {
    step: 1,
    subStep: 1,
    path: '',
  };

  switch (key) {
    case QuestionKey.HouseType:
    case QuestionKey.HouseOwnership:
      position.subStep = 2;
      break;

    case QuestionKey.OwnParkingSpace:
    case QuestionKey.ParkingSpaceEnergy:
    case QuestionKey.ParkingSpaceLocation:
      position.subStep = 3;
      break;

    case QuestionKey.EnergyType:
    case QuestionKey.ConnectionSecurity:
    case QuestionKey.InstallationMeterLocation:
      position.step = 2;
      position.subStep = 1;
      position.path = 'cost-calculator';
      break;

    case QuestionKey.InstallationAmountWalls:
    case QuestionKey.InstallationStraightWall:
    case QuestionKey.InstallationDigging:
    case QuestionKey.InstallationDistance:
    case QuestionKey.InstallationDiggingRestauration:
    case QuestionKey.InstallationExcavationSurface:
    case QuestionKey.InstallationAffectedSurface:
      position.step = 2;
      position.subStep = 2;
      position.path = 'cost-calculator';
      break;

    case QuestionKey.CarModel:
    case QuestionKey.ChargingStation:
      position.step = 2;
      position.subStep = 3;
      position.path = 'cost-calculator';
      break;

    default:
      break;
  }

  return position;
};

export const HOOK_MARK = 'RC_FORM_INTERNAL_HOOKS';

export const firstLetterUpper = (value: string) =>
  value
    ?.split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');

export const getLegalDocumentData = (
  legalDocuments: ICustomDocument[],
  type: CustomDocumentsCategoryType,
) => {
  const entry = legalDocuments.find((item) => item?.category === type);
  if (entry && entry.textType === CustomDocumentTextType.EDITOR_TEXT) {
    return draftToHtml(JSON.parse(entry.text!));
  }
  return null;
};
