import React from 'react';
import moment from 'moment';
import { Dropdown, Menu, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import FileSyncOutlined from '@ant-design/icons/lib/icons/FileSyncOutlined';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import MoreOutlined from '@ant-design/icons/lib/icons/MoreOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import Button from 'components/ui/Button/Button';
import { offerTransition } from 'store/offer/offer.actions';
import { IOffer, OfferEventType, OfferState } from 'store/offer/offer.types';
import { deleteFile } from 'store/client-request/client-request.actions';

import styles from '../../ClientRequestPage.module.sass';

export interface IProps {
  offer: IOffer;
  onShowReminder: () => void;
  isReplaceEnabled: boolean;
  isPublishState: boolean;
  isOfferPublished: boolean;
  updateOffer: (value: IOffer) => void;
}

const OfferActionsDropdown: React.FC<IProps> = ({
  offer,
  onShowReminder,
  isReplaceEnabled,
  isPublishState,
  isOfferPublished,
  updateOffer,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showReminderButton =
    offer.state === OfferState.TenderAccepted ||
    offer.state === OfferState.TenderAcceptedHomeCheck ||
    offer.state === OfferState.TenderAcceptedRemoteHomeCheck;

  const showDeleteButton =
    isReplaceEnabled &&
    isPublishState &&
    offer.state !== OfferState.InstallationDone &&
    !isOfferPublished;

  const showHomeCheckInvoiceButton =
    offer.state === OfferState.TenderAccepted && offer.actualHomeCheckTimestamp;

  const showEditOfferButton =
    offer.state === OfferState.TenderCreated ||
    offer.state === OfferState.TenderSubmitted ||
    offer.state === OfferState.TenderAccepted ||
    offer.state === OfferState.TenderSubmittedHomeCheck ||
    offer.state === OfferState.TenderSubmittedRemoteHomeCheck ||
    offer.state === OfferState.TenderAcceptedHomeCheck ||
    offer.state === OfferState.TenderAcceptedRemoteHomeCheck;

  const showDropdown = showReminderButton || showDeleteButton || showEditOfferButton;

  const requestHomeCheckInvoice = () => {
    dispatch(
      offerTransition(
        OfferEventType.INTERMEDIATE_REQUESTED_HOME_CHECK_INVOICE,
        { id: offer.id },
        (data: any) => {
          updateOffer({
            ...data,
            documents: offer.documents,
            installerUser: offer.installerUser,
          });
          message.info(t('intermediate:myProjects:homeCheckInvoiceRequested'));
        },
      ),
    );
  };

  const deleteUploadedFile = () => {
    if (offer.documents?.length) {
      let fileToDelete = offer.documents[0];
      offer.documents.forEach((item) => {
        const currentTime = moment(fileToDelete.document.createdAt);
        const itemTime = moment(item.document.createdAt);
        if (currentTime.isBefore(itemTime)) {
          fileToDelete = item;
        }
      });
      dispatch(
        deleteFile(
          fileToDelete.document.name,
          fileToDelete.document.id,
          fileToDelete.document.category,
          () =>
            updateOffer({
              ...offer,
              documents: offer.documents?.filter((d) => d.id !== fileToDelete.id),
            }),
        ),
      );
    }
  };

  const onEditOffer = () => {
    navigate(`/installer/tender/${offer.clientRequestId}/offer-type`);
  };

  const menu = (
    <Menu>
      {showReminderButton && (
        <Menu.Item icon={<MailOutlined />} onClick={onShowReminder}>
          {t('intermediate:myProjects:reminderModal.title')}
        </Menu.Item>
      )}
      {showDeleteButton && (
        <Menu.Item
          icon={<DeleteOutlined />}
          onClick={deleteUploadedFile}
          className={styles.deleteFile}
        >
          {t('intermediate:myProjects:offer:deleteUploadedInvoice')}
        </Menu.Item>
      )}
      {showHomeCheckInvoiceButton && (
        <Menu.Item icon={<FileSyncOutlined />} onClick={requestHomeCheckInvoice}>
          {t('intermediate:myProjects:offer.requestHomeCheckInvoice')}
        </Menu.Item>
      )}
      {showEditOfferButton && (
        <Menu.Item icon={<EditOutlined />} onClick={onEditOffer}>
          {t('Edit Offer')}
        </Menu.Item>
      )}
    </Menu>
  );

  return showDropdown ? (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Button className={styles.dropdownButton} size="small">
        <MoreOutlined />
      </Button>
    </Dropdown>
  ) : null;
};

export default OfferActionsDropdown;
