import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { notification, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import Loader from 'components/ui/Loader/Loader';
import EmailVerificationStep from 'pages/auth/EmailVerificationStep/EmailVerificationStep';
import { userSelector } from 'store/auth/auth.selectors';
import { userEmailStatusSelector } from 'store/user/user.selectors';
import {
  clientRequestStateSelector,
  currentClientRequestSelector,
} from 'store/client-request/client-request.selectors';

import { clientRequestUpdate } from 'store/client-request/client-request.actions';
import { IProduct } from 'store/product/product.types';
import { DeliveryDateModalPages, showModal } from 'store/delivery-date/delivery-date.module';
import {
  productWizardSelector,
  productWizardStateSelector,
} from 'store/product-component/product-component.selector';
import { RequestState } from 'store/common.types';
import {
  getInstallationWizardCustomImageUrls,
  poolFilesGet,
} from 'store/intermediate/intermediate.actions';
import { IDocument, MediaCategoryType } from 'store/client-request/client-request.types';
import { poolFilesSelector } from 'store/intermediate/intermediate.selectors';
import styles from './ProductWizard.module.sass';
import { AnswerType } from './components/JourneyOptions/JourneyOptions';
import ProductSelection from './steps/Product/ProductSelection';
import JourneySelection from './steps/Journey/JourneySelection';
import useClientRequestStepsHandler from '../../../../../hooks/useClientRequestStepsHandler';

export enum Steps {
  JourneySelection = 1,
  ProductSelection = 2,
}

export const ProductWizard: React.FC = () => {
  const emailVerified = useSelector(userEmailStatusSelector);
  const clientRequest = useSelector(currentClientRequestSelector);
  const pool = clientRequest?.pool;

  const requestState = useSelector(clientRequestStateSelector);

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const currentStep: Steps = clientRequest?.subStep || Steps.JourneySelection;
  const wizard = useSelector(productWizardSelector);
  const wizardState = useSelector(productWizardStateSelector);
  const poolFiles = useSelector(poolFilesSelector);

  const step = useMemo(() => clientRequest?.step ?? 1, [clientRequest]);
  const { changeStep } = useClientRequestStepsHandler();
  const [form] = useForm();

  useEffect(() => {
    if (
      (!pool?.wizardInstallationJourney && !pool?.wizardWallboxJourney) ||
      (!wizard.length && wizardState === RequestState.Success)
    ) {
      productSelectionHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizard, clientRequest]);

  useEffect(() => {
    if (pool?.id) {
      dispatch(poolFilesGet(pool.id, MediaCategoryType.RequestWallboxOnly));
      dispatch(poolFilesGet(pool.id, MediaCategoryType.RequestWallboxAndInstallation));
    }
  }, [pool?.id, dispatch]);

  useEffect(() => {
    const requestWallboxOnlyFileName = poolFiles.find(
      (file: IDocument) => file.category === MediaCategoryType.RequestWallboxOnly,
    )?.name;
    const requestWallboxAndInstallationFileName = poolFiles.find(
      (file: IDocument) => file.category === MediaCategoryType.RequestWallboxAndInstallation,
    )?.name;
    if (requestWallboxOnlyFileName || requestWallboxAndInstallationFileName)
      dispatch(
        getInstallationWizardCustomImageUrls({
          wallboxOnlyFileName: requestWallboxOnlyFileName,
          wallboxAndInstallationFileName: requestWallboxAndInstallationFileName,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolFiles]);

  const renderSteps = () => {
    switch (currentStep) {
      case Steps.JourneySelection:
        return <JourneySelection form={form} />;
      case Steps.ProductSelection:
        return <ProductSelection form={form} initialProducts={clientRequest?.products} />;
      default:
        return <Loader />;
    }
  };

  const journeySelectionHandler = (values: { journeyAnswer: string }) => {
    values.journeyAnswer === AnswerType.Wallbox
      ? changeStep(1, Steps.ProductSelection, false)
      : dispatch(showModal(true, DeliveryDateModalPages.JourneySelection));
  };

  const handleUpdateSuccess = useCallback(() => {
    setLoading(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/customer/client-request/${clientRequest?.id}/questionnaire`);
  }, [setLoading, navigate, clientRequest?.id]);

  const handleUpdateError = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const productSelectionHandler = (values?: { products: IProduct[] }) => {
    if (clientRequest && clientRequest.id) {
      setLoading(true);
      dispatch(
        clientRequestUpdate(
          clientRequest.id,
          { ...values, step: step === 2 ? step : step + 1, subStep: 1 },
          handleUpdateSuccess,
          handleUpdateError,
        ),
      );
    }
  };

  const onNextClick = () => {
    form
      .validateFields()
      .then((values) => {
        switch (currentStep) {
          case Steps.JourneySelection:
            journeySelectionHandler(values);
            break;
          case Steps.ProductSelection:
            productSelectionHandler(values);
        }
      })
      .catch(() => notification.error({ message: t('common:errors:journeyNotSelected') }));
  };

  const onBackClick = useCallback(() => {
    changeStep(1, Steps.JourneySelection);
  }, [changeStep]);
  const boxProps = {
    boxContentClassName: styles.container,
    nextButtonLoading: loading,
    onNextClick,
    onBackClick,
    showBackButton: currentStep !== Steps.JourneySelection,
    showNextButton: true,
    boxClassName: styles.wizard,
    contentLoading:
      requestState === RequestState.Saving ||
      requestState === RequestState.Loading ||
      clientRequest?.step !== 1,
  };

  if (!(emailVerified || user?.emailVerified)) {
    return <EmailVerificationStep email={user.email} />;
  }

  return (
    <>
      <Helmet>
        <title>{t('customerFlow:productWizard:selectionStep:title')}</title>
      </Helmet>
      <BoxContent {...boxProps}>
        {wizardState === RequestState.Loading ? (
          <Skeleton active paragraph={{ rows: 3 }} />
        ) : (
          renderSteps()
        )}
      </BoxContent>
    </>
  );
};
