import config from 'config';
import { IInstallerData } from 'store/installer/installer.types';
import HttpService from './http.service';

class InstallerAPI extends HttpService {
  ORGANIZATIONS_API = 'organizations';

  getOrganizationData = (id: string) => {
    return this.get(`${this.ORGANIZATIONS_API}/${id}`);
  };

  verifyConfirmation = () => {
    return this.get('installers/confirmation-status');
  };

  getInstallerData = () => {
    return this.get('installers/me');
  };

  updateInstallerData = (params: IInstallerData) => {
    return this.put('installers/me', { ...params });
  };

  confirmJoiningPool = (tokenId: string) => {
    return this.put(`installers/join/${tokenId}`);
  };

  getInstallerInvitations = () => {
    return this.get('installers/invites');
  };

  submitLicenses = () => {
    return this.post('installers/license/submit');
  };
}

export default new InstallerAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
