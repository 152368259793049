import config from 'config';
import { fileDownload } from 'framework/fileDownload';
import {
  CommentType,
  IClientRequest,
  ITransitionStateUpdate,
} from 'store/client-request/client-request.types';
import HttpService from './http.service';

class ClientRequestAPI extends HttpService {
  CLIENT_REQUEST_API = 'client-requests';
  PRODUCT_MODELS_API = 'product-models';

  create = (params: IClientRequest) => {
    return this.put(`${this.CLIENT_REQUEST_API}/transition`, { ...params });
  };

  transition = (params: ITransitionStateUpdate) => {
    return this.put(`${this.CLIENT_REQUEST_API}/transition`, { ...params });
  };

  update = (id: string, fields: any) => {
    return this.put(`${this.CLIENT_REQUEST_API}/${id}`, { ...fields });
  };

  getById = (id: string) => {
    return this.get(`${this.CLIENT_REQUEST_API}/${id}`, {
      relations: [
        'bundle',
        'answers',
        'createdBy',
        'pool',
        'pool.intermediateOrganization',
        'pool.countries',
        'offers',
        'offers.installerUser',
        'offers.installerUser.organization',
        'offers.appointments',
        'offers.installedProductModel',
        'offers.installedProductModel.vendor',
        'questionnaire',
        'orders',
        'products',
        'products.component',
      ],
    });
  };
  checkPoolChange = (requestId: string, poolId: string) =>
    this.post(`${this.CLIENT_REQUEST_API}/${requestId}/can-change-pool `, {
      poolId,
    });
  getRequestDistance = (id: string) => {
    return this.get(`${this.CLIENT_REQUEST_API}/${id}/distance`);
  };

  // In the future this endpoint will return all request for the customer
  getMyRequests = () => {
    return this.get(`${this.CLIENT_REQUEST_API}/latest`);
  };
  getFotoWizardUploadToken = (id: string) => {
    return this.get(`${this.CLIENT_REQUEST_API}/${id}/documents/upload-token`);
  };
  getMyClientRequests = () => {
    return this.get(`${this.CLIENT_REQUEST_API}/me`, {
      relations: ['pool'],
      archived: '',
    });
  };
  getMyArchivedClientRequests = () => {
    return this.get(`${this.CLIENT_REQUEST_API}/me`, {
      relations: ['pool'],
      archived: true,
    });
  };

  getRequestCost = (id: string) => {
    return this.get(`${this.CLIENT_REQUEST_API}/${id}/calculate-cost`);
  };

  // find client requests (using on Installer Dashboard Page)
  findAvailable = (
    limit = 10,
    offset: number,
    distance?: string,
    zip?: string,
    state?: string[],
    query?: string,
  ) => {
    return this.get(`${this.CLIENT_REQUEST_API}/available`, {
      distance,
      zip,
      state,
      offset,
      limit,
      query: query || null,
      sortField: 'createdAt',
      sortDirection: 1,
    });
  };

  deleteById = (id: string) => {
    return this.delete(`${this.CLIENT_REQUEST_API}/${id}`);
  };

  archiveById = async (id: string, archived: boolean) => {
    return await this.put(`${this.CLIENT_REQUEST_API}/${id}/archive`, { archived: archived });
  };

  // get wallboxes
  getWallboxes = () => {
    return this.get(`${this.PRODUCT_MODELS_API}`);
  };

  createVendor = (name: string) => {
    return this.post(`product-vendors/`, { name });
  };

  createProduct = (id: string, name: string) => {
    return this.post(`products`, {
      name,
      vendor: {
        id,
      },
    });
  };

  // download all documents
  downloadAllDocuments = async (id: string) => {
    const headers = this.getHeaders({}, true);
    const res = await this.client.request({
      url: `${config.CRAFT_SERVICE_URL}/${this.CLIENT_REQUEST_API}/${id}/documents/archive`,
      responseType: 'blob',
      headers,
    });
    const fileName = `client-request-${id}-archive.zip`;
    fileDownload(res.data, fileName);
  };

  downloadSummaryPdf = async (id: string) => {
    const headers = this.getHeaders({}, true);
    const res = await this.client.request({
      url: `${config.CRAFT_SERVICE_URL}/${this.CLIENT_REQUEST_API}/${id}/generate-document`,
      responseType: 'blob',
      headers,
    });
    const fileName = `client-request-${id}-summary.pdf`;
    fileDownload(res.data, fileName);
  };

  // questions
  getComments = (id: string) => {
    return this.get(`${this.CLIENT_REQUEST_API}/${id}/comments`);
  };

  addComment = (id: string, params: { text: string; parentComment?: string }) => {
    return this.post(`${this.CLIENT_REQUEST_API}/${id}/comments`, params);
  };

  updateComment = (
    clientRequestId: string,
    commentId: string,
    params: {
      text?: string;
      parentComment?: string;
      type?: CommentType;
    },
  ) => {
    return this.put(`${this.CLIENT_REQUEST_API}/${clientRequestId}/comments/${commentId}`, params);
  };

  getHouseType = (id: string, token: string) => {
    return this.get(`${this.CLIENT_REQUEST_API}/${id}/house-type/${token}`);
  };

  assignBundleToRequest = (id: string, bundleId: string) => {
    this.put(`${this.CLIENT_REQUEST_API}/${id}/bundle/${bundleId}`);
  };
}

export default new ClientRequestAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
