import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import OfferTableWidget from 'components/widgets/OfferTableWidget';
import FiltersPanel from 'components/ui/FiltersPanel/FiltersPanel';
import FilterItemContainer from 'components/ui/FilterItemContainer/FilterItemContainer';
import Filters from 'components/ui/Filters/Filters';
import FiltersContext from 'context/filters.context';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import { useFilters } from 'hooks/useFilters';
import { foundPaginatedOfferSelector, offerFindStateSelector } from 'store/offer/offer.selectors';
import { RequestState } from 'store/common.types';
import { myOrdersFind } from 'store/offer/offer.actions';
import {
  homecheckStates,
  installationStates,
  OfferCategory,
  OfferStatusType,
} from 'store/offer/offer.types';
import styles from './DashboardPage.module.sass';

const defaultFiltersValue = {
  query: '',
  state: null,
  radius: null,
  zip: '',
  type: OfferStatusType.OFFERS_IN_PROGRESS,
};

interface IOffersTabProps {
  type: OfferCategory;
}

const OffersTab: React.FC<IOffersTabProps> = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { filters, handleFilterChange } = useFilters(defaultFiltersValue);

  const findState = useSelector(offerFindStateSelector);
  const { items: foundOffers, total: totalFound } = useSelector(foundPaginatedOfferSelector) as any;
  const [paginationMeta, setPaginationMeta] = useState({
    limit: DEFAULT_PAGINATION_PAGE_SIZE,
    offset: 0,
  });

  useEffect(() => {
    setPaginationMeta({ limit: DEFAULT_PAGINATION_PAGE_SIZE, offset: 0 });
  }, []);

  useEffect(() => {
    dispatch(
      myOrdersFind({
        ...paginationMeta,
        ...filters,
        expired: filters.type === 'expired' ? true : '',
        offerCategory: type,
      }),
    );
  }, [paginationMeta, filters, type]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPageChange = (page: number) => {
    const limit = DEFAULT_PAGINATION_PAGE_SIZE || paginationMeta.limit;

    setPaginationMeta({
      limit,
      offset: (page - 1) * limit,
    });
  };

  const isLoading = !(findState === RequestState.Success || findState === RequestState.Error);

  const renderFilters = () => {
    const stateOptions = (
      type === OfferCategory.HomeCheck ? homecheckStates : installationStates
    ).map((state) => ({ value: state, label: t(`common:allStates:${state}`) }));

    const typeOptions = Object.values(OfferStatusType).map((status) => ({
      value: status,
      label: t(`installerFlow:dashboard:${status}`),
    }));

    const radiusOptions = [
      { value: null, label: t('installerFlow:preferences:unlimited') },
      { value: '30', label: '30 km' },
      { value: '60', label: '60 km' },
      { value: '120', label: '120 km' },
      { value: '240', label: '240 km' },
      { value: '580', label: '580 km' },
      { value: '1000', label: '1000 km' },
    ];
    return (
      <FiltersPanel query={filters.query} onSearchChange={(query) => handleFilterChange({ query })}>
        <FilterItemContainer label={t('intermediate:myProjects:filters:orderType')}>
          <Filters.Select
            name="type"
            placeholder="Select order type"
            value={filters.type}
            width={200}
            options={typeOptions}
            onChange={(type) => handleFilterChange({ type })}
          />
        </FilterItemContainer>
        <FilterItemContainer label={t('intermediate:myProjects:filters:orderStatus')}>
          <Filters.MultiSelect
            name="state"
            label={t('installerFlow:dashboard:selectState')}
            options={stateOptions}
          />
        </FilterItemContainer>
        <FilterItemContainer label={t('intermediate:myProjects:filters:zip')}>
          <Tooltip title={t('installerFlow:preferences:tooltip:zip')}>
            <Filters.TextField
              className={styles.searchField}
              name="zip"
              value={filters.zip}
              placeholder={t('installerFlow:dashboard:zip')}
            />
          </Tooltip>
        </FilterItemContainer>
        <FilterItemContainer label={t('intermediate:myProjects:filters:radius')}>
          <Tooltip title={t('installerFlow:preferences:tooltip:searchInRadius')}>
            <Filters.Select
              name="radius"
              value={filters.radius}
              placeholder={t('installerFlow:preferences:searchRadius')}
              width={200}
              // @ts-ignore
              options={radiusOptions}
            />
          </Tooltip>
        </FilterItemContainer>
      </FiltersPanel>
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({ filters, setFilters: handleFilterChange }), [filters]);

  return (
    <>
      <FiltersContext.Provider value={contextValue}>{renderFilters()}</FiltersContext.Provider>
      <OfferTableWidget
        isLoading={isLoading}
        dataSource={foundOffers}
        total={totalFound}
        offset={paginationMeta.offset}
        onPageChange={onPageChange}
        showInstallerName
        currentTab={type}
      />
    </>
  );
};

export default OffersTab;
