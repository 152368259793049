import config from 'config';
import { Method } from 'axios';
import { GenericFile } from 'store/document/document.types';
import HttpService from './http.service';
import { MediaCategoryType, UploadFileConfig } from '../store/client-request/client-request.types';

class DocumentsAPI extends HttpService {
  DOCUMENTS_API = 'files';
  CLIENT_REQUEST_API = 'client-requests';
  OFFER_API = 'offers';
  USERS_API = 'users';
  INSTALLER_API = 'installers';

  uploadFile = (
    document: File,
    requestId: string,
    category: MediaCategoryType,
    config: UploadFileConfig,
    onUploadProgress?: any,
    isFotoWizard?: boolean,
    token?: string,
  ) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const data = new FormData();

    data.append('document', document);
    data.append('category', category);
    token && data.append('token', token);

    if (config.subCategory) {
      data.append(config.subCategory, config.subCategoryId || '');
    }
    const url =
      category === MediaCategoryType.InstallerConfirmation
        ? `${this.INSTALLER_API}/license/upload`
        : `${this.CLIENT_REQUEST_API}/${requestId}/documents/${
            isFotoWizard ? 'upload-by-token' : 'upload'
          }`;

    return this.post(url, data, true, headers, onUploadProgress);
  };

  uploadGenericFile = (payload: GenericFile) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const data = new FormData();
    data.append('document', payload.document);
    data.append('category', payload.category);
    if (payload.config?.subCategory) {
      data.append(config.subCategory, config.subCategoryId || '');
    }

    const url = `${this.DOCUMENTS_API}/upload`;

    return this.post(url, data, true, headers, payload.uploadProgress);
  };

  //FIXME Make a generic function
  uploadFileOffer = (
    document: File,
    offerId: string,
    category: MediaCategoryType,
    onUploadProgress?: any,
    organizationId?: string,
  ) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const data = new FormData();

    data.append('document', document);
    data.append('category', category);

    if (organizationId) {
      data.append('organizationId', organizationId);
    }

    return this.post(
      `${this.OFFER_API}/${offerId}/documents/upload`,
      data,
      true,
      headers,
      onUploadProgress,
    );
  };

  // Generic method returns all files of the user
  // for specific category.
  getFiles = (category: MediaCategoryType) => {
    return this.get(`${this.USERS_API}/documents/${category}`);
  };

  // Method to return all files of the user
  // for specific category, for specific client request.
  getClientRequestFiles = (requestId: string, category?: MediaCategoryType) => {
    const params: any = {};
    const url = `${this.CLIENT_REQUEST_API}/${requestId}/documents`;

    if (category) {
      params.category = category;
    }

    return this.get(url, params);
  };

  getClientRequestFilesByToken = (
    requestId: string,
    token: string,
    category?: MediaCategoryType,
  ) => {
    let params = { category: '' };
    if (category) {
      params.category = category;
    }
    return this.get(`${this.CLIENT_REQUEST_API}/${requestId}/documents/list/${token}`, params);
  };

  // Method to return all files of the user
  // for specific category, for specific client request.
  // FIXME: MediaCategoryType is not yet supported by the BE for this case
  getOfferFiles = (offerId: string) => {
    const params: any = {};
    const url = `${this.OFFER_API}/${offerId}/documents`;

    return this.get(url, params);
  };

  /**
   * Get binary data of the specific file
   * @param fileName - hashed name of the document
   */
  downloadFile = (fileName: string) => {
    const encodedName = encodeURIComponent(fileName);
    return this.get(`${this.DOCUMENTS_API}/download/${encodedName}`);
  };

  /**
   * Get URL of the specific file
   * @param fileName - hashed name of the document
   */
  getFileLink = (fileName: string) => {
    const encodedName = encodeURIComponent(fileName);
    return this.get(`${this.DOCUMENTS_API}/link/${encodedName}`);
  };

  getFileLinks = (fileNames: string[]) => {
    return this.get(`${this.DOCUMENTS_API}/links`, {
      fileNames,
    });
  };

  // This method useless for now. Because we don't have
  // correct headers set on google storage (CORS)
  downloadFileByLink = (fileLink: string) => {
    const headers = {
      responseType: 'blob',
    };

    const params = {
      headers,
      url: fileLink,
      method: 'GET' as Method,
      directUrl: true,
    };

    return this.request(params);
  };

  /**
   * Delete specific file from our system
   * @param fileName - hashed name of the document
   */
  deleteFile = (fileName: string) => {
    const encodedName = encodeURIComponent(fileName);

    return this.delete(`${this.DOCUMENTS_API}/${encodedName}`);
  };

  deleteLandingCoverImage = (fileId: string) => {
    return this.delete(`${this.DOCUMENTS_API}/landing-image/${fileId}`);
  };
}

export default new DocumentsAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
