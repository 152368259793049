/* eslint-disable react/display-name */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import OfferTag from 'components/ui/OfferTag/OfferTag';
import Table from 'components/ui/Table/Table';
import TableArrow from 'components/ui/TableArrow/TableArrow';
import Tag from 'components/ui/Tag/Tag';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import { IUserObject } from 'store/auth/auth.types';
import { IOffer, OfferCategory, OfferState } from 'store/offer/offer.types';

interface IOfferTableWidgetProps {
  dataSource: IOffer[];
  showInstallerName?: boolean;
  total?: number;
  onPageChange(page: number, pageSize?: number): any;
  offset?: number;
  isLoading: boolean;
  currentTab: OfferCategory;
}

const OfferTableWidget: React.FC<IOfferTableWidgetProps> = ({
  dataSource,
  showInstallerName = false,
  total,
  offset,
  isLoading,
  onPageChange,
  currentTab,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRowClick = (offer: IOffer) =>
    navigate(`/installer/order/${offer.id}/details/${currentTab}`);

  const toDisplayName = (user: IUserObject | undefined) =>
    user ? `${user.firstName} ${user.lastName}`.trim() : '';

  const OfferTableColumns: ColumnsType<IOffer> = [
    {
      title: 'installerFlow:dashboard:orderNr',
      dataIndex: 'displayId',
      key: 'displayId',
      render: (_: string, row: IOffer) => `${row.clientRequest?.displayId} / ${row.displayId}`,
    },
    {
      title: 'installerFlow:dashboard:name',
      dataIndex: 'name',
      key: 'name',
      render: (_: string, row: IOffer) => toDisplayName(row.clientRequest?.createdBy),
    },
    {
      title: 'installerFlow:dashboard:progress',
      dataIndex: 'state',
      key: 'state',
      render: (status: OfferState) =>
        status ? <OfferTag state={status}>{status}</OfferTag> : <Tag>N/A</Tag>,
    },
    {
      title: 'installerFlow:dashboard:zip',
      dataIndex: 'zip',
      render: (_: string, row: IOffer) => {
        return row.clientRequest?.address ? row.clientRequest?.address.postalCode : 'n/a';
      },
    },
    {
      title: 'installerFlow:dashboard:dueDate',
      dataIndex: 'date',
      key: 'date',
      render: (_: string, row: IOffer) => {
        // if finalInstallationTimestamp present, it mean home-check already done or was no needed.
        const date = row.finalInstallationTimestamp || row.finalHomeCheckTimestamp || '';
        return date ? <DateFormat date={date} /> : '';
      },
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'id',
      render: (_: string, row: IOffer) => <TableArrow offer={row} />,
    },
  ];

  showInstallerName &&
    OfferTableColumns.splice(1, 0, {
      title: 'installerFlow:dashboard:installerName',
      dataIndex: 'name',
      key: 'installerName',
      render: (_: string, row: IOffer) => toDisplayName(row.installerUser),
    });

  // TODO: Later show this column only if installer is part of pool
  // if (installerIsPartOfPool) {
  OfferTableColumns.splice(1, 0, {
    title: 'Pool',
    dataIndex: 'pool',
    render: (text: string, row: any) => {
      const name = row.clientRequest?.pool?.name;
      return name || '';
    },
  });
  // }

  return (
    <Table
      loading={isLoading}
      columns={OfferTableColumns.map((order: any) => ({ ...order, title: t(`${order.title}`) }))}
      rowKey="id"
      dataSource={dataSource}
      emptyText={t('installerFlow:dashboard:noOpenOrders')}
      onRowClick={(item) => handleRowClick(item)}
      pagination={{
        total,
        position: ['bottomRight'],
        pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
        current: offset ? offset / DEFAULT_PAGINATION_PAGE_SIZE + 1 : 1,
        onChange: onPageChange,
      }}
    />
  );
};

export default OfferTableWidget;
