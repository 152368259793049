import { IClientRequest } from 'store/client-request/client-request.types';
import {
  homecheckStates,
  installationStates,
  IOffer,
  OfferApproval,
  OfferState,
  rejectedStates,
  remoteHomeCheckStates,
} from 'store/offer/offer.types';

export const checkIsHomeCheck = (offerState: OfferState) => homecheckStates.includes(offerState);

export const checkIsRemoteHomeCheck = (offerState: OfferState) =>
  remoteHomeCheckStates.includes(offerState);

export const checkIsInstallation = (offerState: OfferState) =>
  installationStates.includes(offerState);
export const checkIsRejected = (offerState: OfferState) => rejectedStates.includes(offerState);

export const checkIsOfferPublished = (offerState: OfferState) =>
  offerState !== OfferState.TenderSubmittedHomeCheck &&
  offerState !== OfferState.TenderSubmittedRemoteHomeCheck &&
  offerState !== OfferState.TenderSubmitted &&
  offerState !== OfferState.InvoiceSubmittedHomeCheck &&
  offerState !== OfferState.InvoiceSubmittedRemoteHomeCheck &&
  offerState !== OfferState.InvoiceSubmittedInstallation &&
  offerState !== OfferState.InvoiceAcceptedInstallation &&
  offerState !== OfferState.InvoiceAcceptedHomeCheck &&
  offerState !== OfferState.InvoicePaymentPendingHomeCheck &&
  offerState !== OfferState.InvoicePaymentPendingRemoteHomeCheck;

export const checkIsInvoicePublished = (offerState: OfferState) =>
  offerState === OfferState.InvoiceSubmittedInstallation ||
  offerState === OfferState.InvoiceCheckingSubmitInstallation;

export const checkIsPayByIntermediate = (offer: IOffer, clientRequest?: IClientRequest | null) => {
  const isPayByIntermediateInstallation =
    clientRequest?.installationOfferApproval === OfferApproval.PayByIntermediate;

  const isPayByIntermediateHomeCheck =
    clientRequest?.homeCheckOfferApproval === OfferApproval.PayByIntermediate;

  return checkIsHomeCheck(offer.state!)
    ? isPayByIntermediateHomeCheck
    : isPayByIntermediateInstallation;
};
