import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosResponse,
  Method,
  AxiosRequestConfig,
} from 'axios';
import _get from 'lodash/get';
import { notification } from 'antd';
import i18n from 'i18n';
import Cookies from 'universal-cookie';
import Config from 'config';
import { APP_NAME, AUTH_TOKEN_KEY, FINGERPRINT_KEY, REFRESH_TOKEN_KEY } from 'framework/constants';
import { HttpErrorCodes } from 'types/http-error-codes';

const cookies = new Cookies();

export interface IRequestParams {
  url: string;
  method?: Method;
  headers?: any;
  data?: any;
  params?: any;
  auth?: boolean;
  uploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  directUrl?: boolean;
}

export interface IHttpConfig {
  apiURL?: string;
  headers?: any;
  withCredentials?: boolean;
}

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

export class HttpServiceState {
  private static instance: HttpServiceState;

  isRefreshing: boolean;
  refreshAndRetryQueue: RetryQueueItem[];

  private constructor() {
    this.isRefreshing = false;
    this.refreshAndRetryQueue = [];
  }

  public static getInstance(): HttpServiceState {
    if (!HttpServiceState.instance) HttpServiceState.instance = new HttpServiceState();

    return HttpServiceState.instance;
  }
}
class HttpService {
  client = axios.create({
    withCredentials: cookies.get('CookieConsent') && cookies.get('CookieConsent') !== 'rejected',
  });

  config: IHttpConfig = {
    apiURL: window.location.host,
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };

  constructor(params: IHttpConfig) {
    this.config = { ...this.config, ...params };
  }

  getHeaders = (headers: any, auth?: boolean): any => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    const languageHeader = { 'Accept-Language': i18n.language };
    const authHeader = token && auth ? { Authorization: `Bearer ${token}` } : {};
    const headersObj = { ...headers, ...authHeader, ...languageHeader };

    return Object.assign({}, this.config.headers, headersObj);
  };

  getURL = (url: string, direct = false) => {
    return !direct ? `${this.config.apiURL}/${url}` : url;
  };

  request = async (params: IRequestParams) => {
    const headers = this.getHeaders(params.headers, params.auth);
    const url = this.getURL(params.url, params.directUrl);

    const requestParams = {
      url,
      headers,
      method: params.method,
      data: params.data,
      params: params.params,
      onUploadProgress: params.uploadProgress,
    };

    this.client.interceptors.response.use(
      (response: AxiosResponse<any>) => response,
      async (error: any) => {
        const { config: errorConfig, response } = error;
        const originalRequest: AxiosRequestConfig & { _retry: boolean } = errorConfig;

        if (
          ((response?.status === 400 && response?.data?.code === 'BAD_TOKEN') ||
            response?.status === 401) &&
          !errorConfig.url?.includes('login') &&
          !originalRequest._retry
        ) {
          if (!HttpServiceState.getInstance().isRefreshing) {
            HttpServiceState.getInstance().isRefreshing = true;
            originalRequest._retry = true;
            try {
              const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);
              const fingerprint = localStorage.getItem(FINGERPRINT_KEY);
              const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

              const refreshTokenResponse = await this.client.post(
                `${Config.CRAFT_SERVICE_URL_V2}/auth/refresh`,
                {
                  refreshToken,
                  context: APP_NAME,
                  fingerprint,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                },
              );
              (originalRequest as any).headers['Authorization'] =
                `Bearer ${refreshTokenResponse.data.accessToken}`;
              this.client.defaults.headers.common.Authorization = `Bearer ${refreshTokenResponse.data.accessToken}`;
              localStorage.setItem(AUTH_TOKEN_KEY, refreshTokenResponse.data.accessToken);
              localStorage.setItem(
                REFRESH_TOKEN_KEY,
                refreshTokenResponse.data.refreshSession.refreshToken,
              );
              HttpServiceState.getInstance().refreshAndRetryQueue.forEach(
                ({ config, resolve, reject }) => {
                  (config as any).headers['Authorization'] =
                    `Bearer ${refreshTokenResponse.data.accessToken}`;
                  this.client
                    .request(config)
                    .then((response) => resolve(response))
                    .catch((err) => reject(err));
                },
              );

              HttpServiceState.getInstance().refreshAndRetryQueue = [];
              return this.client(originalRequest);
            } finally {
              HttpServiceState.getInstance().isRefreshing = false;
            }
          }
          if (HttpServiceState.getInstance().isRefreshing) {
            if (errorConfig.url?.includes('refresh')) {
              // Refresh Token is expired
              localStorage.setItem(AUTH_TOKEN_KEY, '');
              localStorage.setItem(REFRESH_TOKEN_KEY, '');
              HttpServiceState.getInstance().isRefreshing = false;
              window.location.href = '/login';
              return;
            }
            return new Promise<void>((resolve, reject) => {
              HttpServiceState.getInstance().refreshAndRetryQueue.push({
                config: originalRequest,
                resolve,
                reject,
              });
            });
          }
        }
        return Promise.reject(error);
      },
    );

    return this.client
      .request(requestParams)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: AxiosError<any>) => {
        const { response } = error;
        const { data } = response as AxiosResponse<any>;

        // Do not show token expired error
        if (!error?.config?.url?.includes('/auth/refresh')) {
          this.handleError(data);
        }

        throw error;
      });
  };

  handleError(data: any) {
    const code = _get(data, 'code', null);
    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    const title = i18n.t('common:errorCodes:title');

    if (
      code === HttpErrorCodes.TendergyCustomDocumentNotFound ||
      code === HttpErrorCodes.TendergyWrongZipCode
    ) {
      return;
    }

    if (code === HttpErrorCodes.BadToken) {
      window.location.pathname = '/login';
      return;
    }

    notification.error({
      message: title,
      description: code ? i18n.t(`common:errorCodes:${code}`) : message,
    });
  }

  // Since most of requests in the app are user private
  // we make auth = true by default.
  post = (url: string, data?: any, auth: boolean = true, headers?: any, uploadProgress?: any) => {
    return this.request({ url, data, auth, headers, uploadProgress, method: 'post' });
  };

  put = (url: string, data?: any, auth: boolean = true) => {
    return this.request({ url, data, auth, method: 'put' });
  };

  patch = (url: string, data?: any, auth: boolean = true) => {
    return this.request({ url, data, auth, method: 'patch' });
  };

  get = (url: string, params?: any, auth: boolean = true) => {
    return this.request({ url, params, auth, method: 'get' });
  };

  delete = (url: string, data?: any, auth: boolean = true) => {
    return this.request({ url, data, auth, method: 'delete' });
  };
}

export default HttpService;
