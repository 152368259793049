import React, { useState } from 'react';
import _get from 'lodash/get';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Select, Typography, ConfigProvider, Popover } from 'antd';
import i18n from 'i18n';
import { CaretDownOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import logoImage from 'assets/images/logo.png';
import {
  SupportedLanguages,
  USER_ROLE_CUSTOMER,
  USER_ROLE_INTERMEDIATE_MANAGER,
} from 'framework/constants';
import { PATH } from 'framework/path';
import { isAuthorizedSelector } from 'store/auth/auth.selectors';
import { userDataSelector } from 'store/auth/auth.selectors';
import { invitePoolAvatarSelector } from 'store/register/register.selectors';
import {
  clientRequestPoolAvatar,
  allowPoolAvatarSelector,
} from 'store/client-request/client-request.selectors';

import { updateUserProfile } from 'store/user/user.actions';
import { setAppLanguage } from 'utils/language';
import { headerLanguageSelectorOptions } from 'utils/supprotedLanguagesUtils';
import styles from './Header.module.sass';
import { theme } from './customSelectHeaderTheme';
import UserMenu from './UserMenu/UserMenu';

const Header: React.FC = () => {
  const { t } = useTranslation(['header', 'common']);

  const userData = useSelector(userDataSelector);
  const userRoles: string[] = _get(userData, 'roles', []);
  const [isLanguageChangeLoading, setIsLanguageChangeLoading] = useState(false);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const invitePoolAvatar = useSelector(invitePoolAvatarSelector);
  const crPoolAvatar = useSelector(clientRequestPoolAvatar);
  const allowPoolAvatar = useSelector(allowPoolAvatarSelector);
  const dispatch = useDispatch();
  const customAvatar = invitePoolAvatar || allowPoolAvatar ? invitePoolAvatar || crPoolAvatar : '';

  const isUserHasRole = (role: string) => {
    return userRoles.includes(role);
  };

  const userNotAuthorized = userRoles.length === 0;
  const changeLanguage = async (lng: string) => {
    if (!userNotAuthorized) {
      setIsLanguageChangeLoading(true);
      dispatch(
        updateUserProfile({
          defaultLanguage: { isoCode: lng },
          onSuccess: async () => {
            await setAppLanguage(lng as SupportedLanguages);
            setIsLanguageChangeLoading(false);
          },
          onError: () => setIsLanguageChangeLoading(false),
        }),
      );
      return;
    }
    await setAppLanguage(lng as SupportedLanguages);
  };
  const avatarToShow = isUserHasRole(USER_ROLE_CUSTOMER) || userNotAuthorized ? customAvatar : '';
  const isIntermediateManager = isUserHasRole(USER_ROLE_INTERMEDIATE_MANAGER);
  const isCustomer = isUserHasRole(USER_ROLE_CUSTOMER);

  const renderSubMenu = () => (
    <div className={styles.subNavItems}>
      {[
        {
          label: t('header:dashboard'),
          route: '/my-projects/management',
        },
        {
          label: t('header:myProjects'),
          route: '/my-projects',
        },
      ].map((link, index) => (
        <NavLink
          end
          to={link.route}
          className={({ isActive }) =>
            clsx(styles.navLink, styles.subNavLink, { [styles.activeSubNavLink]: isActive })
          }
          key={`nav-link-${index}`}
        >
          {link.label}
        </NavLink>
      ))}
    </div>
  );

  return (
    <header className={clsx(styles.header)}>
      <div className={styles.title}>
        <Link to="/home" replace>
          <img
            alt="company logo"
            className={styles.logo}
            src={avatarToShow ? avatarToShow : logoImage}
          />
        </Link>
        <Typography.Text className={styles.slogan}>{t('header:title')}</Typography.Text>
      </div>
      {isAuthorized && !isCustomer && (
        <div>
          {isIntermediateManager && (
            <Popover
              placement="bottom"
              content={renderSubMenu()}
              openClassName={styles.openedSubMenu}
              overlayClassName={styles.submenu}
              autoAdjustOverflow
            >
              <NavLink
                to="/my-projects"
                className={({ isActive }) =>
                  clsx(styles.navLink, { [styles.activeNavLink]: isActive })
                }
              >
                <span>
                  {t('header:poolOwner')} <CaretDownOutlined className={styles.openIcon} />
                </span>
                <div className={styles.navLine} />
              </NavLink>
            </Popover>
          )}
          <NavLink
            to="/installer"
            className={({ isActive }) => clsx(styles.navLink, { [styles.activeNavLink]: isActive })}
          >
            {t('header:installer')}
            <div className={styles.navLine} />
          </NavLink>
        </div>
      )}
      <div className={styles.userSection}>
        <ConfigProvider theme={theme}>
          <Select
            value={i18n.language}
            onChange={changeLanguage}
            suffixIcon={<CaretDownOutlined className={styles.icon} />}
            className={styles.languageSelector}
            options={headerLanguageSelectorOptions}
            loading={isLanguageChangeLoading}
          />
        </ConfigProvider>
        {isAuthorized ? (
          <UserMenu />
        ) : (
          <Link to={PATH.LOGIN} className={styles.link}>
            {t('common:buttons:login')}
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
