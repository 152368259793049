import config from 'config';
import { IClientRequestAnswer } from 'store/client-request/client-request.types';
import HttpService from './http.service';

class ClientRequestAPI extends HttpService {
  CLIENT_REQUEST_API = 'client-requests';

  getByClientRequestId = (clientRequestId: string) => {
    return this.get(`${this.CLIENT_REQUEST_API}/${clientRequestId}/answers`);
  };

  save = (params: { clientRequestId: string; answer: IClientRequestAnswer }) => {
    return this.put(
      `${this.CLIENT_REQUEST_API}/${params.clientRequestId}/answers/${params.answer.questionKey}`,
      params.answer,
    );
  };

  saveBulk = (params: { clientRequestId: string; answers: IClientRequestAnswer[] }) => {
    return this.put(
      `${this.CLIENT_REQUEST_API}/${params.clientRequestId}/answers/bulk`,
      params.answers,
    );
  };
}

export default new ClientRequestAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
