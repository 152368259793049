import { IBundle } from 'store/bundle/bundle.types';
import { ICountry, IPool, IQuestionnaire } from 'store/intermediate/intermediate.types';
import { IOffer } from 'store/offer/offer.types';
import { IOrder } from 'store/orders/orders.types';
import { IProduct } from 'store/product/product.types';
import { RequestState } from 'store/common.types';
import { IUserObject } from '../auth/auth.types';

// Questions calculated on the BE
export enum QuestionCalculatedKey {
  Distance = 'distance',
  TimeSpan = 'time-span',
  PriceRange = 'price-range',
}

// keep this in sync with backend for validation;
export enum MediaCategoryType {
  RequestLandlordPermission = 'request:landlord_permission',
  RequestParking = 'request:parking',
  RequestFloorPlan = 'request:floor_plan',
  RequestSketch = 'request:sketch',
  RequestPhotos = 'request:photos',
  RequestFuseBox = 'request:fuseBox',
  RequestPermitAgreement = 'request:permit_agreement',
  RequestHomeCheckDocumentation = 'request:home_check_documentation',
  RequestIntermediateOther = 'request:intermediate_other',
  RequestVideoCall = 'request:home_check_screenshot',
  RequestWallboxOnly = 'request:wallbox_only',
  RequestWallboxAndInstallation = 'request:wallbox_and_installation',
  InstallationRegForm = 'installation:registration_form',
  InstallationOrderConfirmation = 'offer:bid',
  OfferBidInstaller = 'offer:bid_installer',
  OfferHomeCheck = 'offer:home_check',
  OfferHomeCheckInstaller = 'offer:home_check_installer',
  OfferInvoiceHomeCheckInstaller = 'offer:invoice_home_check_installer',
  OfferInvoiceHomeCheck = 'offer:invoice_home_check',
  OfferInvoiceInstallationInstaller = 'offer:invoice_installation_installer',
  OfferInvoiceInstallation = 'offer:invoice_installation',
  OfferNetworkRegistration = 'offer:network_registration',
  OfferIntermediateOther = 'offer:intermediate_other',
  InstallationFinalDocumentation = 'offer:final_documentation',
  OrganizationEvuConfirmation = 'organization:evu_confirmation',
  InstallerConfirmation = 'installer:confirmation',
  PoolAvatar = 'pool:avatar',
  CommentAttachment = 'comment:attachment',
  CustomDocumentImage = 'custom_document:image',
  ProductWizard = 'product_wizard:image',
}

export enum FileType {
  PDF = 'application/pdf',
  JPG = 'image/jpeg',
}

export interface IDocument extends IFile {
  id: string;
  createdAt: string;
  updatedAt: string;
  size: number;

  // TODO: add typings here
  uploadedBy: any;
  clientRequestDocument: any;
}

export interface IGroupedFiles {
  [key: string]: IFile[];
}

export interface IFile {
  id: string;
  category: MediaCategoryType;
  mime: FileType;
  name: string;
  originalName: string;
}

export enum WallboxDeliveryStatus {
  DeliveredManually = 'deliveredManually',
  ToBeDefined = 'toBeDefined',
  Defined = 'defined',
  PreTransit = 'preTransit',
  Transit = 'transit',
  Delivered = 'delivered',
  Failure = 'failure',
  Unknown = 'unknown',
}

export enum QuestionsType {
  RadioQuestion = 'radio',
  RadioWithCustomInputQuestion = 'radioWithCustomInput',
  SingleInputQuestion = 'singleInput',
  MultipleInputQuestion = 'multipleInput',
  ChargingStatioQuestion = 'chargingStation',
  surfaceQuestion = 'surface',
}
export enum QuestionKey {
  // STEP 1 Requirements
  // 1.1 Existing wallbox
  WallboxAlreadyInstalled = 'wallboxAlreadyInstalled',

  // 1.2 Property ownership
  HouseType = 'houseType',
  HouseOwnership = 'houseOwnership',
  ApartmentOwner = 'apartmentOwner',

  // 1.3 Parking lot
  OwnParkingSpace = 'ownParkingSpace',
  ParkingSpaceEnergy = 'parkingSpaceEnergy',
  ParkingSpaceLocation = 'parkingSpaceLocation',

  // STEP 2 Const calculations
  // 2.1 Power connection
  EnergyType = 'energyType',
  ConnectionSecurity = 'connectionSecurity',
  InstallationMeterLocation = 'installationMeterLocation',

  // 2.2 Installation work
  InstallationAmountWalls = 'installationAmountWalls',
  InstallationStraightWall = 'installationStraightWall',
  InstallationDigging = 'installationDigging',
  InstallationDistance = 'installationDistance',
  InstallationDiggingRestauration = 'installationDiggingRestauration',
  InstallationExcavationSurface = 'installationExcavationSurface',
  InstallationAffectedSurface = 'installationAffectedSurface',

  // 2.3 Wallbox model
  CarModel = 'carModel',
  ChargingStation = 'chargingStation',

  // STEP 3 Request
  // 3.1 Personal details

  // 3.2 Documents upload

  // 3.3 Availability

  // 3.4 Way of contact

  // Rest
  WallboxCompany = 'wallboxCompany',
  WallboxModel = 'wallboxModel',
  ParkingSpaceWall = 'parkingSpaceWall',
  ProcessingFee = 'processingFee',
}

export enum ClientRequestFields {
  UserType = 'userType',
  BusinessEmail = 'businessEmail',
  Email = 'email',
  Address = 'address',
  BillingAddress = 'billingAddress',
  Phone = 'phone',
  PeriodOfTime = 'periodOfTime',
  InstallWeekdays = 'weekdays',
  ContactType = 'contactType',
  Annotation = 'annotation',
  Availability = 'availability',
  SoonestPossible = 'soonestPossible',
  StartDate = 'startDate',
}

export enum EnergyType {
  THREE = 'threePhase',
  SINGLE = 'singlePhase',
  UNKNOWN = 'doNotKnow',
}

/**
 * Client Request Search Result may have some different fields than the full client request.
 * It's going to have less fields + server side distance.
 */
export interface IClientRequestSearchResult extends IClientRequest {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedDate?: any;
  annotation: string;
  rejectReason?: any;
  userType: string;
  displayId: string;
  zip: string;

  contactType?: any;
  distance: number;

  soonestPossible?: boolean;

  // fields available in response but should not be part of search result, because it's confidential
  // Backend todo

  // step?: any;
  // subStep?: any;
  // lastStateChangedAt?: Date;
  // lastReminderSentAt?: Date;
  // lastReminderTypeSent: string;
  // phone: string;
  // businessEmail?: any;
  // weekdays: string[];
}

export interface IClientRequestSearchResults {
  items: IClientRequestSearchResult[];
  total: number;
}

export interface IClientRequests {
  radius?: string | null;
  zip?: string;
}

export interface IClientRequest {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  annotation?: string;
  archived?: boolean;
  archivable?: boolean;
  rejectReason?: string;
  savePosition?: string;
  lastStateChangedAt?: Date;
  lastReminderSentAt?: Date;
  lastReminderTypeSent?: string;
  address?: IAddress;
  postalCode?: string;
  billingAddress?: IAddress;
  state?: ClientRequestState;
  offers?: IOffer[];
  displayId?: string;
  soonestPossible?: boolean;
  contactType?: string;
  bundleId?: string;
  orderDirectlyToInstaller?: boolean;
  businessEmail?: string;

  startDate?: Date;
  endDate?: Date;

  step?: number;
  subStep?: number;

  products?: IProduct[];

  answers?: IClientRequestAnswer[];
  published?: string;
  type?: ClientRequestTransitionTypes;

  createdBy?: IUserObject;
  lastName?: string;
  costEstimateMin?: number;
  costEstimateMax?: number;
  pool?: IPool | null;
  poolId?: string;
  questionnaire?: IQuestionnaire;
  poolCostEstimateMin?: string;
  poolCostEstimateMax?: string;

  questionnaireSkipped?: boolean;

  weekdays?: string[] | null;

  ownerId?: string;
  homeCheckOfferApproval?: string;
  installationOfferApproval?: string;
  installationInvoiceApproval?: string;
  wallboxDeliveryStatus?: WallboxDeliveryStatus;
  wallboxDeliveryDate?: Date;
  bundle?: IBundle;
  offerMinimumValidity?: string;
  orders?: IOrder[];

  trackingValue?: string;
}

export interface IClientRequestCost {
  max: number;
  min: number;
}

export interface ITransitionStateUpdate {
  type: ClientRequestTransitionTypes;
  clientRequest?: {
    id?: string;
    rejectReason?: string;
    questionnaireSkipped?: boolean;
    bundle?: IBundle;
  };
}

export enum ClientRequestState {
  Initiated = 'initiated',
  Cancelled = 'cancelled',
  Submitted = 'submitted',
  PendingDocument = 'pendingDocument',
  ApprovalPending = 'approvalPending',
  HomeCheck = 'homeCheck', //Either the home check is pending or it is done but the customer has not accepted the installation offer yet
  Approved = 'approved',
  OfferSelectionHomeCheck = 'offerSelectionHomeCheck',
  OfferSelectionInstallation = 'offerSelectionInstallation',
  OrderSubmitted = 'orderSubmitted',
  InstallationPending = 'installationPending',
  PaymentPending = 'paymentPending',
  Done = 'done',
}

export enum ClientRequestTransitionTypes {
  created = 'CUSTOMER_REQUEST_CREATED',
  accepted = 'CUSTOMER_ACCEPTED',
  rejected = 'CUSTOMER_REJECTED',
  documentsUploaded = 'CUSTOMER_UPLOADED_DOCS',
  customerRequestUpdated = 'CUSTOMER_REQUEST_UPDATED',
  coordinatorAccepted = 'COORDINATOR_ACCEPTED',
  coordinatorRejected = 'COORDINATOR_REJECTED',
  skippedQuestionnaire = 'SKIPPED_QUESTIONNAIRE',
}

export interface IAddress {
  id: string;
  address2?: string;
  postalCode: string;
  createdAt: Date;
  updatedAt: Date;
  street: string;
  houseNumber: string;
  city?: string;
  country: ICountry;
}

export type TypeRadius = '30' | '60' | '120' | '240' | '580' | '1000';

type WallboxModeType = {
  value: string;
  label: string;
};

export type WallboxesType = {
  [key: string]: {
    value: string;
    label: string;
    models: WallboxModeType[];
  };
};

export type TypeDistance = {
  destination: string;
  distance: number;
};

export type QuestionType = {
  attachments: any[];
  id: string;
  text: string;
  createdAt: string;
  answerDate?: string;
  parentCommentId: string;
  type?: CommentType;
  author: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  authorId?: string;
};

export enum CommentType {
  Public = 'public',
  Private = 'private',
  PrivateInstaller = 'private_installer_intermediate',
}

export type AddCommentPayloadType = {
  id: string;
  params: {
    text: string;
    parentComment?: string;
    commentSide?: string;
    type?: CommentType;
    attachments?: string[];
  };
  onSuccess(id: string): void;
  onError(): void;
};

export type UpdateCommentPayloadType = {
  clientRequestId: string;
  commentId: string;
  params: {
    text?: string;
    type?: CommentType;
    attachments?: string[];
  };
  onSuccess(): void;
};

export type ClientRequestsType = {
  items: IClientRequestSearchResult[] | [];
  total: number;
};

export type OfferDocument = {
  id: string;
  document: IDocument;
};
export interface IClientRequestLastStep {
  step: number;
  subStep: number;
}
export interface IClientRequestState {
  clientRequestState: RequestState | null;
  updateError: string | null;
  createError: string | null;
  clientRequest: IClientRequest | null;

  clientRequestsState: RequestState | null;
  clientRequests: ClientRequestsType;

  myClientRequestsState: RequestState | null;
  myClientRequests: IClientRequest[];
  myClientRequestsError: string | null;
  lastVisitedStep: IClientRequestLastStep;
  cost: IClientRequestCost;
  distance: TypeDistance[] | null;

  clientRequestAnswers: IClientRequestAnswer[];
  answersState: RequestState;
  answersError: string | null;

  error: string | null;

  transitionState: RequestState | null;
  transitionError: string | null;

  files: any;

  houseType: string | null;

  wallboxes: any;

  questions: QuestionType[] | [];

  poolAvatar: any;

  customDocumentImage: any;

  poolAvatarState: RequestState | null;

  invoices: OfferDocument[] | null;
  invoicesState: RequestState;
  invoicesError: string | null;
  fotowizardUploadToken: any;

  assignBundleToRequestState: RequestState | null;
  assignBundleToRequestError: string | null;
}

export interface IClientRequestAnswer {
  questionKey: QuestionKey;
  answerValue: {
    [key: string]: any;
  };
}

export const CLIENT_REQUEST_GET = 'CLIENT_REQUEST_GET';
export const CLIENT_REQUEST_GET_SUCCESS = 'CLIENT_REQUEST_GET_SUCCESS';
export const CLIENT_REQUEST_GET_ERROR = 'CLIENT_REQUEST_GET_ERROR';

export const CLIENT_REQUESTS_ALL_GET = 'CLIENT_REQUESTS_ALL_GET';
export const CLIENT_REQUESTS_ALL_GET_SUCCESS = 'CLIENT_REQUESTS_ALL_GET_SUCCESS';
export const CLIENT_REQUESTS_ALL_GET_ERROR = 'CLIENT_REQUESTS_ALL_GET_ERROR';
export const GET_CUSTOMER_DOCUMENT_UPLOAD_TOKEN = 'GET_CUSTOMER_DOCUMENT_UPLOAD_TOKEN';
export const FOTO_WIZARD_UPLOAD_TOKEN_GET_ERROR = 'FOTO_WIZARD_UPLOAD_TOKEN_GET_ERROR';
export const MY_CLIENT_REQUESTS_GET = 'MY_CLIENT_REQUESTS_GET';
export const MY_CLIENT_REQUESTS_GET_SUCCESS = 'MY_CLIENT_REQUESTS_GET_SUCCESS';
export const MY_CLIENT_REQUESTS_GET_ERROR = 'MY_CLIENT_REQUESTS_GET_ERROR';

export const CLIENT_REQUEST_UPDATE = 'CLIENT_REQUEST_UPDATE';
export const CLIENT_REQUEST_UPDATE_SUCCESS = 'CLIENT_REQUEST_UPDATE_SUCCESS';
export const CLIENT_REQUEST_UPDATE_ERROR = 'CLIENT_REQUEST_UPDATE_ERROR';

export const CLIENT_REQUEST_ARCHIVE = 'CLIENT_REQUEST_ARCHIVE';
export const CLIENT_REQUEST_ARCHIVE_SUCCESS = 'CLIENT_REQUEST_ARCHIVE_SUCCESS';
export const CLIENT_REQUEST_ARCHIVE_ERROR = 'CLIENT_REQUEST_ARCHIVE_ERROR';

export const CLIENT_REQUEST_CREATE = 'CLIENT_REQUEST_CREATE';
export const CLIENT_REQUEST_CREATE_SUCCESS = 'CLIENT_REQUEST_CREATE_SUCCESS';
export const CLIENT_REQUEST_CREATE_ERROR = 'CLIENT_REQUEST_CREATE_ERROR';

export const CLIENT_REQUESTS_COST_GET = 'CLIENT_REQUESTS_COST_GET';
export const CLIENT_REQUESTS_COST_GET_SUCCESS = 'CLIENT_REQUESTS_COST_GET_SUCCESS';
export const CLIENT_REQUESTS_COST_GET_ERROR = 'CLIENT_REQUESTS_COST_GET_ERROR';

export const CLIENT_REQUEST_DISTANCE_GET = 'CLIENT_REQUEST_DISTANCE_GET';
export const CLIENT_REQUEST_DISTANCE_GET_SUCCESS = 'CLIENT_REQUEST_DISTANCE_GET_SUCCESS';
export const CLIENT_REQUEST_DISTANCE_GET_ERROR = 'CLIENT_REQUEST_DISTANCE_GET_ERROR';

export const CLIENT_REQUESTS_FIND = 'CLIENT_REQUESTS_FIND';
export const CLIENT_REQUESTS_FIND_SUCCESS = 'CLIENT_REQUESTS_FIND_SUCCESS';
export const CLIENT_REQUESTS_FIND_ERROR = 'CLIENT_REQUESTS_FIND_ERROR';

export const UPDATE_TRANSITION_STATE = 'UPDATE_TRANSITION_STATE';
export const UPDATE_TRANSITION_STATE_SUCCESS = 'UPDATE_TRANSITION_STATE_SUCCESS';
export const UPDATE_TRANSITION_STATE_ERROR = 'UPDATE_TRANSITION_STATE_ERROR';

export const ANSWERS_GET = 'ANSWERS_GET';
export const ANSWERS_SET = 'ANSWERS_SET';
export const ANSWERS_GET_SUCCESS = 'ANSWERS_GET_SUCCESS';
export const ANSWERS_GET_ERROR = 'ANSWERS_GET_ERROR';

export const ANSWERS_SAVE = 'ANSWERS_SAVE';
export const ANSWERS_SAVE_SUCCESS = 'ANSWERS_SAVE_SUCCESS';
export const ANSWERS_SAVE_ERROR = 'ANSWERS_SAVE_ERROR';

export const SINGLE_ANSWER_SET = 'SINGLE_ANSWER_SET';
export const SINGLE_ANSWER_SAVE = 'SINGLE_ANSWER_SAVE';
export const SINGLE_ANSWER_SAVE_SUCCESS = 'SINGLE_ANSWER_SAVE_SUCCESS';
export const SINGLE_ANSWER_SAVE_ERROR = 'SINGLE_ANSWER_SAVE_ERROR';

export const UPLOAD_CLIENT_REQUEST_FILE = 'UPLOAD_CLIENT_REQUEST_FILE';
export const UPLOAD_CLIENT_REQUEST_FILE_PROGRESS = 'UPLOAD_CLIENT_REQUEST_FILE_PROGRESS';
export const UPLOAD_CLIENT_REQUEST_FILE_SUCCESS = 'UPLOAD_CLIENT_REQUEST_FILE_SUCCESS';
export const UPLOAD_CLIENT_REQUEST_FILE_ERROR = 'UPLOAD_CLIENT_REQUEST_FILE_ERROR';

export const GET_CLIENT_REQUEST_FILES = 'GET_CLIENT_REQUEST_FILES';
export const GET_CLIENT_REQUEST_FILES_SUCCESS = 'GET_CLIENT_REQUEST_FILES_SUCCESS';
export const GET_CLIENT_REQUEST_FILES_ERROR = 'GET_CLIENT_REQUEST_FILES_ERROR';

export const GET_CLIENT_REQUEST_HOUSE_TYPE = 'GET_CLIENT_REQUEST_HOUSE_TYPE';
export const GET_CLIENT_REQUEST_HOUSE_TYPE_SUCCESS = 'GET_CLIENT_REQUEST_HOUSE_TYPE_SUCCESS';
export const GET_CLIENT_REQUEST_HOUSE_TYPE_ERROR = 'GET_CLIENT_REQUEST_HOUSE_TYPE_ERROR';

export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

export const DELETE_LANDING_COVER_IMAGE = 'DELETE_LANDING_COVER_IMAGE';
export const DELETE_LANDING_COVER_IMAGE_SUCCESS = 'DELETE_LANDING_PAGE_SUCCESS';
export const DELETE_LANDING_COVER_IMAGE_ERROR = 'DELETE_LANDING_PAGE_ERROR';

export const GET_FILE_LINK = 'GET_FILE_LINK';
export const GET_FILE_LINK_SUCCESS = 'GET_FILE_LINK_SUCCESS';
export const GET_FILE_LINK_ERROR = 'GET_FILE_LINK_ERROR';

export const DOWNLOAD_FILE_BY_LINK = 'DOWNLOAD_FILE_BY_LINK';

export const GET_WALLBOXES = 'GET_WALLBOXES';
export const GET_WALLBOXES_SUCCESS = 'GET_WALLBOXES_SUCCESS';
export const GET_WALLBOXES_ERROR = 'GET_WALLBOXES_ERROR';

export const CREATE_VENDOR = 'CREATE_VENDOR';
export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS';
export const CREATE_VENDOR_ERROR = 'CREATE_VENDOR_ERROR';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
// export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';

export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_ERROR = 'GET_COMMENTS_ERROR';

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR';

export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_ERROR = 'UPDATE_COMMENT_ERROR';

export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR';

export const DOWNLOAD_ALL_CR_DOCUMENTS = 'DOWNLOAD_ALL_CR_DOCUMENTS';
export const DOWNLOAD_ALL_CR_DOCUMENTS_ERROR = 'DOWNLOAD_ALL_CR_DOCUMENTS_ERROR';
export const FOTO_WIZARD_UPLOAD_TOKEN_GET_SUCCESS = 'FOTO_WIZARD_UPLOAD_TOKEN_GET_SUCCESS';

export const ASSIGN_BUNDLE_TO_REQUEST = 'ASSIGN_BUNDLE_TO_REQUEST';
export const ASSIGN_BUNDLE_TO_REQUEST_SUCCESS = 'ASSIGN_BUNDLE_TO_REQUEST_SUCCESS';
export const ASSIGN_BUNDLE_TO_REQUEST_ERROR = 'ASSIGN_BUNDLE_TO_REQUEST_ERROR';
//Get Invoices
export interface InvoicesGetAction {
  type: typeof GET_INVOICES;
}

export interface InvoicesGetSuccessAction {
  type: typeof GET_INVOICES_SUCCESS;
  payload: OfferDocument[];
}

export interface InvoicesGetErrorAction {
  type: typeof GET_INVOICES_ERROR;
  payload: any;
}

//Get Client Request
export interface ClientRequestGetAction {
  type: typeof CLIENT_REQUEST_GET;
  payload: {
    clientRequestId: string;
    isIntermediate: boolean;
    shouldFetchOffers: boolean;
  };
}

export interface ClientRequestGetSuccessAction {
  type: typeof CLIENT_REQUEST_GET_SUCCESS;
  payload: IClientRequest;
}

export interface ClientRequestGetErrorAction {
  type: typeof CLIENT_REQUEST_GET_ERROR;
  payload: any;
}
//get fotowizard upload token
export interface FotowizardUploadDocumentTokenAction {
  type: typeof GET_CUSTOMER_DOCUMENT_UPLOAD_TOKEN;
  payload: {
    requestId: string;
  };
}

// Get Client Request distance
export interface ClientRequestDistanceGetAction {
  type: typeof CLIENT_REQUEST_DISTANCE_GET;
  payload: {
    clientRequestId: string;
  };
}

export interface ClientRequestDistanceGetSuccessAction {
  type: typeof CLIENT_REQUEST_DISTANCE_GET_SUCCESS;
  payload: any;
}

export interface ClientRequestDistanceGetErrorAction {
  type: typeof CLIENT_REQUEST_DISTANCE_GET_ERROR;
  payload: any;
}

// Find client requests
export interface ClientRequestsFindAction {
  type: typeof CLIENT_REQUESTS_FIND;
  payload: {
    limit: number;
    offset: number;
    radius?: string;
    zip?: string;
    state?: string[];
    query?: string;
  };
}

export interface ClientRequestsFindSuccessAction {
  type: typeof CLIENT_REQUESTS_FIND_SUCCESS;
  payload: IClientRequestSearchResults;
}

export interface ClientRequestsFindErrorAction {
  type: typeof CLIENT_REQUESTS_FIND_ERROR;
  payload: any;
}

// Get Client Requests
// In the future this endpoint will return all user requests
export interface ClientRequestsAllGetAction {
  type: typeof CLIENT_REQUESTS_ALL_GET;
}

export interface ClientRequestsAllGetSuccessAction {
  type: typeof CLIENT_REQUESTS_ALL_GET_SUCCESS;
  payload: IClientRequest;
}

export interface ClientRequestsAllGetErrorAction {
  type: typeof CLIENT_REQUESTS_ALL_GET_ERROR;
  payload: any;
}

// Get My Client Requests
export interface MyClientRequestsGetAction {
  type: typeof MY_CLIENT_REQUESTS_GET;
}

export interface MyClientRequestsGetSuccessAction {
  type: typeof MY_CLIENT_REQUESTS_GET_SUCCESS;
  payload: IClientRequest[];
}

export interface MyClientRequestsGetErrorAction {
  type: typeof MY_CLIENT_REQUESTS_GET_ERROR;
  payload: any;
}

//Create Client Request
export interface ClientRequestCreateAction {
  type: typeof CLIENT_REQUEST_CREATE;
  payload: {
    clientRequest: IClientRequest;
    answers?: IClientRequestAnswer[];
  };
}

export interface ClientRequestArchiveAction {
  type: typeof CLIENT_REQUEST_ARCHIVE;
  payload: { clientRequestId: string; archived: boolean };
}

export interface ClientRequestArchiveSuccessAction {
  type: typeof CLIENT_REQUEST_ARCHIVE_SUCCESS;
  payload: any;
}

export interface ClientRequestArchiveErrorAction {
  type: typeof CLIENT_REQUEST_ARCHIVE_ERROR;
  payload: any;
}

export interface ClientRequestSuccessAction {
  type: typeof CLIENT_REQUEST_CREATE_SUCCESS;
  payload: IClientRequest;
}

export interface ClientRequestErrorAction {
  type: typeof CLIENT_REQUEST_CREATE_ERROR;
  payload: any;
}

// Update Client Request
export interface ClientRequestUpdateAction {
  type: typeof CLIENT_REQUEST_UPDATE;
  payload: {
    clientRequestId: string;
    fields: any;
    onSuccess: Function;
    onError: Function;
  };
}
export interface ClientRequestUpdateSuccessAction {
  type: typeof CLIENT_REQUEST_UPDATE_SUCCESS;
  payload: IClientRequest;
}
export interface ClientRequestUpdateErrorAction {
  type: typeof CLIENT_REQUEST_UPDATE_ERROR;
  payload: any;
}

export interface ClientRequestCostGetAction {
  type: typeof CLIENT_REQUESTS_COST_GET;
  payload: { clientRequestId: string };
}

export interface ClientRequestCostGetSuccessAction {
  type: typeof CLIENT_REQUESTS_COST_GET_SUCCESS;
  payload: IClientRequestCost;
}
export interface ClientRequestCostGetErrorAction {
  type: typeof CLIENT_REQUESTS_COST_GET_ERROR;
  payload: any;
}

//Get Bulk Answers
export interface AnswersGetAction {
  type: typeof ANSWERS_GET;
  payload: string;
}

export interface AnswersGetSuccessAction {
  type: typeof ANSWERS_GET_SUCCESS;
  payload: IClientRequestAnswer[];
}

export interface AnswersGetErrorAction {
  type: typeof ANSWERS_GET_ERROR;
  payload: any;
}

//Set Bulk Answers (without saving)
export interface AnswersSetAction {
  type: typeof ANSWERS_SET;
  payload: IClientRequestAnswer[];
}

//Save Bulk Answers
export interface AnswersSaveAction {
  type: typeof ANSWERS_SAVE;
  payload: {
    clientRequestId: string;
    answers: IClientRequestAnswer[];
    onSuccess?: Function;
    onError?: Function;
  };
}

export interface AnswersSaveSuccessAction {
  type: typeof ANSWERS_SAVE_SUCCESS;
  payload: IClientRequestAnswer[];
}

export interface AnswersSaveErrorAction {
  type: typeof ANSWERS_SAVE_ERROR;
  payload: any;
}

//Set Single Answer (without saving)
export interface SingleAnswerSetAction {
  type: typeof SINGLE_ANSWER_SET;
  payload: IClientRequestAnswer;
}

// Update Transition State action
export interface UpdateTransitionStateAction {
  type: typeof UPDATE_TRANSITION_STATE;
  payload: {
    id: string;
    rejectReason?: string;
    type: ClientRequestTransitionTypes;
    onSuccess?: Function;
    data?: Partial<IClientRequest>;
  };
}

export interface UpdateTransitionStateSuccessAction {
  type: typeof UPDATE_TRANSITION_STATE_SUCCESS;
  payload: any;
}
export interface UpdateTransitionStateSuccessErrorAction {
  type: typeof UPDATE_TRANSITION_STATE_ERROR;
  payload: any;
}

//Save Single Answer
export interface SingleAnswerSaveAction {
  type: typeof SINGLE_ANSWER_SAVE;
  payload: {
    clientRequestId: string;
    answer: IClientRequestAnswer;
  };
}

export interface SingleAnswerSaveSuccessAction {
  type: typeof SINGLE_ANSWER_SAVE_SUCCESS;
  payload: IClientRequestAnswer;
}

export interface SingleAnswerSaveErrorAction {
  type: typeof SINGLE_ANSWER_SAVE_ERROR;
  payload: any;
}

// Upload client request files
export type UploadFileConfig = {
  subCategory?: string;
  subCategoryId?: string;
};

export interface UploadRequestFileAction {
  type: typeof UPLOAD_CLIENT_REQUEST_FILE;
  payload: {
    document: File;
    requestId: string;
    category: MediaCategoryType;
    config: UploadFileConfig;
    uploadProgress: any;
    uploadSuccess?: any;
    uploadError?: any;
    isFotoWizard?: boolean;
    token: string;
  };
}

export interface UploadRequestFileProgressAction {
  type: typeof UPLOAD_CLIENT_REQUEST_FILE_PROGRESS;
}

export interface UploadRequestFileSuccessAction {
  type: typeof UPLOAD_CLIENT_REQUEST_FILE_SUCCESS;
  payload: IDocument;
}

export interface UploadRequestFileErrorAction {
  type: typeof UPLOAD_CLIENT_REQUEST_FILE_ERROR;
  payload: any;
}

// Get client request files actions
export interface GetClientRequestFilesAction {
  type: typeof GET_CLIENT_REQUEST_FILES;
  payload: {
    requestId: string;
    category: MediaCategoryType;
    token: string;
  };
}

export interface GetClientRequestFilesSuccessAction {
  type: typeof GET_CLIENT_REQUEST_FILES_SUCCESS;
  payload: any;
}

export interface GetClientRequestFilesErrorAction {
  type: typeof GET_CLIENT_REQUEST_FILES_ERROR;
  payload: any;
}

export interface GetClientRequestHouseTypeAction {
  type: typeof GET_CLIENT_REQUEST_HOUSE_TYPE;
  payload: {
    id: string;
    token: string;
  };
}
export interface GetClientRequestHouseTypeSuccessAction {
  type: typeof GET_CLIENT_REQUEST_HOUSE_TYPE_SUCCESS;
  payload: string;
}
export interface GetClientRequestHouseTypeErrorAction {
  type: typeof GET_CLIENT_REQUEST_HOUSE_TYPE_ERROR;
  payload: any;
}

export interface DeleteFileAction {
  type: typeof DELETE_FILE;
  payload: {
    fileName: string;
    fileId: string;
    category: MediaCategoryType;
    onSuccess: any;
  };
}

export interface DeleteFileSuccessAction {
  type: typeof DELETE_FILE_SUCCESS;
  payload: {
    fileId: string;
    category: MediaCategoryType;
  };
}

export interface DeleteFileErrorAction {
  type: typeof DELETE_FILE_ERROR;
  payload: any;
}

export interface DeleteLandingCoverImageAction {
  type: typeof DELETE_LANDING_COVER_IMAGE;
  payload: {
    fileName: string;
    fileId: string;
    category: MediaCategoryType;
  };
}
export interface DeleteLandingCoverImageSuccessAction {
  type: typeof DELETE_LANDING_COVER_IMAGE_SUCCESS;
  payload: {
    fileId: string;
    category: MediaCategoryType;
  };
}
export interface DeleteLandingCoverImageErrorAction {
  type: typeof DELETE_LANDING_COVER_IMAGE_ERROR;
  payload: any;
}

export interface GetFileLinkAction {
  type: typeof GET_FILE_LINK;
  payload: { fileName: string };
}

export interface GetFileLinkSuccessAction {
  type: typeof GET_FILE_LINK_SUCCESS;
  payload: {
    fileLink: string;
    fileName?: string;
  };
}

export interface GetFileLinkErrorAction {
  type: typeof GET_FILE_LINK_ERROR;
  payload: any;
}

export interface DownloadFileByLinkAction {
  type: typeof DOWNLOAD_FILE_BY_LINK;
  payload: { fileLink: string };
}

// GET WALLBOX
export interface GetWallboxesAction {
  type: typeof GET_WALLBOXES;
  payload: {};
}

export interface GetWallboxesSuccessAction {
  type: typeof GET_WALLBOXES_SUCCESS;
  payload: { wallboxes: any };
}

export interface GetWallboxesErrorAction {
  type: typeof GET_WALLBOXES_ERROR;
  payload: any;
}

// CREATE VENDOR
export interface CreateVendorAction {
  type: typeof CREATE_VENDOR;
  payload: string;
}

export interface CreateVendorErrorAction {
  type: typeof CREATE_VENDOR_ERROR;
  payload: any;
}

// CREATE PRODUCT
export interface CreateProductAction {
  type: typeof CREATE_PRODUCT;
  payload: {
    id: string;
    name: string;
  };
}

export interface CreateProductErrorAction {
  type: typeof CREATE_PRODUCT_ERROR;
  payload: any;
}

export interface GetCommentsAction {
  type: typeof GET_COMMENTS;
  payload: {
    id: string;
  };
}
export interface GetCommentsSuccessAction {
  type: typeof GET_COMMENTS_SUCCESS;
  payload: any;
}

export interface GetCommentsErrorAction {
  type: typeof GET_COMMENTS_ERROR;
  payload: any;
}

export interface AddCommentAction {
  type: typeof ADD_COMMENT;
  payload: AddCommentPayloadType;
}
export interface AddCommentSuccessAction {
  type: typeof ADD_COMMENT_SUCCESS;
  payload: any;
}

export interface AddCommentErrorAction {
  type: typeof ADD_COMMENT_ERROR;
  payload: any;
}

export interface UpdateCommentAction {
  type: typeof UPDATE_COMMENT;
  payload: UpdateCommentPayloadType;
}
export interface UpdateCommentSuccessAction {
  type: typeof UPDATE_COMMENT_SUCCESS;
  payload: any;
}

export interface UpdateCommentErrorAction {
  type: typeof UPDATE_COMMENT_ERROR;
  payload: any;
}

export interface DownloadAllDocumentsAction {
  type: typeof DOWNLOAD_ALL_CR_DOCUMENTS;
  payload: any;
}

export interface DownloadAllDocumentsErrorAction {
  type: typeof DOWNLOAD_ALL_CR_DOCUMENTS_ERROR;
  payload: any;
}
export interface GetFotoWizardUploadTokenSuccessAction {
  type: typeof FOTO_WIZARD_UPLOAD_TOKEN_GET_SUCCESS;
  payload: any;
}

export interface GetFotoWizardUploadTokenErrorAction {
  type: typeof FOTO_WIZARD_UPLOAD_TOKEN_GET_ERROR;
  payload: any;
}

export interface AssignBundleToRequestAction {
  type: typeof ASSIGN_BUNDLE_TO_REQUEST;
  payload: {
    id: string;
    bundleId: string;
  };
}

export interface AssignBundleToRequestSuccessAction {
  type: typeof ASSIGN_BUNDLE_TO_REQUEST_SUCCESS;
  payload: string;
}

export interface AssignBundleToRequestErrorAction {
  type: typeof ASSIGN_BUNDLE_TO_REQUEST_ERROR;
  payload: any;
}

export type ClientRequestActionTypes =
  | ClientRequestGetAction
  | ClientRequestGetSuccessAction
  | ClientRequestGetErrorAction
  | ClientRequestsFindAction
  | ClientRequestsFindSuccessAction
  | ClientRequestsFindErrorAction
  | ClientRequestsAllGetAction
  | ClientRequestsAllGetSuccessAction
  | ClientRequestsAllGetErrorAction
  | ClientRequestCreateAction
  | ClientRequestArchiveAction
  | ClientRequestArchiveSuccessAction
  | ClientRequestArchiveErrorAction
  | ClientRequestSuccessAction
  | ClientRequestErrorAction
  | ClientRequestUpdateAction
  | ClientRequestUpdateSuccessAction
  | ClientRequestUpdateErrorAction
  | ClientRequestCostGetAction
  | ClientRequestCostGetSuccessAction
  | ClientRequestCostGetErrorAction
  | ClientRequestDistanceGetAction
  | ClientRequestDistanceGetErrorAction
  | ClientRequestDistanceGetSuccessAction
  | AnswersGetAction
  | AnswersGetSuccessAction
  | AnswersGetErrorAction
  | AnswersSaveAction
  | AnswersSaveSuccessAction
  | AnswersSaveErrorAction
  | AnswersSetAction
  | SingleAnswerSaveAction
  | SingleAnswerSaveSuccessAction
  | SingleAnswerSaveErrorAction
  | SingleAnswerSetAction
  | UpdateTransitionStateAction
  | UpdateTransitionStateSuccessAction
  | UpdateTransitionStateSuccessErrorAction
  | MyClientRequestsGetAction
  | MyClientRequestsGetSuccessAction
  | MyClientRequestsGetErrorAction

  // Upload client request documents actions types
  | UploadRequestFileAction
  | UploadRequestFileProgressAction
  | UploadRequestFileSuccessAction
  | UploadRequestFileErrorAction
  //get house type for FotoWizard
  | GetClientRequestHouseTypeAction
  | GetClientRequestHouseTypeSuccessAction
  | GetClientRequestHouseTypeErrorAction
  // Get request files (uploaded documents) actions types
  | GetClientRequestFilesAction
  | GetClientRequestFilesSuccessAction
  | GetClientRequestFilesErrorAction
  // Delete files actions
  | DeleteFileAction
  | DeleteFileSuccessAction
  | DeleteFileErrorAction
  // Download all documents actions
  | DownloadAllDocumentsAction
  | DownloadAllDocumentsErrorAction
  // Get file link action types
  | GetFileLinkAction
  | GetFileLinkSuccessAction
  | GetFileLinkErrorAction
  | DownloadFileByLinkAction
  // Get wallboxes models
  | GetWallboxesAction
  | GetWallboxesSuccessAction
  | GetWallboxesErrorAction
  // Create vendor
  | CreateVendorAction
  | CreateVendorErrorAction
  // Create product
  | CreateProductAction
  | CreateProductErrorAction
  // Get CR questions
  | GetCommentsAction
  | GetCommentsSuccessAction
  | GetCommentsErrorAction
  // Add comment to CR
  | AddCommentAction
  | AddCommentSuccessAction
  | AddCommentErrorAction
  // Update comment to CR
  | UpdateCommentAction
  | UpdateCommentSuccessAction
  | UpdateCommentErrorAction
  // Get Invoices
  | InvoicesGetAction
  | InvoicesGetSuccessAction
  | InvoicesGetErrorAction
  //GET FOTO wizard upload token
  | GetFotoWizardUploadTokenSuccessAction
  | GetFotoWizardUploadTokenErrorAction
  | AssignBundleToRequestAction
  | AssignBundleToRequestSuccessAction
  | AssignBundleToRequestErrorAction;
