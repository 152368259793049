/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ColumnsType } from 'antd/lib/table';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import OfferTag, { TagType } from 'components/ui/OfferTag/OfferTag';
import Table from 'components/ui/Table/Table';
import Tag from 'components/ui/Tag/Tag';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import { IUserObject } from 'store/auth/auth.types';
import { IOffer, OfferState } from 'store/offer/offer.types';
import { unapprovedOffersGet } from 'store/intermediate/intermediate.actions';

const UnapprovedOffersTable: React.FC<{ dataSource: any; showInstallerName?: boolean }> = ({
  dataSource,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [offset, setOffset] = useState<number>(0);

  const params = {
    offset,
    limit: DEFAULT_PAGINATION_PAGE_SIZE,
  };

  useEffect(() => {
    dispatch(unapprovedOffersGet(params));
  }, [offset]); // eslint-disable-line

  const toDisplayName = (user: IUserObject | undefined) =>
    user ? `${user.firstName} ${user.lastName}`.trim() : '';

  const OfferTableColumns: ColumnsType<IOffer> = [
    {
      title: 'installerFlow:dashboard:orderNr',
      dataIndex: 'id',
      key: 'id',
      sortDirections: ['ascend', 'descend'],
      sorter: ({ clientRequest: a }: IOffer, { clientRequest: b }: IOffer) => {
        return (a?.displayId || '').localeCompare(b?.displayId || '');
      },
      render: (_: string, row: IOffer) => row.clientRequest?.displayId,
    },
    {
      title: 'installerFlow:dashboard:name',
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['ascend', 'descend'],
      sorter: ({ clientRequest: a }: IOffer, { clientRequest: b }: IOffer) =>
        toDisplayName(a?.createdBy).localeCompare(toDisplayName(b?.createdBy)),
      render: (_: string, row: IOffer) => toDisplayName(row.clientRequest?.createdBy),
    },
    {
      title: 'installerFlow:dashboard:progress',
      dataIndex: 'state',
      key: 'state',
      sortDirections: ['ascend', 'descend'],
      sorter: ({ state: a }: IOffer, { state: b }: IOffer) => {
        return (a || '').localeCompare(b || '', []);
      },
      render: (status: OfferState) =>
        status ? (
          <OfferTag type={TagType.pool} state={status}>
            {status}
          </OfferTag>
        ) : (
          <Tag>N/A</Tag>
        ),
    },
    {
      title: 'installerFlow:dashboard:zip',
      dataIndex: 'zip',
      sortDirections: ['ascend', 'descend'],
      sorter: ({ clientRequest: a }: IOffer, { clientRequest: b }: IOffer) => {
        return (a?.address?.postalCode || '').localeCompare(b?.address?.postalCode || '');
      },
      render: (_: string, row: IOffer) => {
        return row.clientRequest?.address ? row.clientRequest?.address.postalCode : 'n/a';
      },
    },
    {
      title: 'installerFlow:dashboard:dueDate',
      dataIndex: 'date',
      key: 'date',
      sortDirections: ['ascend', 'descend'],
      render: (_: string, row: IOffer) => {
        const date = row.finalInstallationTimestamp || row.finalHomeCheckTimestamp || '';
        return date ? <DateFormat date={date} /> : 'n/a';
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={OfferTableColumns.map((order: any) => ({ ...order, title: t(`${order.title}`) }))}
      dataSource={dataSource.items}
      onRowClick={(record) => `/my-projects/client-request/${record.clientRequest.id}`}
      pagination={{
        total: dataSource?.total || 0,
        position: ['bottomCenter'],
        pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
        current: offset ? offset / DEFAULT_PAGINATION_PAGE_SIZE + 1 : 1,
        onChange: (page) => setOffset((page - 1) * DEFAULT_PAGINATION_PAGE_SIZE),
      }}
    />
  );
};

export default UnapprovedOffersTable;
