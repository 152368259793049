import React, { useEffect } from 'react';
import { Flex, Form, FormInstance, InputNumber, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MultiLangInput from 'components/ui/MultiLangEntries/MultiLangInput';
import { ValidationFactory } from 'framework/validations';
import { customDocumentSelector } from 'store/intermediate/intermediate.selectors';
import {
  CustomDocumentsCategoryType,
  ICustomDocument,
  IPool,
} from 'store/intermediate/intermediate.types';
import styles from './ServicesFeeSettings.module.sass';

interface IServicesFeeSettingsProps {
  form: FormInstance;
  pool: IPool;
}
const ServicesFeeSettings = (props: IServicesFeeSettingsProps) => {
  const { form, pool } = props;
  const { t } = useTranslation();
  const sectionLocizeKey = 'intermediate:myProjects:poolSettings:servicesFee';
  const descriptionsKey = 'servicesFeeLocalizedDescriptions';
  const customDocuments = useSelector(customDocumentSelector) as ICustomDocument[];
  const isServiceFeeEnabledWatcher = Form.useWatch('isServiceFeeEnabled', form);

  useEffect(() => {
    const serviceFeeDescriptions = customDocuments
      .filter(
        (_customDocument) =>
          _customDocument.category === CustomDocumentsCategoryType.PoolServiceFreeDescription,
      )
      .map((_customDocuments) => ({
        value: _customDocuments.text,
        lang: _customDocuments.lang,
      }));
    if (serviceFeeDescriptions?.length === 0) {
      form.setFieldValue(descriptionsKey, [
        {
          lang: pool.country.defaultLanguage,
          value: '',
        },
      ]);
      return;
    }
    form.setFieldValue(descriptionsKey, serviceFeeDescriptions);
  }, [customDocuments, form, pool]);

  return (
    <Flex vertical={true} gap={8} className={styles.padding}>
      <Form.Item name={'isServiceFeeEnabled'} label={t(`${sectionLocizeKey}:isEnabled`)}>
        <Switch />
      </Form.Item>
      {isServiceFeeEnabledWatcher && (
        <>
          <Form.Item label={t(`${sectionLocizeKey}:feeField`)} name={'serviceFeeCost'}>
            <InputNumber
              prefix={pool.country.currencySymbol}
              className={styles.input}
              controls={false}
            />
          </Form.Item>
          <Typography.Title level={5}>{t(`${sectionLocizeKey}:description`)}</Typography.Title>
          <MultiLangInput
            fieldName={descriptionsKey}
            label={t(`${sectionLocizeKey}:descriptionPlaceholder`)}
            rules={[ValidationFactory.REQUIRED]}
            form={form}
          />
        </>
      )}
    </Flex>
  );
};
export default ServicesFeeSettings;
