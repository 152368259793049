import _groupBy from 'lodash/groupBy';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import ClientRequestAnswersAPI from 'services/client-request-answers.service';
import ClientRequestAPI from 'services/client-request.service';
import DocumentsAPI from 'services/documents.service';
import OfferAPI from 'services/offer.service';
import { userGetDocuments } from 'store/user/user.actions';
import { clientRequestArchiveSuccess as intermediateArchiveSucess } from 'store/intermediate/intermediate.actions';
import { IProductModel } from 'store/product/product.types';
import {
  addCommentError,
  addCommentSuccess,
  clientRequestAnswerSaveError,
  clientRequestAnswerSaveSuccess,
  clientRequestAnswersGetError,
  clientRequestAnswersGetSuccess,
  clientRequestAnswersSave,
  clientRequestAnswersSaveError,
  clientRequestAnswersSaveSuccess,
  clientRequestCostGetError,
  clientRequestCostGetSuccess,
  clientRequestCreate,
  clientRequestCreateError,
  clientRequestCreateSuccess,
  clientRequestDistanceGetErrorAction,
  clientRequestDistanceGetSuccessAction,
  clientRequestGetError,
  clientRequestGetSuccess,
  clientRequestsAllGetSuccess,
  clientRequestsFindError,
  clientRequestsFindSuccess,
  clientRequestUpdateError,
  clientRequestUpdateSuccess,
  deleteFileError,
  deleteFileSuccess,
  downloadFileByLink,
  getClientRequestFiles,
  getClientRequestFilesError,
  getClientRequestFilesSuccess,
  getCommentsError,
  getCommentsSuccess,
  getFileLinkError,
  getFileLinkSuccess,
  getWallboxesError,
  getWallboxesSuccess,
  invoicesGetError,
  invoicesGetSuccess,
  myClientRequestGetError,
  myClientRequestGetSuccess,
  updateCommentSuccess,
  updateTransitionStateError,
  updateTransitionStateSuccess,
  uploadFileError,
  uploadFileSuccess,
  createVendorError,
  getWallboxes,
  createProductError,
  downloadAllDocumentsError,
  clientRequestArchiveError,
  clientRequestArchiveSuccess,
  getCustomerDocumentUploadTokenSuccess,
  getCustomerDocumentUploadTokenError,
  assignBundleToRequestError,
  assignBundleToRequestSuccess,
  getClientRequestHouseTypeSuccess,
  getClientRequestHouseTypeError,
  deleteLandingCoverImageError,
  deleteLandingCoverImageSuccess,
} from './client-request.actions';
import {
  ADD_COMMENT,
  AddCommentAction,
  ANSWERS_GET,
  ANSWERS_SAVE,
  AnswersGetAction,
  AnswersSaveAction,
  CLIENT_REQUEST_CREATE,
  CLIENT_REQUEST_DISTANCE_GET,
  CLIENT_REQUEST_GET,
  CLIENT_REQUEST_UPDATE,
  CLIENT_REQUESTS_ALL_GET,
  CLIENT_REQUESTS_COST_GET,
  CLIENT_REQUESTS_FIND,
  ClientRequestCostGetAction,
  ClientRequestCreateAction,
  ClientRequestDistanceGetAction,
  ClientRequestGetAction,
  ClientRequestsFindAction,
  ClientRequestTransitionTypes,
  ClientRequestUpdateAction,
  CREATE_PRODUCT,
  CREATE_VENDOR,
  CreateProductAction,
  CreateVendorAction,
  DELETE_FILE,
  DeleteFileAction,
  DOWNLOAD_ALL_CR_DOCUMENTS,
  DOWNLOAD_FILE_BY_LINK,
  DownloadFileByLinkAction,
  GET_CLIENT_REQUEST_FILES,
  GET_COMMENTS,
  GET_FILE_LINK,
  GET_INVOICES,
  GET_WALLBOXES,
  GetClientRequestFilesAction,
  GetCommentsAction,
  GetFileLinkAction,
  IClientRequestSearchResults,
  InvoicesGetAction,
  MediaCategoryType,
  MY_CLIENT_REQUESTS_GET,
  SINGLE_ANSWER_SAVE,
  SingleAnswerSaveAction,
  TypeDistance,
  UPDATE_COMMENT,
  UPDATE_TRANSITION_STATE,
  UpdateCommentAction,
  UpdateTransitionStateAction,
  UPLOAD_CLIENT_REQUEST_FILE,
  UploadRequestFileAction,
  DownloadAllDocumentsAction,
  ClientRequestArchiveAction,
  CLIENT_REQUEST_ARCHIVE,
  GET_CUSTOMER_DOCUMENT_UPLOAD_TOKEN,
  FotowizardUploadDocumentTokenAction,
  AssignBundleToRequestAction,
  ASSIGN_BUNDLE_TO_REQUEST,
  GetClientRequestHouseTypeAction,
  GET_CLIENT_REQUEST_HOUSE_TYPE,
  DELETE_LANDING_COVER_IMAGE,
  DeleteLandingCoverImageAction,
  IClientRequest,
  IClientRequestAnswer,
  IDocument,
  IFile,
  QuestionType,
  OfferDocument,
} from './client-request.types';

export function* getClientRequest(action: ClientRequestGetAction): Generator<any, void, any> {
  try {
    const state = yield select();
    let clientRequest = yield call(ClientRequestAPI.getById, action.payload.clientRequestId);
    if (clientRequest) {
      if (clientRequest.offers && action.payload.shouldFetchOffers) {
        for (let i = 0; i < clientRequest.offers.length; i++) {
          if (
            state?.auth?.user?.id === clientRequest.offers[i].installerUserId ||
            action.payload.isIntermediate
          ) {
            const documents = yield call(DocumentsAPI.getOfferFiles, clientRequest.offers[i].id);
            clientRequest.offers[i] = { ...clientRequest.offers[i], documents };
          }
        }
      }
      yield put(clientRequestGetSuccess(clientRequest));
    } else {
      yield put(clientRequestGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestGetError(message));
  }
}

// For Customer
export function* getClientRequests(): Generator<any, void, any> {
  try {
    const requestsRaw = yield call(ClientRequestAPI.getMyRequests);

    if (requestsRaw) {
      yield put(clientRequestsAllGetSuccess(requestsRaw));
    } else {
      yield put(clientRequestCreate({ type: ClientRequestTransitionTypes.created }));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestGetError(message));
  }
}

export function* getFotoWizardUploadToken(
  action: FotowizardUploadDocumentTokenAction,
): Generator<any, void, any> {
  try {
    const requestsRaw: any = yield call(
      ClientRequestAPI.getFotoWizardUploadToken,
      action.payload.requestId,
    );
    if (requestsRaw) {
      yield put(getCustomerDocumentUploadTokenSuccess(requestsRaw.uploadToken));
    } else {
      yield put(getCustomerDocumentUploadTokenError('No token'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestGetError(message));
  }
}

export function* getMyClientRequests(): Generator<any, void, any> {
  try {
    const requestsRaw = yield call(ClientRequestAPI.getMyClientRequests);

    if (requestsRaw) {
      yield put(myClientRequestGetSuccess(requestsRaw.items));
    } else {
      yield put(myClientRequestGetError('No requests'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(myClientRequestGetError(message));
  }
}

// For installer Dashboard
export function* getAvailableClientRequest(action: ClientRequestsFindAction) {
  try {
    const { limit, offset, radius, zip, state, query } = action.payload;
    const foundRequests: IClientRequestSearchResults = yield call(
      ClientRequestAPI.findAvailable,
      limit,
      offset,
      radius,
      zip,
      state,
      query,
    );
    yield put(clientRequestsFindSuccess(foundRequests));
  } catch (err: any) {
    const message = Array.isArray(err.message) ? err.message[0] : err.message;
    yield put(clientRequestsFindError(message));
  }
}

export function* archiveClientRequest(action: ClientRequestArchiveAction) {
  try {
    const { clientRequestId, archived } = action.payload;

    const archiveClientRequest: IClientRequest = yield call(
      ClientRequestAPI.archiveById,
      clientRequestId,
      archived,
    );

    yield put(clientRequestArchiveSuccess({ response: archiveClientRequest, id: clientRequestId }));
    yield put(intermediateArchiveSucess({ response: archiveClientRequest, id: clientRequestId }));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestArchiveError(message));
  }
}

export function* createClientRequest(action: ClientRequestCreateAction) {
  try {
    const { clientRequest, answers } = action.payload;

    const savedClientRequest: IClientRequest = yield call(ClientRequestAPI.create, clientRequest);
    yield put(clientRequestCreateSuccess(savedClientRequest));

    if (answers && answers.length > 0) {
      yield put(clientRequestAnswersSave(savedClientRequest.id!, answers));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestCreateError(message));
  }
}

export function* updateTransitionState(action: UpdateTransitionStateAction) {
  try {
    const { id, type, rejectReason, onSuccess, data } = action.payload;
    const questionnaireSkipped = type === ClientRequestTransitionTypes.skippedQuestionnaire;

    const acceptedClientRequest: IClientRequest = yield call(ClientRequestAPI.transition, {
      type,
      clientRequest: {
        id,
        questionnaireSkipped: questionnaireSkipped || undefined,
        rejectReason: rejectReason || undefined,
        ...data,
      },
    });
    onSuccess?.();
    yield put(updateTransitionStateSuccess(acceptedClientRequest));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(updateTransitionStateError(message));
  }
}

export function* updateClientRequest(action: ClientRequestUpdateAction) {
  const { clientRequestId, fields, onSuccess, onError } = action.payload;
  try {
    const updatedClientRequest: IClientRequest = yield call(
      ClientRequestAPI.update,
      clientRequestId,
      fields,
    );

    if (updatedClientRequest) {
      yield put(clientRequestUpdateSuccess(updatedClientRequest));
      onSuccess?.(updatedClientRequest);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestUpdateError(message));
    onError?.(message);
  }
}

export function* clientRequestGetCost(
  action: ClientRequestCostGetAction,
): Generator<any, void, any> {
  try {
    const { clientRequestId } = action.payload;
    const cost = yield call(ClientRequestAPI.getRequestCost, clientRequestId);
    yield put(clientRequestCostGetSuccess(cost));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestCostGetError(message));
  }
}

export function* getClientRequestDistance(action: ClientRequestDistanceGetAction) {
  try {
    const { clientRequestId } = action.payload;
    const distance: TypeDistance[] = yield call(
      ClientRequestAPI.getRequestDistance,
      clientRequestId,
    );

    // one zip code can contain a few places.
    // we show to user the min value

    yield put(clientRequestDistanceGetSuccessAction(distance));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestDistanceGetErrorAction(message));
  }
}

export function* getAnswers(action: AnswersGetAction) {
  try {
    const clientRequest: IClientRequestAnswer[] = yield call(
      ClientRequestAnswersAPI.getByClientRequestId,
      action.payload,
    );
    yield put(clientRequestAnswersGetSuccess(clientRequest));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestAnswersGetError(message));
  }
}

export function* saveClientRequestAnswers(action: AnswersSaveAction) {
  const { clientRequestId, answers, onSuccess, onError } = action.payload;

  try {
    const savedAnswers: IClientRequestAnswer[] = yield call(ClientRequestAnswersAPI.saveBulk, {
      clientRequestId,
      answers,
    });
    yield put(clientRequestAnswersSaveSuccess(savedAnswers));

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestAnswersSaveError(message));

    if (typeof onError === 'function') {
      onError();
    }
  }
}

export function* saveClientRequestAnswer(action: SingleAnswerSaveAction) {
  try {
    const answer: IClientRequestAnswer = yield call(ClientRequestAnswersAPI.save, action.payload);
    yield put(clientRequestAnswerSaveSuccess(answer));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(clientRequestAnswerSaveError(message));
  }
}

export function* uploadClientRequestFile(action: UploadRequestFileAction) {
  const {
    document,
    requestId,
    category,
    config,
    uploadProgress,
    uploadSuccess,
    uploadError,
    isFotoWizard,
    token,
  } = action.payload;

  try {
    const uploadedDocument: IDocument = yield call(
      DocumentsAPI.uploadFile,
      document,
      requestId,
      category,
      config,
      uploadProgress,
      isFotoWizard,
      token,
    );

    if (uploadedDocument) {
      yield put(uploadFileSuccess(uploadedDocument));
      if (requestId) {
        yield put(getClientRequestFiles(requestId, category, token));
      }

      if (typeof uploadSuccess === 'function') {
        uploadSuccess(uploadedDocument);
      }
    }
  } catch (err: any) {
    const { response = {} } = err;
    const { data = {} } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(uploadFileError(message));

    if (typeof uploadError === 'function') {
      uploadError(message);
    }
  }
}

export function* getClientRequestDocuments(action: GetClientRequestFilesAction) {
  try {
    const { requestId, token, category } = action.payload;
    const files: IFile = token
      ? yield call(DocumentsAPI.getClientRequestFilesByToken, requestId, token, category)
      : yield call(DocumentsAPI.getClientRequestFiles, requestId, category);

    if (Array.isArray(files)) {
      const groupedFiles = _groupBy(files, 'category');
      yield put(getClientRequestFilesSuccess(groupedFiles, category));
    }
  } catch (err: any) {
    const { response = {} } = err;
    const { data = {} } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(getClientRequestFilesError(message));
  }
}

export function* getClientRequestHouseType(action: GetClientRequestHouseTypeAction) {
  try {
    const { id, token } = action.payload;
    const type: string = yield call(ClientRequestAPI.getHouseType, id, token);
    yield put(getClientRequestHouseTypeSuccess(type));
  } catch (err: any) {
    const { response = {} } = err;
    const { data = {} } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(getClientRequestHouseTypeError(message));
  }
}

export function* deleteUserFile(action: DeleteFileAction) {
  try {
    const { fileName, fileId, category, onSuccess } = action.payload;
    yield call(DocumentsAPI.deleteFile, fileName);
    if (category !== MediaCategoryType.InstallerConfirmation) {
      yield put(deleteFileSuccess(fileId, category));
      onSuccess && onSuccess();
    } else {
      yield put(userGetDocuments(category));
    }
  } catch (err: any) {
    yield put(deleteFileError(err));
  }
}

export function* deleteLandingCoverImage(action: DeleteLandingCoverImageAction) {
  try {
    const { fileId, category } = action.payload;
    yield call(DocumentsAPI.deleteLandingCoverImage, fileId);
    yield put(deleteLandingCoverImageSuccess(fileId, category));
  } catch (err: any) {
    yield put(deleteLandingCoverImageError(err));
  }
}

export function* getFileLink(action: GetFileLinkAction) {
  try {
    const { fileName } = action.payload;
    const response: { link: string } = yield call(DocumentsAPI.getFileLink, fileName);
    yield put(getFileLinkSuccess(response.link, fileName));

    if (
      response.link &&
      !response.link.includes('pool') &&
      !response.link.includes('custom_document')
    ) {
      yield put(downloadFileByLink(response.link));
    }
  } catch (err: any) {
    yield put(getFileLinkError(err));
  }
}

export function openFile(action: DownloadFileByLinkAction) {
  const { fileLink } = action.payload;
  window.open(fileLink, '_blank');
}

export function* getAllWallboxes() {
  try {
    const response: IProductModel[] = yield call(ClientRequestAPI.getWallboxes);
    yield put(getWallboxesSuccess(response));
  } catch (err: any) {
    yield put(getWallboxesError(err));
  }
}

export function* createVendor(action: CreateVendorAction) {
  try {
    yield call(ClientRequestAPI.createVendor, action.payload);
    yield put(getWallboxes());
  } catch (err: any) {
    yield put(createVendorError(err));
  }
}

export function* createProduct(action: CreateProductAction) {
  try {
    const { id, name } = action.payload;
    yield call(ClientRequestAPI.createProduct, id, name);
    yield put(getWallboxes());
  } catch (err: any) {
    yield put(createProductError(err));
  }
}

export function* getComments(action: GetCommentsAction) {
  try {
    const { id } = action.payload;
    const response: QuestionType[] = yield call(ClientRequestAPI.getComments, id);
    yield put(getCommentsSuccess(response));
  } catch (err: any) {
    yield put(getCommentsError(err));
  }
}

export function* addComment(action: AddCommentAction) {
  try {
    const { id, params, onSuccess } = action.payload;
    const response: QuestionType = yield call(ClientRequestAPI.addComment, id, params);
    yield put(addCommentSuccess(response));
    onSuccess(response.id);
  } catch (err: any) {
    action.payload.onError && action.payload.onError();
    yield put(addCommentError(err));
  }
}

export function* updateComment(action: UpdateCommentAction) {
  try {
    const { clientRequestId, commentId, params, onSuccess } = action.payload;
    const response: QuestionType = yield call(
      ClientRequestAPI.updateComment,
      clientRequestId,
      commentId,
      params,
    );
    yield put(updateCommentSuccess(response));
    onSuccess();
  } catch (err: any) {
    yield put(addCommentError(err));
  }
}

export function* getInvoices(action: InvoicesGetAction) {
  try {
    const response: OfferDocument[] = yield call(OfferAPI.getInvoices);
    yield put(invoicesGetSuccess(response));
  } catch (err: any) {
    yield put(invoicesGetError(err));
  }
}

export function* downloadAllDocuments(action: DownloadAllDocumentsAction) {
  try {
    const { id } = action.payload;
    yield call(ClientRequestAPI.downloadAllDocuments, id);
  } catch (err: any) {
    yield put(downloadAllDocumentsError(err));
  }
}

export function* assignBundleToRequest(action: AssignBundleToRequestAction) {
  try {
    const { id, bundleId } = action.payload;
    yield call(ClientRequestAPI.assignBundleToRequest, id, bundleId);
    yield put(assignBundleToRequestSuccess(bundleId));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(assignBundleToRequestError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_CLIENT_REQUEST_HOUSE_TYPE, getClientRequestHouseType),
    takeLatest(CLIENT_REQUEST_CREATE, createClientRequest),
    takeLatest(ANSWERS_SAVE, saveClientRequestAnswers),
    takeLatest(SINGLE_ANSWER_SAVE, saveClientRequestAnswer),
    takeLatest(CLIENT_REQUEST_GET, getClientRequest),
    takeLatest(CLIENT_REQUEST_DISTANCE_GET, getClientRequestDistance),
    takeLatest(CLIENT_REQUESTS_ALL_GET, getClientRequests),
    takeLatest(CLIENT_REQUESTS_FIND, getAvailableClientRequest),
    takeLatest(CLIENT_REQUEST_UPDATE, updateClientRequest),
    takeLatest(CLIENT_REQUESTS_COST_GET, clientRequestGetCost),
    takeLatest(UPDATE_TRANSITION_STATE, updateTransitionState),
    takeLatest(ANSWERS_GET, getAnswers),
    takeLatest(UPLOAD_CLIENT_REQUEST_FILE, uploadClientRequestFile),
    takeLatest(GET_CLIENT_REQUEST_FILES, getClientRequestDocuments),
    takeLatest(DELETE_FILE, deleteUserFile),
    takeLatest(DELETE_LANDING_COVER_IMAGE, deleteLandingCoverImage),
    takeEvery(GET_FILE_LINK, getFileLink),
    takeLatest(DOWNLOAD_FILE_BY_LINK, openFile),
    takeLatest(GET_WALLBOXES, getAllWallboxes),
    takeLatest(MY_CLIENT_REQUESTS_GET, getMyClientRequests),
    takeLatest(GET_COMMENTS, getComments),
    takeLatest(ADD_COMMENT, addComment),
    takeLatest(UPDATE_COMMENT, updateComment),
    takeLatest(GET_INVOICES, getInvoices),
    takeLatest(CREATE_VENDOR, createVendor),
    takeLatest(CREATE_PRODUCT, createProduct),
    takeLatest(DOWNLOAD_ALL_CR_DOCUMENTS, downloadAllDocuments),
    takeLatest(CLIENT_REQUEST_ARCHIVE, archiveClientRequest),
    takeLatest(GET_CUSTOMER_DOCUMENT_UPLOAD_TOKEN, getFotoWizardUploadToken),
    takeLatest(ASSIGN_BUNDLE_TO_REQUEST, assignBundleToRequest),
  ]);
}
