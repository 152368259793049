import React, { useState, useMemo } from 'react';
import { useEffect } from 'react';
// @ts-ignore
import AccCore from 'opentok-accelerator-core';

import { notification, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import rotateIcon from 'assets/icons/rotate.svg';
import Icon from 'components/ui/Icon/Icon';
import styles from '../VideoCall.module.sass';
import SettingSection from '../SettingSection';
import configBuilder, { containerClasses } from '../helpers';

interface ICallProps {
  config: any;
  endCall: () => void;
}

const Call: React.FC<ICallProps> = ({ config, endCall }) => {
  // eslint-disable-next-line no-undef
  const openTok = OT;

  const { t } = useTranslation();

  const [localAudioEnabled, setLocalAudioEnabled] = useState(true);
  const [localVideoEnabled, setLocalVideoEnabled] = useState(true);
  const [vonageInstance, setVonageInstance] = useState<any>(null);
  const [publisher, setPublisher] = useState(null);
  const [meta, setMeta] = useState(null);

  const callConfig = useMemo(
    () => ({
      insertMode: 'append',
      width: '100%',
      height: '100%',
      fitMode: 'cover',
      mirror: false,
      publishVideo: localVideoEnabled,
      publishAudio: localAudioEnabled,
      style: { buttonDisplayMode: 'off' },
    }),
    [localAudioEnabled, localVideoEnabled],
  );

  useEffect(() => {
    const { apiKey, sessionId, token } = config;
    const callPublisher = new AccCore(configBuilder(apiKey, sessionId, token));
    setVonageInstance(callPublisher);
    callPublisher
      .connect()
      .then(() => {
        callPublisher
          .startCall(callConfig)
          .then((res: any) => {
            const { meta, publisher } = res;
            setPublisher(publisher);
            setMeta(meta);
          })
          .catch((error: any) => {
            notification.error({ message: t('remoteHomeCheck:errors:startCall') });
          });
      })
      .catch((error: any) => {
        notification.error({ message: t('remoteHomeCheck:errors:connectMeeting') });
      });

    const events = [
      'subscribeToCamera',
      'unsubscribeFromCamera',
      'subscribeToScreen',
      'unsubscribeFromScreen',
      'startScreenShare',
    ];

    events.forEach(
      (event) =>
        // @ts-ignore
        vonageInstance?.on(event, ({ meta, publisher }) => {
          setMeta(meta);
          setPublisher(publisher);
        }),
    );
    return () => {
      callPublisher.endCall();
      callPublisher.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleLocalAudio = () => {
    vonageInstance?.toggleLocalAudio(!localAudioEnabled);
    setLocalAudioEnabled(!localAudioEnabled);
  };

  const toggleLocalVideo = () => {
    vonageInstance?.toggleLocalVideo(!localVideoEnabled);
    setLocalVideoEnabled(!localVideoEnabled);
  };

  const switchCamera = (publisher: any) => {
    if (publisher)
      publisher.cycleVideo().catch((error: any) => {
        notification.error({ message: t('remoteHomeCheck:errors:switchCamera') });
      });
  };

  const {
    localAudioClass,
    localVideoClass,
    localCallClass,
    controlClass,
    cameraPublisherClass,
    screenPublisherClass,
    cameraSubscriberClass,
    screenSubscriberClass,
  } = containerClasses(true, meta, localAudioEnabled, localVideoEnabled, true);

  return (
    <>
      <div className="App-main-customer">
        <div className="App-video-container">
          <div id="cameraPublisherContainer" className={cameraPublisherClass} />
          <div id="screenPublisherContainer" className={screenPublisherClass} />
          <div id="cameraSubscriberContainer" className={cameraSubscriberClass} />
          <div id="screenSubscriberContainer" className={screenSubscriberClass} />
          <div className={styles.topLeft}>
            <SettingSection OT={openTok} publisher={publisher} />
          </div>
        </div>
        <div id="controls" className={controlClass}>
          <div className={localAudioClass} onClick={toggleLocalAudio} />
          <div className={localVideoClass} onClick={toggleLocalVideo} />
          <div className={localCallClass} onClick={endCall} />
          <Tooltip title={t('remoteHomeCheck:tooltip:switchCamera')}>
            <div
              className={'ots-video-control circle take-snapshot'}
              onClick={() => switchCamera(publisher)}
            >
              <span
                style={{
                  fontSize: 'xx-large',
                }}
              >
                <Icon icon={rotateIcon} width={'30px'} height={'30px'} />
              </span>
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default Call;
