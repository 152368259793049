import React, { useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import Checkbox from 'components/ui/Checkbox/Checkbox';
import TextField from 'components/ui/TextField/TextField';
import FiltersContext from 'context/filters.context';
import { useOutsideClick } from 'hooks/common';
import styles from './FilterMultiSelect.module.sass';

export type MultiSelectOption = {
  value: string;
  label: string;
};

export interface IFilterMultiSelectProps {
  name: string;
  label: string;
  options: MultiSelectOption[];
  onFilterChange?(): void;
}

const FilterMultiSelect: React.FC<IFilterMultiSelectProps> = ({
  name,
  label = 'Select',
  options,
  onFilterChange,
}) => {
  const { t } = useTranslation();
  const { filters, setFilters } = useContext(FiltersContext);

  const checkedItems: any = filters[name] || [];

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const handleCheckboxChange = (el: string) => {
    onFilterChange && onFilterChange();

    const itemIndex = checkedItems.indexOf(el);
    const newState = [...(checkedItems as string[])];

    itemIndex === -1 ? newState.push(el) : newState.splice(itemIndex, 1);

    setFilters({
      ...filters,
      [name]: newState,
    });
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const renderBox = () => {
    return (
      <div className={styles.filtersBox}>
        <div className={styles.filtersBoxSearch}>
          <TextField
            placeholder={t('intermediate:myProjects:filters:searchStatus')}
            className={styles.searchField}
            type="text"
            value={inputValue}
            prefix={<SearchOutlined />}
            onInput={handleInputChange}
          />
        </div>
        <div className={styles.filtersBoxList}>
          {options
            .filter((i) => i.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
            .map(({ value, label }) => (
              <Checkbox
                key={value}
                checked={checkedItems.indexOf(value) !== -1}
                onChange={() => handleCheckboxChange(value)}
                className={styles.checkbox}
              >
                <div className={styles.checkboxText}>{label}</div>
              </Checkbox>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.filterMultiSelect} ref={ref}>
      <div
        className={clsx(styles.filterMultiSelectField, {
          [styles.filterMultiSelectFieldBlue]: checkedItems.length,
        })}
        onClick={() => setOpen(!open)}
      >
        {label} {checkedItems.length ? `(${checkedItems.length})` : false}
        <span className={styles.filterMultiSelectIcon} />
      </div>
      {open && renderBox()}
    </div>
  );
};

export default FilterMultiSelect;
