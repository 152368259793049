import React, { FC, useContext, useState } from 'react';
import { Button, Carousel, Modal, Skeleton, Typography } from 'antd';

import draftToHtml from 'draftjs-to-html';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Loader from 'components/ui/Loader/Loader';
import { CustomerDashboardContext } from 'context/customerDashboard.context';
import { IProduct } from 'store/product/product.types';
import styles from './Request.module.sass';
import { IWidgetProps } from '../widget.types';
const Request: FC<IWidgetProps> = () => {
  const [product, setProduct] = useState<IProduct>();
  const { request } = useContext(CustomerDashboardContext);
  const { t } = useTranslation();

  if (!request) return <Loader />;

  const renderProduct = (product: IProduct) => {
    return (
      <div key={product.id} className={styles.product}>
        <img src={product.mediaUrl} alt={`product coverImage ${product.id}`} />
        <div className={styles.information}>
          <span className={styles.title}>{product.title}</span>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: getDescription(product.description!) }}
          />
          <Button type="primary" block onClick={() => setProduct(product)}>
            {t('customerFlow:dashboard:request:button:showComponent')}
          </Button>
        </div>
      </div>
    );
  };

  const getDescription = (description: string) => {
    return draftToHtml(JSON.parse(description));
  };

  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick, children } = props;
    return (
      <div className={clsx(className, styles.carouselArrow)} style={style} onClick={onClick}>
        {children}
      </div>
    );
  };

  return (
    <>
      {request?.products?.length ? (
        <div className={styles.wallboxSection}>
          <Carousel
            dots={false}
            arrows
            nextArrow={
              <SamplePrevArrow>
                <CaretRightOutlined />
              </SamplePrevArrow>
            }
            prevArrow={
              <SamplePrevArrow>
                <CaretLeftOutlined />
              </SamplePrevArrow>
            }
          >
            {request?.products?.map((product) => renderProduct(product))}
          </Carousel>
        </div>
      ) : null}
      <p>
        <span className={styles.description}>
          {t('customerFlow:dashboard:request:texts:request')}
        </span>{' '}
        {request.displayId}
        <br />
        <span className={styles.description}>
          {moment(request?.updatedAt).format('DD.MM.YYYY')}
        </span>
      </p>
      <Modal
        title={t('customerFlow:dashboard:request:modal:title')}
        open={!!product}
        onCancel={() => setProduct(undefined)}
        footer={false}
      >
        {product ? (
          <>
            <img
              src={product?.mediaUrl}
              alt={`product coverImage ${product?.id}`}
              className={styles.coverImage}
            />
            <Typography.Title level={4}>{product?.title}</Typography.Title>
            <div className={styles.information}>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: getDescription(product?.description!) }}
              />
            </div>
          </>
        ) : (
          <Skeleton />
        )}
      </Modal>
    </>
  );
};

export default Request;
