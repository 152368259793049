import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loader from 'components/ui/Loader/Loader';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { installationWizardCustomImageUrlsSelector } from 'store/intermediate/intermediate.selectors';
import { RequestState } from 'store/common.types';
import OptionCard from './OptionCard';

import styles from './JourneyOptions.module.sass';

interface IJourneyOptions {
  onChange?: any;
  value?: any;
}

export enum AnswerType {
  Wallbox = 'wallbox',
  Installation = 'installation',
}

const WALLBOX_DEFAULT_IMAGE =
  '/images/questionnaire/product-wizard/journey-options/wallbox-step-option-wallbox.jpg';
const WALLBOX_INSTALLATION_DEFAULT_IMAGE =
  '/images/questionnaire/product-wizard/journey-options/wallbox-step-option-installation.jpg';

const JourneyOptions: React.FC<IJourneyOptions> = ({ onChange, value }) => {
  const request = useSelector(currentClientRequestSelector);
  const installationWizardCustomImageUrlsState = useSelector(
    installationWizardCustomImageUrlsSelector,
  );
  const pool = useMemo(() => request?.pool, [request]);
  const { t } = useTranslation();

  const options = [
    {
      title: t('customerFlow:productWizard:wallbox:title'),
      description: t('customerFlow:productWizard:wallbox:description'),
      type: AnswerType.Wallbox,
      image: installationWizardCustomImageUrlsState.wallboxAndInstallation || WALLBOX_DEFAULT_IMAGE,
      checked: value === AnswerType.Wallbox,
      onClick: () => onChange(AnswerType.Wallbox),
      visible: pool?.wizardWallboxJourney,
    },
    {
      title: t('customerFlow:productWizard:installation:title'),
      description: t('customerFlow:productWizard:installation:description'),
      type: AnswerType.Installation,
      image:
        installationWizardCustomImageUrlsState.wallboxOnly || WALLBOX_INSTALLATION_DEFAULT_IMAGE,
      checked: value === AnswerType.Installation,
      onClick: () => onChange(AnswerType.Installation),
      visible: pool?.wizardInstallationJourney,
    },
  ];

  if (installationWizardCustomImageUrlsState.state === RequestState.Loading) return <Loader />;

  return (
    <div className={styles.options}>
      {options.map((item) => item.visible && <OptionCard key={item.type} {...item} />)}
    </div>
  );
};

export default JourneyOptions;
