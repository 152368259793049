import config from 'config';
import HttpService from './http.service';
import { Organization } from '../store/intermediate/intermediate.types';

class PoolTenderOrganizationAPI extends HttpService {
  INTERMEDIATE_API = 'intermediates';

  getPoolOrganizationsByPoolId = (poolId: string): Promise<Organization[]> => {
    return this.get(`${this.INTERMEDIATE_API}/pools/${poolId}/organizations`);
  };

  updatePoolOrganizationsByPoolId = (poolId: string, organization: Organization) => {
    return this.put(`${this.INTERMEDIATE_API}/pools/${poolId}/organizations`, { ...organization });
  };

  deletePoolOrganizationsByPoolId = (poolId: string) => {
    return this.delete(`${this.INTERMEDIATE_API}/pools/${poolId}/organizations`);
  };
}

export default new PoolTenderOrganizationAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
