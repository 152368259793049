import React, { useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useFetchCustomerLegalDocuments from 'hooks/useFetchCustomerLegalDocuments';
import { legalDocumentsSelector } from 'store/intermediate/intermediate.selectors';
import { isRegistrationInviteTokenSelector } from 'store/register/register.selectors';
import { CustomDocumentsCategoryType } from 'store/intermediate/intermediate.types';
import { isCustomerSelector } from 'store/auth/auth.selectors';
import { getLegalDocumentData } from 'utils/commonUtils';

import styles from './TermsPage.module.sass';
import TermsCustomerComponent from '../../components/static/TermsCustomerComponent/TermsCustomerComponent';
import BoxContent from '../../components/layout/BoxContent/BoxContent';
import Box from '../../components/layout/Box/Box';
import TermsInstallerComponent from '../../components/static/TermsInstallerComponent/TermsInstallerComponent';
const { TabPane } = Tabs;

const TermsPage: React.FC = () => {
  const { t } = useTranslation();

  const { search } = useLocation();
  let activeTabQueryStr = new URLSearchParams(search).get('at'); // at: active tab
  if (activeTabQueryStr !== '1' && activeTabQueryStr !== '2') {
    activeTabQueryStr = '1';
  }
  const [activeTab, setActiveTab] = useState(activeTabQueryStr);

  const token = useSelector(isRegistrationInviteTokenSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const legalDocuments = useSelector(legalDocumentsSelector);
  useFetchCustomerLegalDocuments();
  const customTerms = useMemo(() => {
    const value = getLegalDocumentData(
      legalDocuments,
      CustomDocumentsCategoryType.PoolTermsConditions,
    );
    if (isCustomer || token) {
      return value ? (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <TermsCustomerComponent />
      );
    }

    return <TermsCustomerComponent />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalDocuments]);

  return (
    <Box>
      <BoxContent title={t('common:texts:termsAndConditions')} showBackButton={false}>
        <div className={styles.container}>
          <Tabs defaultActiveKey={activeTab} onChange={(key) => setActiveTab(key)}>
            <TabPane tab={t('common:texts:termsForInstaller')} key="1">
              <TermsInstallerComponent />
            </TabPane>

            <TabPane tab={t('common:texts:termsForCustomer')} key="2">
              {customTerms}
            </TabPane>
          </Tabs>
        </div>
      </BoxContent>
    </Box>
  );
};

export default TermsPage;
