import { createSelector } from 'reselect';
import {
  CUSTOM_POOL_AVATAR_KEY,
  CUSTOM_POOL_ACCENT_COLOR_KEY,
  CUSTOM_POOL_FONT_KEY,
  CREATE_SURVEY_POOL_KEY,
  CUSTOM_POOL_IMPRINT_KEY,
  CUSTOM_POOL_DATA_PRIVACY_KEY,
} from 'framework/constants';
import { isMembershipDateNotOverdue } from 'framework/dateUtils';
import { RootState } from 'store/rootReducer';

export const orderInstallerDirectlyStateSelector = (state: any) =>
  state.intermediate.orderInstallerDirectlyState;

export const poolsSelector = (state: any) => state.intermediate.pools;
export const totalPoolsSelector = (state: RootState) => state.intermediate.totalPools;

export const poolsRequestStateSelector = (state: RootState) => state.intermediate.poolsRequestState;
export const poolCreateStateSelector = (state: any) => state.intermediate.poolCreateState;
export const orderInstallerDirectlySelector = (state: any) =>
  state.intermediate.orderInstallerDirectlyState;

export const currentPool = (state: any) => state.intermediate.currentPool;

export const poolUpdateSuccessSelector = (state: any) => state.intermediate.poolUpdateSuccess;
export const poolUpdateErrorSelector = (state: any) => state.intermediate.poolUpdateError;

export const currentPoolState = (state: any) => state.intermediate.currentPoolState;

export const unapprovedClientRequests = (state: any) => state.intermediate.unapprovedClientRequests;

export const poolClientRequests = (state: any) => state.intermediate.poolClientRequests;

export const unapprovedOffers = (state: any) => state.intermediate.unapprovedOffers;

export const poolOffers = (state: any) => state.intermediate.poolOffers;
export const poolOffersState = (state: any) => state.intermediate.poolOffersState;

export const findOrganizations = (state: any) => state.intermediate.findOrganizations;

export const statsSelector = (state: any) => state.intermediate.stats;

export const statsStateSelector = (state: any) => state.intermediate.statsState;

export const poolFilesSelector = (state: any) => state.intermediate.poolFiles;

export const poolFilesStateSelector = (state: any) => state.intermediate.poolFilesState;

export const getCustomDocumentsStateSelector = (state: any) =>
  state.intermediate.getCustomDocumentsState;

export const customDocumentSelector = (state: any) => state.intermediate.customDocument;

export const landingPageSelector = (state: any) => state.intermediate.landingPage;

export const landingPageStateSelector = (state: any) => state.intermediate.landingPageState;
export const servicesFeeDescriptionSelector = (state: RootState) =>
  state.intermediate.servicesFeeDescription;
export const servicesFeeDescriptionRequestStateSelector = (state: RootState) =>
  state.intermediate.servicesFeeDescriptionRequestState;

export const questionnaireConfigsSelector = (state: any) => state.intermediate.questionnaireConfigs;

export const questionnaireConfigsStateSelector = (state: any) =>
  state.intermediate.questionnaireConfigsState;

export const questionnaireSelector = (state: any) => state.intermediate.questionnaire;

export const questionnaireStateSelector = (state: any) => state.intermediate.questionnaireState;

export const customerQuestionnaireConfigsSelector = (state: any) =>
  state.intermediate.customerQuestionnaireConfigs;

export const inviteTokenUpdateStateSelector = (state: any) =>
  state.intermediate.inviteTokenUpdateState;

export const customDocumentImagesSelector = (state: any) => state.intermediate.customDocumentImages;

export const customDocumentMediaLinkSelector = (state: any) =>
  state.intermediate.customDocumentMediaLink;

export const organizationsInvitesSelector = (state: any) => state.intermediate.organizationsInvites;

export const poolCreateErrorSelector = (state: any) => state.intermediate.poolCreateError;

export const currentPoolRequestsSelector = (state: any) => state.intermediate.currentPoolRequests;

export const currentPoolRequestsStateSelector = (state: any) =>
  state.intermediate.currentPoolRequestsState;

export const poolInviteTokensSelector = (state: any) => state.intermediate.inviteTokens;

export const poolInviteTokensStateSelector = (state: any) => state.intermediate.inviteTokensState;

export const organizationCurrencySelector = (state: any) => state.intermediate.currencies;

export const allowPoolAvatarSelector = createSelector(currentPool, (pool) => {
  const allowAvatar = pool?.membership?.optionsIncluded?.some(
    (option: any) => option.name === CUSTOM_POOL_AVATAR_KEY,
  );

  return isMembershipDateNotOverdue(pool?.membership) && allowAvatar;
});

export const allowSurveyPoolSelector = createSelector(currentPool, (pool) => {
  const allowSurveyPool = pool?.membership?.optionsIncluded?.some(
    (option: any) => option.name === CREATE_SURVEY_POOL_KEY,
  );

  return isMembershipDateNotOverdue(pool?.membership) && allowSurveyPool;
});

export const allowPoolAccentColorSelector = createSelector(currentPool, (pool) => {
  const allowColor = pool?.membership?.optionsIncluded?.some(
    (option: any) => option.name === CUSTOM_POOL_ACCENT_COLOR_KEY,
  );

  return isMembershipDateNotOverdue(pool?.membership) && allowColor;
});

export const allowPoolFontSelector = createSelector(currentPool, (pool) => {
  const allowFont = pool?.membership?.optionsIncluded?.some(
    (option: any) => option.name === CUSTOM_POOL_FONT_KEY,
  );

  return isMembershipDateNotOverdue(pool?.membership) && allowFont;
});

export const allowPoolImprintSelector = createSelector(currentPool, (pool) => {
  const allowFont = pool?.membership?.optionsIncluded?.some(
    (option: any) => option.name === CUSTOM_POOL_IMPRINT_KEY,
  );

  return isMembershipDateNotOverdue(pool?.membership) && allowFont;
});

export const allowPoolDataPrivacySelector = createSelector(currentPool, (pool) => {
  const allowFont = pool?.membership?.optionsIncluded?.some(
    (option: any) => option.name === CUSTOM_POOL_DATA_PRIVACY_KEY,
  );

  return isMembershipDateNotOverdue(pool?.membership) && allowFont;
});

export const currenciesSelector = createSelector(organizationCurrencySelector, (currencies) => {
  return currencies;
});

export const legalDocumentsSelector = (state: any) => state.intermediate.legalDocuments;

export const legalDocumentsStateSelector = (state: any) => state.intermediate.legalDocumentsState;

export const installationWizardCustomImageUrlsSelector = (state: RootState) =>
  state.intermediate.installationWizardCustomImageUrls;
