import React from 'react';

import { ColumnsType } from 'antd/lib/table';
import BiLingualTable, {
  IBiLingualData,
  rendrerFn,
} from 'components/ui/BiLingualTable/BiLingualTable';
import styles from './TermsInstallerComponent.module.sass';

const columns: ColumnsType<IBiLingualData> = [
  {
    key: 'german',
    title: 'Anbieterbedingungen für tendergy',
    dataIndex: 'german',
    render: rendrerFn,
  },
  {
    key: 'english',
    title: 'Provider Terms and Conditions for tendergy',
    dataIndex: 'english',
    render: rendrerFn,
  },
];

const privacyData: IBiLingualData[] = [
  {
    german: `Die Schneider Electric GmbH, (nachfolgend „Schneider Electric“ oder „wir“ oder „uns“) betreibt die Internet-Plattform <a href="${document.location.origin}/">${document.location.origin}/</a> sowie alle damit verbundenen Internet-Domänen und -Auftritte (nachfolgend die „Plattform“), auf der Anbieter (nachfolgend der oder die „Anbieter“ oder „Sie“) Endkunden (nachfolgend die „Kunden“) die Installation (und ggfs. Verkauf) einer sog. Wallbox („Service“) anbieten können.`,
    english: `Schneider Electric GmbH, (hereinafter referred to as "Schneider Electric" or "we" or "us") operates the internet Platform <a href="${document.location.origin}/">${document.location.origin}/</a>, as well as all associated internet domains and websites (hereinafter referred to as the "Platform"), upon which providers (hereinafter referred to as the "Provider" or "you") may offer end customers (hereinafter referred to as the "Customers") to install (and, if applicable, sale) of a so-called wallbox ("Service").`,
  },
  {
    german:
      'Anbieter und Poolbetreiber stellen im Zuge dieser Bedingungen immer Unternehmen im Sinne von § 14 BGB dar. Kunden können entweder Verbraucher im Sinne von § 13 BGB oder Unternehmen im Sinne von § 14 BGB darstellen. Insofern gilt folgendes:',
    english:
      'For the purposes of these Terms and Conditions, the Provider and the Pool Operator shall always constitute companies within the meaning of Section 14 of the German Civil Code. Customers may either be consumers within the meaning of Section 13 of the German Civil Code or businesses within the meaning of Section 14 of the German Civil Code. In this respect, the following shall apply:',
  },
  {
    german:
      'Ein „Verbraucher“ ist jede natürliche Person, die den Vertrag zu Zwecken abschließt, die überwiegend keiner gewerblichen noch selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 Bürgerliches Gesetzbuch – „BGB“).',
    english:
      'A "Consumer" is any natural person who enters into the contract for purposes that are predominantly not attributable to a commercial or self-employed professional activity (Section 13 of the German Civil Code).',
  },
  {
    german:
      'Ein „Unternehmer“ ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss des Vertrages in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt (§ 14 Abs. 1 BGB).',
    english:
      'A "Businessperson" is a natural or legal person or a partnership with legal capacity who, at the time of conclusion of the contract, acts in the exercise of their commercial or self-employed professional activity (Section 14 (1) of the German Civil Code).',
  },
  {
    german:
      'Kunde ist somit grundsätzlich eine natürliche Person, es sei denn, sie ist ausdrücklich als juristische Person bei tendergy registriert.',
    english:
      'In principle, a Customer is therefore a natural person, unless they are expressly registered as a legal entity at tendergy.',
  },
  {
    german:
      'Schneider Electric stellt die Plattform nur zur Verfügung, um es den Anbietern zu ermöglichen, den Kunden oder Pool-Betreibern ihren Service direkt anzubieten bzw. Verträge abzuschließen. Schneider Electric tritt als vom Anbieter autorisierter und beauftragter gewerblicher Vermittler auf und wird zu keiner Zeit Vertragspartner bezüglich des Services, den der Anbieter den Kunden oder Pool-Betreibern anbietet.',
    english:
      'Schneider Electric shall only provide the Platform for the purpose of enabling Providers to offer their service to or enter into contracts with Customers or Pool Operators directly. Schneider Electric shall act as a commercial broker authorised and appointed by the Provider and shall at no time become a party to any contract with regard to the service offered by the Provider to Customers or Pool Operators.',
  },
  {
    german: '1. Anwendungsbereich dieser Anbieterbedingungen',
    english: '1. Scope of Application of these Terms and Conditions',
    subSection: true,
  },
  {
    german: `Diese Anbieterbedingungen (nachfolgend die „Bedingungen“ oder der „Vertrag“) gelten für alle Anbieter, die den Kunden ihren Service über die Plattform anbieten, und regeln lediglich das Verhältnis zwischen dem Anbieter und Schneider Electric. Das Vertragsverhältnis zwischen dem Anbieter und tendergy umfasst nur die ordnungsgemäße Vermittlung der Kunden und Pool-Betreiber an den Anbieter. Die Vermittlung zwischen Kunden bzw. Pool-Betreibern und Anbietern findet in verschiedenen Gruppen und/oder Projekten statt – sog. Pools („Pools“). Das Verhältnis zwischen den Kunden und dem Anbieter ist nicht Gegenstand der Bedingungen, sondern allein Gegenstand der einzelnen Verträge, die zwischen diesen Parteien abgeschlossen werden. Das Verhältnis zwischen Schneider Electric und den Kunden ist unterdessen Gegenstand der Nutzungsbedingungen von Schneider Electric unter <a href="${document.location.origin}/legal/terms-and-conditions?at=2">${document.location.origin}/legal/terms-and-conditions?at=2</a>`,
    english: `These Provider Terms and Conditions (hereinafter the "Terms" or the "Agreement") are applicable to all Providers offering their service to Customers via the Platform and shall only govern the relationship between the Provider and Schneider Electric. The contractual relationship between the Provider and tendergy shall only include the proper referral of Customers and Pool Operators to the Provider. Brokerage between Customers or Pool Operators and providers shall take place via different groups and/or projects – so-called pools ("Pools"). The relationship between the Customers and the Provider shall not be the subject of the Terms but shall solely be the subject of the individual contracts concluded between these parties. The relationship between Schneider Electric and its customers, moreover, shall be governed by Schneider Electric's Terms of Use at <a href="${document.location.origin}/legal/terms-and-conditions?at=2">${document.location.origin}/legal/terms-and-conditions?at=2</a>`,
  },
  {
    german: '2. Registrierung des Anbieters',
    english: '2. Provider Registration',
    subSection: true,
  },
  {
    german: '2.1',
    english: '2.1',
    bold: true,
  },
  {
    german:
      'Nach Registrierung auf der Plattform ist der Anbieter berechtigt, seinen Service über die Plattform und insbesondere dem von Schneider Electric betriebenen öffentlichen Pool anzubieten. Im Zuge der Registrierung auf der Plattform nimmt der Anbieter die Bedingungen an.',
    english:
      'The Provider shall be entitled to offer their Service via the Platform, and in particular the public Pool operated by Schneider Electric, upon registration at the Platform. The Provider shall accept the Terms and Conditions during registration to the Platform.',
  },
  {
    german: '2.2',
    english: '2.2',
    bold: true,
  },
  {
    german:
      'Schneider Electric ist nicht dafür verantwortlich, die Identität, die Echtheit oder Berechtigungsnachweise der Person zu überprüfen, die diesen Vertrag abschließt. Schneider Electric ist jedoch jederzeit berechtigt, sich derartige Nachweise auf Kosten des Anbieters vorlegen zu lassen.',
    english:
      'Schneider Electric is not responsible for verifying the identity, authenticity or credentials of the person entering into this Agreement. However, Schneider Electric shall be entitled at any time to obtain such evidence at the expense of the Provider.',
  },
  {
    german: '2.3',
    english: '2.3',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist verpflichtet, seinen Service als Unternehmer gemäß den für ihn einschlägigen Steuervorschriften des anwendbaren Umsatzsteuergesetzes und den Verfahren und Anforderungen der Steuerbehörden anzubieten und zu erbringen. Der Anbieter ist verpflichtet, im Rahmen der Führung seiner Geschäfte bzw. seiner selbständigen beruflichen Tätigkeit die maßgeblichen Verträge mit den Kunden abzuschließen.',
    english:
      'The Provider is obliged to offer and provide their Service as a Businessperson in accordance with the tax regulations of the applicable VAT law and the procedures and requirements of the tax authorities that apply to it. The Provider is obliged to conclude the relevant contracts with the Customers during the course of their business or self-employed professional activity.',
  },
  {
    german: '2.4',
    english: '2.4',
    bold: true,
  },
  {
    german:
      'Der Anbieter kann sich von Schneider Electric zum sog.„Pool-Betreiber“ unter den Bedingungen eines separat abzuschließenden Lizenzvertrags freischalten lassen. Pool-Betreiber können zusätzlich zum von Schneider Electric betriebenen öffentlichen Pool auch private Pools zur Vermittlung („Privater Vermittlungs-Pool“) von Installationsleistungen durch anderer Anbieter oder zum Abschluss der Installationsleistung mit sich selber („Privater Betreiber-Pool“) erstellen. Anbieter und Kunden können diesen privaten Pools nur auf Einladung der entsprechenden Pool-Betreiber beitreten. Bei Beitritt zu diesen privaten Pools unterwerfen sich die Anbieter den besonderen Bestimmungen des jeweiligen Pools und insbesondere dem entsprechenden Buchungsverfahren wie in Ziffer 5 dargestellt.',
    english:
      'The Provider may receive Schneider Electric’s authority to become a so-called "Pool Operator" under the terms and conditions of a licence agreement to be concluded separately. Pool Operators may, in addition to the public pool operated by Schneider Electric, also create private pools for the Brokerage ("Private Brokerage Pool") of installation services by other providers or for the conclusion of installation service contracts with themselves ("Private Operator Pool"). Providers and Customers may only join these private Pools upon invitation by the respective Pool operators. When joining these private Pools, Providers shall be subject to the specific Terms and Conditions of the relevant Pool and in particular to the relevant booking procedure as set out in Clause 5.',
  },
  {
    german: '3. Der von Schneider Electric angebotene Service',
    english: '3. Service provided by Schneider Electric',
    subSection: true,
  },
  {
    german:
      'Die Plattform ermöglicht es den Anbietern, den Kunden ihren Service anzubieten und Verträge mit diesen abzuschließen. Dabei beauftragt der Anbieter Schneider Electric als gewerblichen Vermittler und bevollmächtigt uns, die Anbahnung und den Vertragsschluss in Bezug auf den Service direkt zwischen dem Anbieter bzw. dem Pool-Betreiber und den Kunden über die Plattform abzuwickeln.',
    english:
      'The Platform enables Providers to offer their service to Customers and to conclude contracts with them. In doing so, the Provider shall appoint Schneider Electric as a commercial mediating agent and authorise us to handle directly the initiation and conclusion of the contract in relation to the Service between the provider or the Pool Operator and the Customers via the Platform.',
  },
  {
    german: '4. Pflichten des Anbieters',
    english: '4. Provider obligations',
    subSection: true,
  },
  {
    german: '4.1',
    english: '4.1',
    bold: true,
  },
  {
    german:
      'Der Anbieter sichert zu und garantiert, dass alle Informationen im Zusammenhang mit seinem Angebot jederzeit richtig, wahr und nicht irreführend sind. Der Anbieter ist verpflichtet, die hochgeladenen Informationen zeitnah zu aktualisieren, so dass die Richtigkeit der bereitgestellten Informationen sichergestellt ist. Der Anbieter kann für alle Forderungen der Kunden haftbar gemacht werden, die auf Unrichtigkeiten der hochgeladenen, auf der Plattform angezeigten Informationen (wie z. B. Informationen zu dem Service und Preisen) beruhen.',
    english:
      'The Provider shall assure and guarantee that all information relating to their Offer is at all times accurate, true and not misleading. The Provider is obliged to update the uploaded information in a timely manner in order to ensure the accuracy of the information provided. The Provider may be held liable for any claims by Customers based on inaccuracies within the uploaded information displayed on the Platform (such as information on the Service and prices).',
  },
  {
    german: '4.2',
    english: '4.2',
    bold: true,
  },
  {
    german:
      'Der Anbieter sichert zu und garantiert, dass der Service ausschließlich im Einklang mit allen anwendbaren Gesetzen erbracht wird und dass die Erbringung des Services nicht gegen Rechte Dritter verstößt. Insbesondere garantiert der Anbieter, dass er (a) sämtliche Verbraucherschutz- und sonstige Schutz-, Informations- und Beratungspflichten und -vorschriften einhält und (b) über alle erforderlichen verwaltungsrechtlichen und sonstigen Genehmigungen und Lizenzen sowie über Versicherungen und ähnliches verfügt, und dass er seinen im Rahmen der Erfüllung des Services eingesetzten Auftragnehmern Weisungen erteilt, die diesen Bedingungen entsprechen. Der Anbieter ist verpflichtet, auf Nachfrage entsprechende Nachweise und Unterlagen, wie z. B. Kopien von Genehmigungen und Lizenzen, vorzulegen. Für den Fall, dass der vom Anbieter erbrachte Service die genannten Vorgaben nicht einhält oder wenn ein entsprechender Verdacht besteht, ist der Anbieter verpflichtet, unverzüglich auf eigene Kosten mit den Verwaltungsbehörden oder Wettbewerbs-/Verbraucherschutzverbänden zusammenzuarbeiten.',
    english:
      'The Provider shall assure and guarantee that the Service will be provided solely in accordance with all applicable laws and that the provision of the Service will not infringe any third-party rights. In particular, the Provider shall guarantee that they will (a) comply with all consumer protection, other protection, information and consultation obligations and regulations and (b) possess all necessary administrative and other permits and licences, as well as insurance and equivalent policies, and that they will issue instructions to their contractors engaged in the performance of the Service which comply with these conditions. The Provider shall be obliged to submit appropriate evidence and documentation, such as copies of permits and licences, upon request. In the event that the service provided by the Provider does not comply with the aforementioned specifications, or if there is any suspicion to this effect, the Provider is obliged to cooperate immediately with the administrative authorities or competition/consumer protection associations at their own expense.',
  },
  {
    german: '4.3',
    english: '4.3',
    bold: true,
  },
  {
    german:
      'Der Anbieter sichert zu und garantiert, dass er den Kunden alle für die Ausführung des Services erforderlichen Informationen (wie z. B. den Treffpunkt und die Uhrzeit) rechtzeitig im Voraus mitteilt.',
    english:
      'The Provider shall assure and guarantee that they will provide the Customers with all information necessary for the performance of the Service (such as the meeting place and time) in due time prior to the performance of the Service.',
  },
  {
    german: '4.4',
    english: '4.4',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist nur berechtigt, seinen eigenen Service über die Plattform anzubieten. Der Anbieter ist nicht berechtigt, einen Service als Vermittler anzubieten. Sofern der Anbieter dennoch als Vermittler oder Makler auftritt, wird er wie eine Vertragspartei behandelt und kann für etwaige Schäden haftbar gemacht werden. Der Anbieter muss in der Lage sein, den angebotenen Service auszuführen. Er ist nicht berechtigt, einen vorgetäuschten Service auf der Plattform einzustellen.',
    english:
      'The Provider is only entitled to offer their own service via the Platform. The Provider is not authorised to offer a service as a mediating agent. To the extent that the Provider nevertheless acts as a broker, the Provider shall be treated as a contracting party and may be held liable for any damages. The Provider must be able to perform the service offered. The Provider is not entitled to post mock services on the Platform.',
  },
  {
    german: '4.5',
    english: '4.5',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist verpflichtet, eine umfassende allgemeine Haftpflichtversicherung für alle über die Plattform angebotenen Services abzuschließen. Der Anbieter muss zwingend eine Versicherungssumme vereinbaren, die für die festgelegten Buchungsvolumina und -risiken angemessen ist. Darüber hinaus muss die allgemeine Haftpflichtversicherung potenzielle Regressansprüche von Schneider Electric abdecken.',
    english:
      'The Provider is obliged to take out comprehensive general liability insurance for all services offered via the Platform. The Provider is obliged to agree on an insurance sum that is appropriate for the specified booking volumes and risks. The general liability insurance must also cover potential recourse claims by Schneider Electric.',
  },
  {
    german: '4.6',
    english: '4.6',
    bold: true,
  },
  {
    german:
      'Die vom Anbieter auf seinem Account für seine Angebote und Services angegebenen Preise sind einschließlich der USt. bzw. MwSt. und sonstiger Preisbestandteile. Es ist nicht gestattet, von den Kunden zu verlangen, dass sie auf die Ausführung des Service weitere Beträge, Steuern, Gebühren oder Provisionen zahlen oder sonstige Kosten erstatten.',
    english:
      'The prices quoted by the Provider on their account for their offers and services shall be inclusive of VAT and other price components. Requiring customers to pay any further amounts, taxes, fees or commissions on the performance of the Service or to reimburse any other costs is not permitted.',
  },
  {
    german:
      'Der Anbieter ist verpflichtet, den Kunden eine ordnungsgemäße Rechnung zu übermitteln, die sämtliche anwendbaren steuerlichen Voraussetzungen erfüllt.',
    english:
      'The Provider is obliged to send the Customers a proper invoice that complies with all applicable tax requirements.',
  },
  {
    german: '5. Buchungsverfahren',
    english: '5. Booking Procedure',
    subSection: true,
  },
  {
    german: '5.1',
    english: '5.1',
    bold: true,
  },
  {
    german:
      'Im öffentlichen Pool gilt: Wenn der Kunde an einem bestimmten Service interessiert ist, erstellt der Kunde eine Anfrage im Pool, und die Anbieter im Pool werden ordnungsgemäß über die betreffende Anfrage in Kenntnis gesetzt.',
    english:
      'In the Public Pool, if the Customer is interested in a particular Service, the Customer shall create a request in the Pool and the Providers in the Pool shall be duly notified of the relevant request.',
  },
  {
    german:
      'Nach Angabe aller erforderlichen Angaben, können die Anbieter im Pool dem Kunden ein verbindliches Angebot machen. Dieses Angebot unterliegt ggf. der abschließenden Kontrolle durch Schneider Electric. Der Anbieter ist an dieses Angebot 60 Werktage gebunden. Wenn der Kunde das Angebot annimmt, wird Schneider Electric dies dem Anbieter mitteilen. Mit der Annahme des Kunden kommt ein verbindlicher Vertrag mit dem Anbieter des verbindlichen Angebots über den Service zustande.',
    english:
      'Upon providing all the necessary information, the Providers in the Pool may submit a binding offer to the Customer. This offer may be subject to final inspection by Schneider Electric. Should the Customer accept the offer, Schneider Electric will notify the Provider accordingly. Upon acceptance by the Customer, a binding contract shall be concluded with the Provider of the binding offer for the service.',
  },
  {
    german: '5.2',
    english: '5.2',
    bold: true,
  },
  {
    german:
      'In Privaten Vermittlungs-Pools gilt: Wenn der Kunde in diesem Pool an einem bestimmten Service interessiert ist, erstellt er eine entsprechende Anfrage, und die Anbieter im Pool sowie der Pool-Betreiber werden ordnungsgemäß über die betreffende Anfrage in Kenntnis gesetzt.',
    english:
      'In Private Brokerage Pools, should the Customer in that Pool be interested in a particular Service, the Customer is to create a request to that effect and the Providers in the Pool and the Pool Operator shall be duly notified of the request in question.',
  },
  {
    german:
      'Nach Angabe aller erforderlichen Angaben, können die Anbieter dem Kunden ein verbindliches Angebot machen. Dieses Angebot unterliegt ggf. der abschließenden Kontrolle durch den Pool-Betreiber. Der Anbieter ist an dieses Angebot 60 Werktage gebunden. Wenn der Kunde das Angebot annimmt, wird Schneider Electric dies dem Anbieter mitteilen. Mit der Annahme des Kunden kommt ein verbindlicher Vertrag mit dem Anbieter des verbindlichen Angebots über den Service zustande.',
    english:
      'Providers can make a binding offer to the Customer upon providing all required information. This offer is subject to final review by the Pool Operator, if applicable. The Provider will be bound to this offer for 60 working days. Should the Customer accept the offer, Schneider Electric will notify the Provider accordingly. Upon Customer acceptance, a binding contract with the Provider of the Binding Offer for the Service shall be concluded.',
  },
  {
    german: '5.3',
    english: '5.3',
    bold: true,
  },
  {
    german:
      'In Privaten Betreiber-Pools gilt: Wenn der Kunde in diesem Pool an einem bestimmten Service interessiert ist, erstellt er eine Anfrage, und die Anbieter in diesem Pool sowie der Pool-Betreiber werden ordnungsgemäß über die betreffende Anfrage in Kenntnis gesetzt.',
    english:
      'In Private Operator Pools, should the Customer in that Pool be interested in a particular Service, the Customer is to create a request to that effect and the Providers in the Pool and the Pool Operator shall be duly notified of the request in question.',
  },
  {
    german:
      'Nach Angabe aller erforderlichen Angaben, kann der Anbieter dem Pool-Betreiber ein verbindliches Angebot zur Ausführung der Anfrage des Kunden als Unterauftragnehmer des Pool-Betreibers machen. Der Anbieter ist an dieses Angebot fünfzehn (15) Werktage gebunden. Wenn der Pool-Betreiber das Angebot annimmt, wird Schneider Electric dies dem Anbieter mitteilen. Mit der Annahme des Pool-Betreibers kommt ein verbindlicher Vertrag zwischen dem Anbieter und dem Pool-Betreiber hinsichtlich des verbindlichen Angebots über die Erbringung des Service beim Kunden als Unterauftrag im Namen und Auftrag des Pool-Betreibers zustande.',
    english:
      "Upon providing all required information, the Provider may make a binding offer to the Pool Operator to execute the Customer's request as a subcontractor of the Pool Operator. The Provider shall be bound by this offer for fifteen (15) working days. Should the Pool Operator accept the offer, Schneider Electric will notify the Provider accordingly. A binding contract between the Provider and the Pool Operator regarding the binding offer for the provision of the Service at the Customer's premises, shall be concluded as a subcontract in the name and on behalf of the Pool Operator upon acceptance by the Pool Operator.",
  },
  {
    german: '5.4',
    english: '5.4',
    bold: true,
  },
  {
    german:
      'Schneider Electric haftet nicht für die Genauigkeit und/oder die Vollständigkeit der kundenbezogenen Daten und Anfragen.',
    english:
      'Schneider Electric shall not be liable for the accuracy and/or the completeness of the Customer-related data and requests.',
  },
  {
    german: '6. Buchungsangebote des Anbieters',
    english: '6. Provider Booking Offers',
    subSection: true,
  },
  {
    german: '6.1',
    english: '6.1',
    bold: true,
  },
  {
    german:
      'Der Anbieter gibt die Angaben zum Service in den Anbieter-Account ein. Er ist dafür verantwortlich sicherzustellen, dass die gemachten Angaben stets auf dem aktuellen Stand sind. Überarbeitete, stornierte oder nicht mehr gültige Services sind unverzüglich zu löschen.',
    english:
      'The Provider shall enter the Service details into the Provider Account. It is the Provider’s responsibility to ensure that the information provided is always up to date. Revised, cancelled or no longer valid services must be deleted immediately.',
  },
  {
    german: '6.2',
    english: '6.2',
    bold: true,
  },
  {
    german:
      'Aus der Servicebeschreibung muss klar ersichtlich sein, dass der Anbieter den Service in seinem eigenen Namen und in seiner eigenen Verantwortung anbietet. Der Service muss unter dem allgemein verwendeten Markennamen angeboten werden. Ist dieser Markenname ein anderer als der in allgemeinen (Marken-) Registern eingetragene, muss der Anbieter auch diesen eingetragenen Markennamen nennen. Es darf weder mittelbar noch unmittelbar der Eindruck entstehen, dass Schneider Electric Anbieter des Services ist oder das Schneider Electric die Qualität des angebotenen Services geprüft hat oder ihre Qualität anderweitig hervorhebt.',
    english:
      'The Service Description shall clearly state that the Provider is providing the Service on their own behalf and under its own responsibility. The Service must be offered under the brand name in common use. If this brand name differs from the one registered in general (trademark) registers, the Provider must also mention this registered brand name. There must be no direct or indirect impression that Schneider Electric is the provider of the service, or that Schneider Electric has checked the quality of the service offered or otherwise promotes its quality.',
  },
  {
    german: '7. Verantwortung für den Inhalt',
    english: '7. Responsibility for Content',
    subSection: true,
  },
  {
    german: '7.1',
    english: '7.1',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist für den Inhalt verantwortlich, der über seinen Account übermittelt wird, wie z. B. Beschreibungen des Services. Schneider Electric behandelt diesen Inhalt des Anbieters wie externen Inhalt.',
    english:
      'The Provider is responsible for the content that is transmitted via its account, such as descriptions of the Service. Schneider Electric shall treat this Provider Content as External Content.',
  },
  {
    german: '7.2',
    english: '7.2',
    bold: true,
  },
  {
    german:
      'Wird Schneider Electric über Inhalt informiert oder entdeckt Inhalt, der gegen diese Bedingungen oder sonstige rechtliche Bestimmungen verstößt, ist Schneider Electric berechtigt, diesen Inhalt ganz oder teilweise zu löschen. Falls der missbräuchliche Inhalt von einem Anbieter stammt, ist Schneider Electric berechtigt, den Zugang zu seinem Account zu sperren oder seinen Account zu löschen. Schneider Electric wird stets die jeweiligen beiderseitigen Interessen an dem betreffenden Fall berücksichtigen, bevor das Unternehmen einen Anbieter-Account ganz oder vorübergehend sperrt.',
    english:
      'Should Schneider Electric be informed of or discover Content that violates these Terms or any other legal provisions, Schneider Electric shall be entitled to delete such Content in whole or in part. Schneider Electric is entitled to block access to the account or delete the account in the event that the abusive content emanates from a Provider. Schneider Electric will always consider the respective mutual interests in the case concerned before suspending a Provider Account completely or temporarily.',
  },
  {
    german: '7.3',
    english: '7.3',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist nur berechtigt, Inhalte und Daten hochzuladen, zu denen er alle notwendigen Rechte besitzt. Diese Informationen dürfen nicht gegen die Datenschutzrechte von Kunden, Dritten, etc. verstoßen und müssen den geltenden Wettbewerbsgesetzen und sonstigen rechtlichen Anforderungen entsprechen.',
    english:
      'The Provider is only entitled to upload content and data to which it holds all necessary rights. This information must not violate the data protection rights of customers, third parties, etc. and must comply with applicable competition laws and other legal requirements.',
  },
  {
    german: '8. Kundenbeschwerden',
    english: '8. Customer complaints',
    subSection: true,
  },
  {
    german: '8.1',
    english: '8.1',
    bold: true,
  },
  {
    german:
      'Um Beschwerden oder Forderungen bezüglich (des durch den Anbieter angebotenen, erbrachten oder zur Verfügung gestellten Services) des Anbieters oder spezielle vom Kunden vorgebrachte Anfragen hat sich der Anbieter zu kümmern, da Schneider Electric nicht für solche Anfragen von Kunden verantwortlich ist und für Forderungen nicht einsteht. Schneider Electric ist berechtigt, jederzeit und nach eigenem Ermessen (a) einem Kunden Kunden-(Support-)Service anzubieten, (b) als Vermittler zwischen dem Anbieter und den Kunden aufzutreten oder (c) die Kunden anderweitig bei ihrer Kommunikation mit dem oder der Geltendmachung von Ansprüchen gegenüber dem Anbieter zu unterstützen.',
    english:
      'Complaints or claims regarding (the service offered, performed or provided by) the Provider or specific requests made by the Customer shall be dealt with by the Provider, as Schneider Electric is not responsible for such Customer requests and shall not be liable for any claims. Schneider Electric may, at any time and at its sole discretion, (a) provide customer (support) services to a Customer, (b) act as a mediating agent between the Provider and Customers, or (c) otherwise assist Customers in their communications with or claims against the Provider.',
  },
  {
    german: '9. Zahlung und Abrechnung',
    english: '9. Payment and Billing',
    subSection: true,
  },
  {
    german:
      'Für die Zahlung des Kunden und die diesbezügliche Abrechnung ist allein der Anbieter verantwortlich.',
    english:
      'The Provider shall be solely responsible for Customer payment and billing in this regard.',
  },
  {
    german: '10. Steuern',
    english: '10. Taxes',
    subSection: true,
  },
  {
    german:
      'Der Anbieter nimmt zustimmend zur Kenntnis, dass er allein für die Genauigkeit der Angaben zum Steuersatz, für die Ermittlung der anwendbaren Steuern und etwaige Änderungen der Steuersätze verantwortlich ist, die auf der Plattform eingegeben werden. Der Anbieter ist dafür verantwortlich, den zuständigen Steuerbehörden seine Abrechnungen im Hinblick auf jegliche anwendbaren Steuern zu allen Beträgen einzureichen, die als Gegenleistung für den Service bei ihm eingehen. Sofern Schneider Electric es verlangt, ist der Anbieter verpflichtet, Schneider Electric in den Fällen, in denen nach anwendbarem Recht Steuern anfallen, unverzüglich gültige Steuerrechnungen im Hinblick auf alle im Rahmen dieser Bedingungen abgeschlossenen Transaktionen vorzulegen.',
    english:
      'The Provider acknowledges and agrees that they are solely responsible for the accuracy of tax rate information, the determination of applicable taxes and any changes to tax rates entered on the Platform. The Provider is responsible for submitting to the relevant tax authorities their accounts in respect of any applicable taxes on all amounts received in consideration for the Service. Where required by Schneider Electric, the Provider shall promptly provide Schneider Electric with valid tax invoices in respect of all transactions entered into under these Terms and Conditions in cases where taxes are payable under applicable law.',
  },
  {
    german: '11. Datenschutz',
    english: '11. Data Protection',
    subSection: true,
  },
  {
    german:
      'Der Anbieter versichert, dass er angemessene Sicherheitsverfahren und -kontrollen installiert hat und aufrechterhalten wird, bzw. dass er diese einrichten und aufrechterhalten wird, um die unbeabsichtigte Offenlegung von und den unbefugten Zugriff auf oder die widerrechtliche Verwendung von personenbezogenen Daten oder Informationen von Kunden zu verhindern.',
    english:
      'The Provider shall assure that they have installed and will maintain, or will install and maintain, appropriate security procedures and controls to prevent the inadvertent disclosure of, and unauthorised access to, or unlawful use of, Customer personal data or information.',
  },
  {
    german:
      'Weder der Anbieter noch seine verbundenen Unternehmen beteiligen sich mittelbar oder unmittelbar an verlangt oder unverlangt zugesandten Marketing- oder Promotion-Maßnahmen für Kunden oder ähnlichen Mitteilungen an Kunden, die über die Plattform gebucht haben, ohne dass hierzu die Zustimmung des betreffenden Kunden vorliegt. Der Anbieter ist verpflichtet, sämtliche kundenbezogenen Informationen (wie z. B. Kreditkarten-, Debitkarten- oder Bankkontodaten) gemäß den auf den Anbieter anwendbaren Gesetzen zu verarbeiten, zu speichern, zu übermitteln und darauf zuzugreifen. Der Anbieter ist auf Anforderung von Schneider Electric verpflichtet nachzuweisen, dass er technische und organisatorische Sicherheitsmaßnahmen, die die Verarbeitung personenbezogener Daten gemäß dem vorliegenden Abschnitt regeln, installiert hat und aufrechterhält.',
    english:
      'Neither the Provider nor any of its affiliates shall participate directly or indirectly in any solicited or unsolicited marketing or promotional activities for Customers or similar communications to Customers who have booked through the Platform without the consent of the relevant Customer. The Provider is obliged to process, store, transmit and access all Customer-related information (such as credit card, debit card or bank account data) in accordance with the laws applicable to the Provider. Upon request by Schneider Electric, the Provider shall be required to demonstrate that it has installed and maintains technical and organisational security measures governing the processing of Personal Data in accordance with this Section.',
  },
  {
    german: `Schneider Electric erhebt und verwendet personenbezogene Daten wie in den Datenschutzbestimmungen von tendergy unter <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a> beschrieben.`,
    english: `Schneider Electric collects and uses personal data as described in tendergy's privacy policy at <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a>.`,
  },
  {
    german: '12. Zusicherungen und Garantien',
    english: '12. Assurances and Guarantees',
    subSection: true,
  },
  {
    german:
      'Zusätzlich zu etwaigen sonstigen Zusicherungen und Garantien des Anbieters in diesen Bedingungen sichert der Anbieter hiermit zu und garantiert, dass (a) er sämtliche Lizenzen, Genehmigungen und Vollmachten besitzt, die er benötigt, um seinen Service zur Buchung über die Plattform anzubieten und seine Verpflichtungen im Rahmen dieser Bedingungen einzuhalten, (b) er Inhaber sämtlicher Rechte an geistigem Eigentum ist, die über den Anbieter-Account hochgeladen wurden, bzw. dass der Inhaber dieser Rechte seine Einwilligung erteilt hat, dies auf der Plattform hochzuladen und die Rechte über die Plattform zu lizenzieren.',
    english:
      'In addition to any other assurances and guarantees made by the Provider in these Terms, the Provider hereby assures and guarantees that (a) they hold all licences, permissions and authorisations required to provide its Booking Service through the Platform and to comply with its obligations under these Terms, (b) they are the owner of all Intellectual Property Rights uploaded through the Provider Account, or that the owner of such rights has given consent to upload the same on the Platform and to license the rights through the Platform.',
  },
  {
    german: '13. Beschränkung der Haftung von Schneider Electric',
    english: '13. Schneider Electric Liability Limitation ',
    subSection: true,
  },
  {
    german: '13.1',
    english: '13.1',
    bold: true,
  },
  {
    german:
      'Schneider Electric macht keine Zusicherungen und übernimmt keine Garantien im Hinblick auf die Plattform, wie z. B. für eine vorübergehende oder dauerhafte Unterbrechung des Betriebs der Plattform.',
    english:
      'Schneider Electric makes no assurances and assumes no guarantees with respect to the Platform, such as for temporary or permanent interruption of the operation of the Platform.',
  },
  {
    german: '13.2',
    english: '13.2',
    bold: true,
  },
  {
    german:
      'Schneider Electric trägt kein Risiko hinsichtlich der Anzahl, der Häufigkeit oder der Art von Servicebuchungen über die Plattform.',
    english:
      'Schneider Electric bears no risk as to the number, frequency or nature of Service Bookings made through the Platform.',
  },
  {
    german: '13.3',
    english: '13.3',
    bold: true,
  },
  {
    german:
      'Die Haftung von Schneider Electric im Rahmen der Bedingungen ist wie folgt eingeschränkt: Die Schneider Electric haftet für Schäden, die durch die Schneider Electric oder deren Erfüllungs- und Verrichtungsgehilfen durch Vorsatz oder grobe Fahrlässigkeit verursacht wurden.',
    english:
      "Schneider Electric's liability under the Terms and Conditions is limited as follows: Schneider Electric shall be liable for damage caused by Schneider Electric or its vicarious agents or employees through intent or gross negligence.",
  },
  {
    german:
      'Für einfache Fahrlässigkeit haftet die Schneider Electric nur bei Verletzung einer wesentlichen Vertragspflicht und nur für vorhersehbare und typische Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf.',
    english:
      'Schneider Electric shall only be liable for simple negligence in the event of a breach of a material contractual obligation and only for foreseeable and typical damage. Material contractual obligations are those whose fulfilment is a prerequisite for the proper performance of the contract and on whose compliance the user may regularly rely.',
  },
  {
    german:
      'Haftungsbeschränkungen gelten nicht im Rahmen abgegebener Garantien, bei Verletzungen des Lebens, des Körpers und der Gesundheit sowie für Ansprüche aus dem Produkthaftungsgesetz.',
    english:
      'Liability limitations shall not apply within the scope of guarantees given, in the event of injury to life, bodily injury or injury to health, or for claims arising from the Product Liability Act.',
  },
  {
    german: '13.4',
    english: '13.4',
    bold: true,
  },
  {
    german:
      'Schneider Electric haftet für einen Datenverlust nur bis zur Höhe der typischen Wiederherstellungskosten, die entstehen würden, wenn ordnungsgemäß und regelmäßig Datensicherungsmaßnahmen ergriffen worden wären.',
    english:
      'Schneider Electric shall only be liable for a loss of data up to the amount of the typical recovery costs that would have been incurred if proper and regular data backup measures had been taken.',
  },
  {
    german: '14. Laufzeit und Kündigung/Beendigung',
    english: '14. Term of Contract and Termination',
    subSection: true,
  },
  {
    german: '14.1',
    english: '14.1',
    bold: true,
  },
  {
    german: 'Der Vertrag wird auf unbestimmte Zeit geschlossen.',
    english: 'The contract shall be concluded for an indefinite period.',
  },
  {
    german: '14.2',
    english: '14.2',
    bold: true,
  },
  {
    german:
      'Der Anbieter und Schneider Electric sind berechtigt, den Vertrag jederzeit und ohne Angabe von Gründen zu kündigen. Die verbleibenden bestätigten Buchungen müssen wie geplant ausgeführt werden.',
    english:
      'The Provider and Schneider Electric shall be entitled to terminate the Agreement at any time and without stating reasons. The remaining confirmed bookings must be executed as planned.',
  },
  {
    german: '14.3',
    english: '14.3',
    bold: true,
  },
  {
    german:
      'Wenn konkrete Hinweise darauf vorliegen, dass ein Anbieter schuldhaft gegen rechtliche Bestimmungen, Rechte Dritter oder diese Bedingungen verstößt, oder wenn wir ansonsten ein legitimes Interesse haben, insbesondere im Hinblick auf den Schutz der Kunden und anderer Anbieter vor betrügerischen Aktivitäten, sind wir berechtigt, unter angemessener Berücksichtigung der jeweiligen Interessen des Anbieters in dem betreffenden Fall eine oder mehrere der folgenden Maßnahmen zu ergreifen:',
    english:
      "If there is specific evidence that a Provider is in culpably breach of legal provisions, third party rights, or these Terms and Conditions, or if we otherwise have a legitimate interest, in particular with regard to protecting Customers and other Providers from fraudulent activity, we shall be entitled to take one or more of the following measures, taking due account of the Provider's respective interests in the case in question: ",
  },
  {
    german: `
    <ul>
      <li>Den Anbieter warnen;</li>
      <li>Die Angebote des Anbieters oder sonstigen Inhalt löschen;</li>
      <li>Die Nutzung der Website durch den Anbieter einschränken;</li>
      <li>Den Anbieter vorübergehend oder dauerhaft von der Plattform ausschließen (sperren);</li>
      <li>Bestehende Buchungen auf den Namen des Anbieters ungeachtet der geltenden Stornierungsrichtlinien zu stornieren und die Angebote der Kunden zum Abschluss eines Vertrags im Namen des Anbieters ablehnen.,</li>
      </ul>
      `,
    english: `
      <ul>
        <li>Warn the Provider;</li>
        <li>Delete the Provider's listings or other content;</li>
        <li>Restrict the Provider's use of the Website;</li>
        <li>Temporarily or permanently exclude (block) the Provider from the Platform;</li>
        <li>Cancel existing bookings in the Provider's name, notwithstanding the applicable cancellation policy and reject Customers' offers to enter into a contract in the Provider's name.</li>
      </ul>
      `,
    indented: true,
  },
  {
    german: '15. Änderung',
    english: '15. Amendments',
    subSection: true,
  },
  {
    german:
      'Schneider Electric behält sich das Recht vor, die Bestimmungen dieses Vertrags jederzeit zu ändern und neue oder zusätzliche Bestimmungen einzuführen. Schneider Electric wird den Anbieter über etwaige Änderungen an diesem Vertrag per E-Mail informieren. Die geänderten Bedingungen werden dem Anbieter per E-Mail spätestens zwei Wochen vor ihrem Inkrafttreten zugesendet. Widerspricht der Anbieter der Geltung der neuen Bedingungen nicht innerhalb von zwei Wochen nach Empfang der E-Mail, gelten die geänderten Bedingungen als angenommen. Schneider Electric wird den Anbieter in der E-Mail, die die geänderten Bedingungen enthält, auf die Bedeutung dieser Zweiwochenfrist gesondert hinweisen.',
    english:
      'Schneider Electric reserves the right to amend the provisions of this Agreement at any time and to introduce new or additional provisions. Schneider Electric will notify the Provider of any changes to this Agreement by email. The amended Terms and Conditions shall be sent to the Provider by email no later than two weeks before they come into force. Should the Provider not object to the validity of the new Terms and Conditions within two weeks after receipt of the email, the amended Terms and Conditions shall be deemed accepted. Schneider Electric will separately advisers Users of the significance of this two-week period in the email containing the amended Terms and Conditions.',
  },
  {
    german: '16. Schlussbestimmung',
    english: '16. Final Provision',
    subSection: true,
  },
  {
    german: '16.1',
    english: '16.1',
    bold: true,
  },
  {
    german:
      'Nebenabreden wurden nicht getroffen. Alle Änderungen und Ergänzungen zu diesem Vertrag müssen schriftlich erfolgen, um rechtswirksam zu sein.',
    english:
      'No ancillary agreements have been made. All amendments and additions to this Agreement must be made in writing in order to be legally effective.',
  },
  {
    german: '16.2',
    english: '16.2',
    bold: true,
  },
  {
    german:
      'Falls einzelne Bestimmungen dieses Vertrags ungültig oder nichtig sind oder werden, bleibt die Gültigkeit der übrigen Vertragsteile hiervon unberührt.',
    english:
      'The validity of the remaining parts of the contract shall not be affected in the event that individual provisions of this contract are or become invalid or void.',
  },
  {
    german: '16.3',
    english: '16.3',
    bold: true,
  },
  {
    german:
      'Dieser Vertrag und alle Ansprüche aus oder im Zusammenhang mit diesem Vertrag unterliegen unter Ausschluss der Regeln des internationalen Privatrechts deutschem Recht und sind diesem Recht gemäß auszulegen. Ausschließlicher Gerichtsstand ist Berlin, soweit der Anbieter Kaufmann im Sinne des Handelsgesetzbuches ist oder bei Klageerhebung keinen festen Wohnsitz in Deutschland hat. Gesetzlich zwingende Gerichtsstände bleiben unberührt.',
    english:
      'This Agreement and all claims arising out of or in connection with this Agreement shall be governed by and construed in accordance with German law, excluding the rules of private international law. The exclusive place of jurisdiction is Berlin, insofar as the Provider is a merchant within the meaning of the German Commercial Code or does not have a fixed place of residence in Germany at the time the action is brought. Statutory mandatory places of jurisdiction shall remain unaffected.',
  },
];

const TermsInstallerComponent: React.FC = (_) => {
  return (
    <div className={styles.container}>
      <BiLingualTable columns={columns} dataSource={privacyData} />
    </div>
  );
};

export default TermsInstallerComponent;
