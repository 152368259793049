import { all, call, put, takeLatest } from 'redux-saga/effects';
import InstallerAPI from 'services/installer.service';
import {
  InstallerDataGetAction,
  InstallerDataUpdateAction,
  INSTALLER_DATA_GET,
  INSTALLER_DATA_UPDATE,
  JoiningPoolConfirmAction,
  JOINING_POOL_CONFIRM,
  INSTALLER_INVITATIONS_GET,
  INSTALLER_ORGANIZATION_GET,
  InstallerInvitationsGetAction,
  InstallerOrganizationGetAction,
  IInstallerData,
  INSTALLER_SUBMIT_LICENSES,
  InstallerSubmitLicensesAction,
} from './installer.types';
import {
  installerDataGetError,
  installerDataGetSuccess,
  installerDataUpdateError,
  installerDataUpdateSuccess,
  joiningPoolConfirmError,
  joiningPoolConfirmSuccess,
  installerInvitationsGetSuccess,
  installerInvitationsGetError,
  getInstallerOrganizationSuccess,
  getInstallerOrganizationError,
  submitLicensesSuccess,
  submitLicensesError,
  installerDataGet,
} from './installer.actions';

export function* confirmJoiningPool(action: JoiningPoolConfirmAction): Generator<any, void, any> {
  try {
    const data = yield call(InstallerAPI.confirmJoiningPool, action.payload);
    if (data) {
      yield put(joiningPoolConfirmSuccess(data));
    } else {
      yield put(joiningPoolConfirmError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(joiningPoolConfirmError(message));
  }
}

export function* getInstallerData(action: InstallerDataGetAction) {
  try {
    const data: IInstallerData = yield call(InstallerAPI.getInstallerData);
    if (data) {
      yield put(installerDataGetSuccess(data));
    } else {
      yield put(installerDataGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(installerDataGetError(message));
  }
}

export function* updateInstallerData(action: InstallerDataUpdateAction) {
  try {
    const result: { affected: boolean } = yield call(
      InstallerAPI.updateInstallerData,
      action.payload,
    );
    if (result && result.affected) {
      yield put(installerDataUpdateSuccess(action.payload));
    } else {
      yield put(installerDataUpdateError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(installerDataUpdateError(message));
  }
}

export function* getInstallerInvitations(
  action: InstallerInvitationsGetAction,
): Generator<any, void, any> {
  const {
    payload: { onSuccess },
  } = action;
  try {
    const result = yield call(InstallerAPI.getInstallerInvitations);

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
    yield put(installerInvitationsGetSuccess(result));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(installerInvitationsGetError(message));
  }
}

export function* getInstallerOrganizationData(
  action: InstallerOrganizationGetAction,
): Generator<any, void, any> {
  try {
    const result = yield call(InstallerAPI.getOrganizationData, action.payload);
    if (result) {
      yield put(getInstallerOrganizationSuccess(result));
    } else {
      yield put(getInstallerOrganizationError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(installerDataUpdateError(message));
  }
}

export function* submitLicenses(action: InstallerSubmitLicensesAction) {
  try {
    const data: IInstallerData = yield call(InstallerAPI.submitLicenses);
    if (data) {
      yield put(submitLicensesSuccess());
    } else {
      yield put(submitLicensesError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(submitLicensesError(message));
  } finally {
    yield put(installerDataGet());
  }
}

export default function* root() {
  yield all([
    takeLatest(INSTALLER_DATA_GET, getInstallerData),
    takeLatest(INSTALLER_DATA_UPDATE, updateInstallerData),
    takeLatest(JOINING_POOL_CONFIRM, confirmJoiningPool),
    takeLatest(INSTALLER_INVITATIONS_GET, getInstallerInvitations),
    takeLatest(INSTALLER_ORGANIZATION_GET, getInstallerOrganizationData),
    takeLatest(INSTALLER_SUBMIT_LICENSES, submitLicenses),
  ]);
}
