import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/lib/typography';
import Form from 'antd/lib/form';
import { Checkbox } from 'antd';
import draftToHtml from 'draftjs-to-html';
import TextField from 'components/ui/TextField/TextField';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import PasswordResetSuccessModal from 'components/modals/PasswordResetSuccess';
import Loader from 'components/ui/Loader/Loader';
import OrganizationInfoLabel from 'components/layout/OrganizationInfoLabel/OrganizationInfoLabel';
import TermsCustomerComponent from 'components/static/TermsCustomerComponent/TermsCustomerComponent';
import TermsOfUseModal from 'components/static/TermsOfUseModal/TermsOfUseModal';
import DataProtectionModal from 'components/static/DataProtectionModal/DataProtectionModal';
import {
  MODAL_CUSTOM_DATA_PROTECTION_KEY,
  MODAL_DATA_PROTECTION_KEY,
  MODAL_TERMS_KEY,
} from 'framework/constants';
import { PATH } from 'framework/path';
import { ValidationFactory } from 'framework/validations';
import {
  isPasswordResetUpdateSucceedSelector,
  passVerifyTokenDataSelector,
  passVerifyTokenLoadingSelector,
} from 'store/auth/auth.selectors';
import {
  passwordResetUpdate,
  passwordResetConfirm,
  verifyPasswordResetToken,
} from 'store/auth/auth.actions';
import styles from './PasswordResetConfirmPage.module.sass';

const PasswordResetConfirmPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modal, setModal] = useState<string | null>();

  const passwordRepeatValidator = ({ getFieldValue }: any) => ({
    validator(rule: any, value: string) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(t('common:errors:passwordsNotSame'));
    },
  });

  const passResetUpdateSuccess = useSelector(isPasswordResetUpdateSucceedSelector);
  const dataByToken = useSelector(passVerifyTokenDataSelector);
  const loading = useSelector(passVerifyTokenLoadingSelector);

  const [passwordDto, setPasswordDto] = useState({ password: '', passwordRepeat: '' });
  const { token, userId } = useParams() as { token: string; userId: string };

  useEffect(() => {
    if (!token) return;
    dispatch(passwordResetConfirm(token));
    dispatch(verifyPasswordResetToken(token));
    // eslint-disable-next-line
  }, []);

  const onFormChange = (changedValues: any) => {
    setPasswordDto({ ...changedValues });
  };

  const onFormSubmit = () => {
    form.validateFields().then((values: any) => {
      dispatch(passwordResetUpdate(token, values.password, userId));
    });
  };

  const onOkayClick = () => {
    navigate(PATH.LOGIN);
  };

  const formProps = {
    name: 'basic',
    onValuesChange: onFormChange,
    form: form,
    className: styles.form,
  };

  const boxContentProps = {
    showNextButton: true,
    showBackButton: false,
    boxClassName: styles.resetPasswordContainer,
    title: t('customerFlow:registration:resetPassword'),
    onNextClick: onFormSubmit,
  };

  const termsCheckBoxes = [
    {
      body: t('common:texts:termsOfUse'),
      name: 'termsChecked',
      modalType: MODAL_TERMS_KEY,
    },
    {
      body: t('common:texts:dataDeclaration'),
      name: 'dataChecked',
      className: styles.resetMargin,
      modalType: MODAL_DATA_PROTECTION_KEY,
    },
  ];

  const getDataPrivacy = () => {
    if (dataByToken?.pool.dataPrivacy) {
      return draftToHtml(JSON.parse(dataByToken.pool.dataPrivacy));
    } else {
      return '';
    }
  };

  const renderModal = () => {
    const modalProps = {
      width: 800,
      termsComponent: <TermsCustomerComponent />,
      destroyOnClose: true,
      onOk: () => setModal(null),
      onCancel: () => setModal(null),
    };

    switch (modal) {
      case MODAL_TERMS_KEY:
        return <TermsOfUseModal {...modalProps} open={modal === MODAL_TERMS_KEY} />;

      case MODAL_DATA_PROTECTION_KEY:
        return <DataProtectionModal {...modalProps} open={modal === MODAL_DATA_PROTECTION_KEY} />;

      case MODAL_CUSTOM_DATA_PROTECTION_KEY:
        return (
          <DataProtectionModal
            {...modalProps}
            open={modal === MODAL_CUSTOM_DATA_PROTECTION_KEY}
            dataPrivacy={getDataPrivacy()}
            custom
          />
        );

      default:
        return null;
    }
  };

  return (
    <BoxContent {...boxContentProps}>
      {loading ? (
        <Loader />
      ) : (
        <Form {...formProps}>
          <Typography.Title level={2}>
            {t(`customerFlow:${dataByToken ? 'createAccount' : 'resetPassword'}:title`)}
          </Typography.Title>
          <Typography.Paragraph>
            {t(`customerFlow:${dataByToken ? 'createAccount' : 'resetPassword'}:enterNew`)}
          </Typography.Paragraph>

          <Form.Item
            name="password"
            rules={[
              ValidationFactory.REQUIRED,
              ValidationFactory.PASSWORD,
              ValidationFactory.PASSWORD_MIN_LENGTH,
            ]}
          >
            <TextField
              size="large"
              type="password"
              value={passwordDto.password}
              label={t('common:forms:password')}
            />
          </Form.Item>

          <Form.Item
            name="passwordRepeat"
            rules={[ValidationFactory.REQUIRED, passwordRepeatValidator]}
          >
            <TextField
              type="password"
              value={passwordDto.passwordRepeat}
              label={t('common:forms:passwordRepeat')}
            />
          </Form.Item>
          {dataByToken?.thirdPartyUser && (
            <>
              {termsCheckBoxes.map((item) => (
                <Form.Item
                  key={item.name}
                  name={item.name}
                  valuePropName="checked"
                  rules={[ValidationFactory.REQUIRED]}
                  noStyle
                >
                  <Checkbox className={item.className}>
                    <span>{t('customerFlow:registration:termsText1')} </span>
                    <span
                      className={styles.modalButton}
                      onClick={(e) => {
                        e.preventDefault();
                        setModal(item.modalType);
                      }}
                    >
                      {item.body}
                    </span>
                    <span> {`${t('customerFlow:registration:by')} inno2Grid GmbH`}</span>
                  </Checkbox>
                </Form.Item>
              ))}
              {dataByToken.organization && (
                <OrganizationInfoLabel organization={dataByToken.organization} />
              )}
              {dataByToken.pool.dataPrivacy && (
                <Form.Item
                  name="termsChecked2"
                  valuePropName="checked"
                  rules={[ValidationFactory.REQUIRED]}
                  noStyle
                >
                  <Checkbox>
                    <span> {t('customerFlow:registration:termsText1')} </span>
                    <span
                      className={styles.modalButton}
                      onClick={(e) => {
                        e.preventDefault();
                        setModal(MODAL_CUSTOM_DATA_PROTECTION_KEY);
                      }}
                    >
                      {t('common:texts:dataDeclaration')}
                    </span>
                    <span>
                      {` ${t('customerFlow:registration:by')} ${dataByToken.organization.name}`}
                    </span>
                  </Checkbox>
                </Form.Item>
              )}
            </>
          )}
        </Form>
      )}
      {modal && renderModal()}
      <PasswordResetSuccessModal
        text={t(`customerFlow:${dataByToken ? 'createAccount' : 'resetPassword'}:passUpdated`)}
        open={passResetUpdateSuccess!}
        onOkayClick={onOkayClick}
      />
    </BoxContent>
  );
};

export default PasswordResetConfirmPage;
