import React, { ReactElement } from 'react';
import { default as Modal, ModalProps } from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import { legalDocumentsSelector } from 'store/intermediate/intermediate.selectors';
import { CustomDocumentsCategoryType } from 'store/intermediate/intermediate.types';
import { getLegalDocumentData } from 'utils/commonUtils';

export interface ITermsOfUseProps extends ModalProps {
  termsComponent?: ReactElement;
  customer?: boolean;
}

const TermsOfUseModal: React.FC<ITermsOfUseProps> = ({
  onOk,
  customer,
  termsComponent,
  ...rest
}) => {
  const { t } = useTranslation();

  const legalDocuments = useSelector(legalDocumentsSelector);

  const getBody = () => {
    const value = getLegalDocumentData(
      legalDocuments,
      CustomDocumentsCategoryType.PoolTermsConditions,
    );
    return value ? <div dangerouslySetInnerHTML={{ __html: value }} /> : termsComponent;
  };

  return (
    <Modal
      {...rest}
      footer={[
        <Button key="confirmButton" color={ButtonColor.GREEN} onClick={() => onOk} type="primary">
          Ok
        </Button>,
      ]}
    >
      <Typography.Title>{t('common:texts:termsOfUse')}</Typography.Title>
      {customer ? getBody() : termsComponent}
    </Modal>
  );
};

export default TermsOfUseModal;
